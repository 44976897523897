import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AngularUpdateService } from './services/angular-update-service/angular-update.service';
import { CustomIconService } from './services/custom-icon-service/custom-icon.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { FuseSegmentedNavigationService } from './services/navigation-service/fuse-segmented-navigation';
import { CookiePoliciesService } from './services/cookie-policies/cookie-policies.service';
import { CookieEloquaService } from './services/cookie-eloqua/cookie-eloqua.service';
import { BannerService } from './services/banner/banner.service';
import { MobileService } from './services/mobile-service/mobile.service';

@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    public displayMenu = true;
    public isUserLogged: boolean;
    public isMobile: boolean;

    private _unsubscribeAll: Subject<any>;
    private navigationSubscription: Subscription;
    private navEndEvent: Subscription;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _customIconService: CustomIconService,
        private navigationService: FuseSegmentedNavigationService,
        private readonly router: Router,
        private googleService: GoogleAnalyticsService,
        private angularUpdateService: AngularUpdateService,
        private cookiePoliciesService: CookiePoliciesService,
        private cookieEloquaService: CookieEloquaService,
        private bannerService: BannerService,
        public mobile: MobileService,
    ) {
        const navEndEvent$ = this.router.events.pipe( filter(e => e instanceof NavigationEnd));
        this.navEndEvent = navEndEvent$.subscribe((e: NavigationEnd) => {
            this.googleService.pageLoaded(e.urlAfterRedirects);
        });
 
        this._unsubscribeAll = new Subject();
        this._translateService.addLangs(['es', 'en']);
        this._translateService.setDefaultLang('es');
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);
        this._translateService.use('es');
        if ( this.isMobile ) {
            this.document.body.classList.add('is-mobile');
        }
        if (this.isUserLogged ) {
            this.document.body.classList.add('logged');
        }
        this._unsubscribeAll = new Subject();
        this._customIconService.init();
    }

    public ngOnInit(): void {
        this.beginFuseConfiguration();
        this.configureNavigationHandler();
        this.angularUpdateService.checkAngularStatus();
        this.cookiePoliciesService.init();
        this.cookieEloquaService.init();
        this.bannerService.load();
    }

    public ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
        this.navEndEvent.unsubscribe();
    }

    public toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    private configureNavigationHandler(): void {
        this._fuseNavigationService.register('main', []);
        this.navigationSubscription = this.navigationService.fuseNavigationEvent.subscribe(val => {
            if (val) {
                this._fuseNavigationService.unregister('main');
                this._fuseNavigationService.register('main', val);
                this._fuseNavigationService.setCurrentNavigation('main');
            }
        });
    }

    private beginFuseConfiguration(): void {
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                if ( this.fuseConfig.layout.width === 'boxed' ) {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                for (const className of this.document.body.classList) {
                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }
}
