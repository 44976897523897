import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
    DateAdapter,
    MatCommonModule,
    MatNativeDateModule, MAT_DATE_FORMATS,
    MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { CurrencyDirective } from 'app/directives/currency.directive';
import { FileManagerDirective } from 'app/directives/file-manager.directive';
import { IbanPipe } from 'app/pipes/iban.pipe';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { SafeResourceUrlPipe } from 'app/pipes/safe-url.pipe';
import { CreditsAttachedFilesModalComponent } from './credits-attached-files-modal/credits-attached-files-modal.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { GenericDialogComponent } from './generic-dialog/generic-dialog.component';
import { AccordionPanelComponent } from './gg-accordion-panel/gg-accordion-panel.component';
import { AccountButtonComponent } from './gg-account-button/gg-account-button.component';
import { AccessButtonComponent } from './access-button/access-button.component';
import { ContentIframeComponent } from './gg-content-iframe/gg-content-iframe.component';
import { CustomDatePickerHeaderFragmentComponent } from './gg-custom-header-date-picker/custom-header-fragment.component';
import { FormBankAccoutComponent } from './gg-form-bank-account/gg-form-bank-account.component';
import { FormEmailComponent } from './gg-form-email/gg-form-email.component';
import { FormNameEmailPhoneComponent } from './gg-form-name-email-phone/gg-form-name-email-phone.component';
import { ListTotalesComponent } from './gg-list-totals/gg-list-totals.component';
import { FormDropdownComponent } from './form-dropdown/form-dropdown.component';
import { NotificationComponent } from './gg-notification/gg-notification.component';
import { PanelButtonBarComponent } from './gg-panel-button-bar/gg-panel-button-bar.component';
import { PanelButtonFooterComponent } from './gg-panel-button-footer/gg-panel-button-footer.component';
import { PanelHeaderComponent } from './gg-panel-header/gg-panel-header.component';
import { PanelTitleBarComponent } from './gg-panel-title-bar/gg-panel-title-bar.component';
import { PanelToolbarComponent } from './gg-panel-toolbar/gg-panel-toolbar.component';
import { PanelComponent } from './gg-panel/gg-panel.component';
import { TariffCalculatorComponent } from './gg-tariff-calculator/gg-tariff-calculator.component';
import { TemplateDownloadComponent } from './gg-template-download/gg-template-download.component';
import { LoadingComponent } from './loading/loading.component';
import { MyAccountLoginModalComponent } from './myaccount-login-modal/myaccount-login-modal.component';
import { SearchListComponent } from './search-list/search-list.component';
import { SelectionListComponent } from './selection-list/selection-list.component';
import { TimeoutComponent } from './timeout/timeout.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FooterComponent } from './footer/footer.component';
import { FilterListComponent } from './gg-filter-list/gg-filter-list.component';
import { getSpanishPaginatorIntl } from './spanish-paginator-intl/spanish-paginator-intl';
import { MatTabGroupComponent } from './gg-mat-tab-group/mat-tab-group.component';
import { NotesComponent } from './gg-notes/gg-notes.component';
import { CardDataComponent } from './gg-card-data/gg-card-data.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { FilterListDateComponent } from './gg-filter-list-date/gg-filter-list-date.component';
import { FilterListColumnComponent } from './gg-filter-list-column/gg-filter-list-column.component';
import { FormAccountingAccountComponent } from './gg-form-accounting-account/gg-form-accounting-account/gg-form-accounting-account.component';
import { ProceedingPreviewModalComponent } from 'app/pages/manager/proceeding-creditor-communications/proceeding-preview-modal/proceeding-preview-modal.component';
import { ContextualHelpComponent } from './gg-help/gg-help.component';
import { TokensListComponent } from './tokens-list/tokens-list.component';
import { CreditsAgainstEstateAttachedFilesModalComponent } from './credits-against-estate-attached-files-modal/credits-against-estate-attached-files-modal.component';
import { FormTreasuryMovementComponent } from './gg-form-treasury-movement/gg-form-treasury-movement.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IncomeAttachedFilesModalComponent } from './income-attached-files-modal/income-attached-files-modal.component';
import { GenericAttachedFilesModalComponent } from './generic-attached-files-modal/generic-attached-files-modal.component';
import { BidAttachedFilesModalComponent } from './bid-attached-files-modal/bid-attached-files-modal.component';
import { ProceedingCreditorsSelectionsListComponent } from './gg-proceeding-creditors-selections-list/gg-proceeding-creditors-selections-list.component';
import { CustomFilterMatcheckboxOptionsComponent } from './gg-custom-filter-matcheckbox-options/gg-custom-filter-matcheckbox-options.component';
import { CustomFilterProceedingCreditorCreditsComponent } from './gg-custom-filter-proceeding-creditor-credits/gg-custom-filter-proceeding-creditor-credits.component';
import { AssetStockAssociationsWithAssetInfoListModalComponent } from './asset-stock-associations/asset-stock-associations-with-asset-info-list-modal/asset-stock-associations-with-asset-info-list-modal.component';
import { AssociatedAssetStockAssociationsListComponent } from './asset-stock-associations/associated-asset-stock-associations-list/associated-asset-stock-associations-list.component';
import { GalleryComponent } from './gg-gallery/gallery.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { SharedLiquidationManagementButtonModule } from './shared-liquidation-management-button.module';
import { AssetsAttachedFilesModalComponent } from './asset-attached-files-modal/assets-attached-files-modal.component';
import { InfoComponent } from './gg-info/gg-info.component';
import { ImportResultComponent } from './gg-import-result/import-result.component';
import { AboutComponent } from './gg-about/gg-about.component';

export const MY_DATE_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const MY_FORM_FIELD_APPEARANCE = {
    appearance: 'outline'
};

const entrypointsDeclarations = [
    GenericDialogComponent,
    CreditsAttachedFilesModalComponent,
    GenericAttachedFilesModalComponent,
    AssetsAttachedFilesModalComponent,
    CardDataComponent,
    CustomDatePickerHeaderFragmentComponent,
    ProceedingPreviewModalComponent,
    CreditsAgainstEstateAttachedFilesModalComponent,
    IncomeAttachedFilesModalComponent,
    BidAttachedFilesModalComponent,
    AssetStockAssociationsWithAssetInfoListModalComponent,
    AssociatedAssetStockAssociationsListComponent,
    ScrollToTopComponent,
    InfoComponent,
    ImportResultComponent
];

const componentsDeclarations = [
    ...entrypointsDeclarations,
    ToolbarComponent,
    FooterComponent,
    PanelTitleBarComponent,
    PanelHeaderComponent,
    PanelToolbarComponent,
    PanelComponent,
    FileManagerComponent,
    PanelButtonBarComponent,
    PanelButtonFooterComponent,
    NotificationComponent,
    AccordionPanelComponent,
    TemplateDownloadComponent,
    TariffCalculatorComponent,
    SelectionListComponent,
    SearchListComponent,
    ListTotalesComponent,
    FormDropdownComponent,
    FormNameEmailPhoneComponent,
    FormBankAccoutComponent,
    FormTreasuryMovementComponent,
    FormEmailComponent,
    FormAccountingAccountComponent,
    MatTabGroupComponent,
    MyAccountLoginModalComponent,
    AccountButtonComponent,
    AccessButtonComponent,
    TimeoutComponent,
    LoadingComponent,
    FilterListComponent,
    FilterListDateComponent,
    FilterListColumnComponent,
    FilterListDateComponent,
    CardDataComponent,
    NotesComponent,
    ProceedingPreviewModalComponent,
    ContextualHelpComponent,
    TokensListComponent,
    CustomFilterMatcheckboxOptionsComponent,
    CustomFilterProceedingCreditorCreditsComponent,    
    ProceedingCreditorsSelectionsListComponent,
    AssetStockAssociationsWithAssetInfoListModalComponent,
    AssociatedAssetStockAssociationsListComponent,
    GalleryComponent,
    AboutComponent,

    // Pipes
    IbanPipe,
    SafeHtmlPipe,
    SafeResourceUrlPipe,

    // Directives
    CurrencyDirective,
    FileManagerDirective,

    ContentIframeComponent,
    ScrollToTopComponent,

    InfoComponent,
    ImportResultComponent
];

const sharedModules = [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,

    // Material moment date module
    MatMomentDateModule,

    // Material components modules
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatCommonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatListModule,
    MatGridListModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatChipsModule,
    MatSidenavModule,
    MatExpansionModule,
    MatSortModule,
    MatPaginatorModule,
    MatAutocompleteModule,

    FuseSharedModule,

    // KendoUI
    EditorModule,
    ScrollViewModule,

    SharedLiquidationManagementButtonModule
];

const sharedProviders = [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MY_FORM_FIELD_APPEARANCE },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() }
];

@NgModule({
    declarations: [...componentsDeclarations],
    imports: [
        ...sharedModules,
    ],
    providers: [...sharedProviders],
    exports: [...componentsDeclarations, ...sharedModules],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GiocondaGenericComponentsModule {
}
