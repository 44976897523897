import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, UrlTree } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Configuration } from 'api/configuration-service/configuration.model';
import { StaticContentService } from 'api/static-content-service/static-content.service';
import { PublicLinksButtonsService } from 'app/services/public-links-buttons/public-links-buttons.service';
import { filter } from 'rxjs/operators';
import { LoginService } from 'app/services/login/login.service';

@Component({
    selector: 'gg-panel-toolbar',
    templateUrl: './gg-panel-toolbar.component.html',
    styleUrls: ['./gg-panel-toolbar.component.scss'],
    encapsulation  : ViewEncapsulation.None,
})
export class PanelToolbarComponent implements OnInit {

    @Input() public name: string;
    @Input() public surname: string;
    @Input() public email: string;

    public isProceedingSection: boolean;
    public isComunicationSection: boolean;        
    public creditorId: string;
    public auctionsUrl: SafeResourceUrl;
    
    private readonly manualCreditorLocation = 'manual/Gioconda_Manual_ayuda_Acreedor.pdf';
    
    constructor(
        public readonly environment: Configuration,
        public readonly publicLinksbuttons: PublicLinksButtonsService,
        private activatedRoute: ActivatedRoute,
        private router: Router, 
        private readonly staticContentService: StaticContentService,
        private readonly loginService: LoginService
    ) {
        this.isProceedingSection = true;
        this.auctionsUrl = this.environment.auctionsUrl;
    }

    ngOnInit(): void {        
        this.activatedRoute.params.subscribe(params => {
            this.creditorId = params['creditorId'];
        });

        this.router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationEnd),
            ).subscribe((event: RouterEvent) => {                
                this.isComunicationSection = event.url.includes('communications');
                this.isProceedingSection = event.url.includes('proceedings');
            });
    }

    public logout(): void {
        this.loginService.logout();
    }

    public openHelp(): void {
        this.staticContentService.downloadStaticFile(this.manualCreditorLocation).subscribe({
            error: (error: any) => {
                if (error instanceof UrlTree) {
                    this.loginService.returnUrl.next(this.router.url);
                    this.router.navigate([error.toString()]);                 
                }    
            }
        });
    }

    public goToLink(link: SafeResourceUrl): void {
        this.publicLinksbuttons.goToLink(link);
    }
}
