import { Component } from '@angular/core';

@Component({
    selector: 'gioc-timeout',
    templateUrl: './timeout.component.html',
    styleUrls : ['./timeout.component.scss']
})
export class TimeoutComponent {

}
