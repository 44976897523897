import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'gg-loading-component',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnChanges {
    @Input() isLoaded: boolean;
    @Input() text: string;
    @Input() size = 'unset';
    @Input() additionalClass: string;
    private preventScrolling: (e: any) => void;

    public constructor(
        private readonly changeRef: ChangeDetectorRef,
        private readonly selfElement: ElementRef) {

        this.preventScrolling = (e: any) => {
            e.stopBubbling = true;
            e.preventDefault();
            e.stopPropagation();
            return;
        };
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['isLoaded'] !== undefined) {
            const mustCloseLoader = changes['isLoaded'].currentValue;

            if (mustCloseLoader) {
                this.selfElement.nativeElement.removeEventListener('scroll', this.preventScrolling);
            } else {
                this.selfElement.nativeElement.addEventListener('scroll', this.preventScrolling);
            }
        }

        this.changeRef.detectChanges();
    }
}
