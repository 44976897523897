import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { LiquidationManagementButtonTypes } from 'app/components/gg-gioconda-liquidation-management-button/gg-gioconda-liquidation-management-button.component';

@Component({
    selector   : 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-item';

    @Input() item: FuseNavigationItem;
    
    public isSelected = false;
    public firstSelected = true;
    
    public liquidationManagementButtonTypes = LiquidationManagementButtonTypes;
    
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _router: Router
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
     
        this._router.events.subscribe(
            (event: any) => {
                if (event instanceof NavigationStart) {
                    this.isActiveLink(event.url);
                }
                if (event instanceof NavigationEnd) {
                    this.isActiveLink(event.url);
                }
            });

        merge(
             this._fuseNavigationService.onNavigationItemAdded,
             this._fuseNavigationService.onNavigationItemUpdated,
             this._fuseNavigationService.onNavigationItemRemoved
         ).pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => {

              // Mark for check
              this._changeDetectorRef.markForCheck();
          });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private isActiveLink(url: string): void{

        if (url === this.item.url){
            this.isSelected = true;
            if (this.firstSelected && this.item.url.indexOf('creditors') !== -1){
                this.item.classes = 'accent';
                this.firstSelected = false;
            }
            return;
        }
    
        if (this.item.children !== undefined && this.item.children.some(x => url.indexOf(x.url) !== -1))
        {
            this.isSelected = true;
            this.item.classes = 'accent';
            return;
        }                        

        this.isSelected = false;        
    }
}
