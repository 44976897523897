import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProceedingCreditorCommunicationEmailsService {
    private endpointUrl: string;
    
    constructor(
        private readonly apiProcessor: ApiProcessorService, 
        private readonly environment: Configuration,
        private readonly http: HttpClient) {
        
        this.endpointUrl = this.environment.apiUrl + 'proceeding-management/creditor-communication-emails/';
    }

    public getEmail(id: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.endpointUrl = `${this.endpointUrl}${id}/preview`;
        apiRequest.operation = ApiOperation.GET;
        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public resendEmail(id: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.endpointUrl = `${this.endpointUrl}${id}/resend`;
        apiRequest.operation = ApiOperation.POST;
        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public createProofOfSending(proceedingCreditorEmailIds: Array<string>): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${ this.environment.apiUrl }proceeding-management/create-proof-of-sending`;
        const body = JSON.stringify(proceedingCreditorEmailIds);
        return this.http.post(url, body, {responseType: 'arraybuffer', headers: headers, observe: 'events', withCredentials: true});        
    }

    public downloadProofOfSending(response: any, fileName: string): void{
        const contentType = response.headers.get('content-type');
        this.apiProcessor.saveDownloadedFile(response.body, fileName, contentType);
    }
}
