import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'iban'})
export class IbanPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null) { return ''; }

    let result = value.replace(/\s/g, '').replace(/\-/g, '');
    let head = '';
    let tail = '';

    for (let index = 4; index < result.length; index = index + 4) {
      head = result.substring(0, index);
      tail = result.substring(index);
      index++;
      result = head + ' ' + tail;
    }

    return result.toUpperCase();
  }
}
