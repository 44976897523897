import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, pipe, from, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, tap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FuseMatchMediaService implements OnDestroy {
    activeMediaQuery: string;
    onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private mediaSusbcription: Subscription;

    /**
     * Constructor
     *
     * @param {MediaObserver} _mediaObserver
     */
    constructor(
        private _mediaObserver: MediaObserver
    ) {
        // Set the defaults
        this.activeMediaQuery = '';

        // RxJS 6
        this.mediaSusbcription = this._mediaObserver.asObservable()
            .pipe(debounceTime(500),
                distinctUntilChanged(),
                mergeMap(val => from(val).pipe(
                    map(item => item),
                )))
            .subscribe((change: MediaChange) => {
                if (this.activeMediaQuery !== change.mqAlias) {
                    this.activeMediaQuery = change.mqAlias;
                    this.onMediaChange.next(change.mqAlias);
                }
            });
    }

    ngOnDestroy(): void {
        this.mediaSusbcription.unsubscribe();
    }
}
