import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiocondaLiquidationManagementButtonComponent } from 'app/components/gg-gioconda-liquidation-management-button/gg-gioconda-liquidation-management-button.component';
import { GiocondaLiquidationManagementInfoComponent } from 'app/components/gg-gioconda-liquidation-management-info/gg-gioconda-liquidation-management-info.component';

import { GiocondaGenericDialogComponent } from 'app/components/gg-generic-dialog/gg-generic-dialog.component';
import { GiocButtonComponent } from 'app/components/gg-button/gg-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatIconModule
    ],
    declarations: [
        GiocondaLiquidationManagementButtonComponent,
        GiocondaLiquidationManagementInfoComponent,
        GiocondaGenericDialogComponent,
        GiocButtonComponent
    ],
    exports: [
        GiocondaLiquidationManagementButtonComponent,
        GiocondaLiquidationManagementInfoComponent,
        GiocondaGenericDialogComponent,
        GiocButtonComponent,
        MatButtonModule,
        MatIconModule
    ]
})
export class SharedLiquidationManagementButtonModule {

}