import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe, KeyValue } from '@angular/common';
import { ChangeDetectorRef, Directive, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IPageData } from 'api/api-processor-service/ipage-data';
import { IRequest } from 'api/generic-interfaces/generic-request.interface';
import { ISelectedButton } from 'app/interfaces/selected-button.model';
import { FilterListComponent } from '../gg-filter-list/gg-filter-list.component';
import { SearchListComponent } from '../search-list/search-list.component';
import { TableUtil } from './excel-util';
import { BehaviorSubject } from 'rxjs';
import { FilterListDateComponent } from '../gg-filter-list-date/gg-filter-list-date.component';
import { FilterListColumnComponent } from '../gg-filter-list-column/gg-filter-list-column.component';
import { UserListPrefencesService } from 'api/user-list-preferences/user-prefences.service';
import { IUserPreferences } from 'api/user-list-preferences/user-preferences.model';
import { INote } from 'api/generic-interfaces/note.model';
import { GiocondaGenericDialogComponent } from '../gg-generic-dialog/gg-generic-dialog.component';
import { GenericDialogComponent } from 'app/components/generic-dialog/generic-dialog.component';
import { InfoComponent } from 'app/components/gg-info/gg-info.component';
import { cloneDeep } from 'lodash';

@Directive()
export abstract class GenericListComponentDirective<T> {
    public selectedItems: ISelectedButton[] = [];
    public isAnyItemListSelected = false;
    public selection = new SelectionModel<T>(true, []);
    public dataTable: MatTableDataSource<T> = null;
    public pageData: IPageData;
    public hiddenGearEntries: Array<string> = [];
    public isExporting = false;
    public isLoading = false;
    public abstract dialog: MatDialog;
    public abstract idProperty: string;
    public userPreferencesName = '';
    public visibleFields: { [key: string]: {value: boolean, exportExcel: boolean, valueName: string, widthColum?: number, groupColumn?: string }; };
    public columnAnimation: string;

    public selectedRowChange: BehaviorSubject<any> = new BehaviorSubject([]);

    public notesGroupedByParentId: {};
    
    protected abstract currentRequest: IRequest;
    protected currentPage = 1;
    protected userPreferences: IUserPreferences;
    protected EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
    protected paginator: MatPaginator;

    protected filterDataByCustomFilters: (data: T) => boolean;

    protected isElementDisabled: (data: T) => boolean;

    protected pageSize: number;
    protected PAGE_SIZE_DEFAULT = 25;

    @ViewChild(MatPaginator, { static: false }) set newPaginator(value: MatPaginator) {
        if (value) {
            this.paginator = value;
            this.paginator.pageSize = this.pageSize;
        }
    }

    @ViewChild(MatSort, { static: false }) set sort(newSort) {
        if (newSort && this.dataTable && !Object.is(this.dataTable.sort, newSort)) {
            this.dataTable.sort = newSort;
        }
    }

    @ViewChildren(FilterListComponent) public filterList: QueryList<FilterListComponent<T>>;
    @ViewChildren(FilterListDateComponent) public filterListDate: QueryList<FilterListDateComponent<T>>;
    @ViewChildren(FilterListColumnComponent) public filterListColumn: QueryList<FilterListColumnComponent<T>>;
    @ViewChild(SearchListComponent, { static: false }) public searchList: SearchListComponent<T>;

    constructor(private userListPrefencesService: UserListPrefencesService) {
        this.pageSize = this.PAGE_SIZE_DEFAULT;
    }
    
    public showConfirmDialog(type: string, itemsToRemoveLength?: number, extraText?: string ): MatDialogRef<any> {
        return this.dialog.open(GenericDialogComponent, {
            data: {
                template: InfoComponent,
                displayButtonBar: true,
                dialogCloseButon: 'Cancelar',
                dialogButton: 'Eliminar',
                isConfirm: true,
                numType: itemsToRemoveLength,
                strType: type,
                extraText: extraText,
            },
            disableClose: true
        });
    }

    public showErrorDownloadDialog(fileName?: string): MatDialogRef<any> {
        return this.dialog.open(GenericDialogComponent, {
            data: {
                template: InfoComponent,
                icon: 'info',
                subTitle: 'Fallo en la descarga del fichero',
                message: 'Se ha producido un error en la descarga del fichero:',
                filename: `${fileName}`,
                displayButtonBar: true,
                dialogCloseButon: 'Cerrar',
            },
            disableClose: true
        });
    }

    public showErrorDialog(message?: any): MatDialogRef<any> {
        return this.dialog.open(GenericDialogComponent, {
            data: {
                template: InfoComponent,
                icon : 'error_outline',
                message: message,
                displayButtonBar: true,
                dialogCloseButon: 'Aceptar',
            },
            disableClose: true
        });
    }

    public isAllSelected(): any {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataTable.data.length;
        return numSelected === numRows;
    }

    public resetAllFilters(): any {
        this.filterList.forEach((fl: FilterListComponent<T>) => fl.resetFilters());
        this.filterListDate.forEach((fld: FilterListDateComponent<T>) => fld.resetFilters());
        this.filterListColumn.forEach((flc: FilterListColumnComponent<T>) => flc.resetFilters());
    }

    public resetSearchValue(): void {
        this.searchList.clearSearchField();
    }

    public masterToggle($event: any): void {
        this.selectedItems.length = 0;
        if ($event.checked) {
            this.dataTable.filteredData.forEach((element: T, index: number) => {
                if (element[this.idProperty] && !this.checkIsElementDisabled(element)) {
                    this.selectedItems.push({ id: element[this.idProperty], index: index });
                    this.selection.select(element);
                }
            });
            this.isAnyItemListSelected = this.selectedItems.length > 0;
        } else {
            this.selection.clear();
            this.isAnyItemListSelected = false;
        }
        this.selectedRowChange.next($event.checked);
    }

    public statusRow(row?: any): string {
        
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'selected'}`;
        }
        else if (row.recognized === true) {
            return `${this.selection.isSelected(row) ? 'selected recognized' : 'deselect recognized'}`;
        }
        else if (row.recognized === false) {
            return `${this.selection.isSelected(row) ? 'selected unrecognized' : 'deselect unrecognized'}`;
        } 

        return `${this.selection.isSelected(row) ? 'selected' : 'deselect'}`;
    }

    public selectRow($event: any, selection: SelectionModel<any>, item: any, i: number): void {
        if ($event.checked) {
            this.selectedItems.push({ id: item.id, index: i });
        }
        else {
            const indexToDelete = this.selectedItems.findIndex(
                x => x.id === item.id &&
                    x.index === i
            );

            this.selectedItems.splice(indexToDelete, 1);
        }
        this.isAnyItemListSelected = this.selectedItems.length > 0;
        selection.toggle(item);
        this.selectedRowChange.next($event.checked);
    }

    public changeVisibleField(name: string, event: Event): void {
        if (this.visibleFields[name].value) {
            this.columnAnimation = name;
        }

        this.visibleFields = cloneDeep(this.visibleFields);
        this.changeAnimationClass(name);
        this.dataTable._updateChangeSubscription();
    }

    public columnAnimationClass(name: string): any {
        if (!this.visibleFields[name].value) {
            return 'hidden';
        } else if (this.columnAnimation !== undefined && this.columnAnimation === name) {
            return 'visibleAnimation';
        }
    }

    public changeAnimationClass(name: string): void | any {
        if (!this.visibleFields[name].value) {
            return 'hidden';
        } else {
            return 'visibleAnimation';
        }
    }

    public getGroupColumn(groupColumn: string): any {
        let colspan = 0;
        for (const key in this.visibleFields) {
            if (Object.prototype.hasOwnProperty.call(this.visibleFields, key)) {
                const element = this.visibleFields[key];
                if (element.groupColumn === groupColumn && element.value) {
                    colspan++;
                }
            }
        }
        return colspan;
    }

    public stopClickPropagation(event: Event): void {
        event.stopPropagation();
    }

    public initializeVisibleFields(): void {
        this.userPreferences = {
            id: this.EMPTY_GUID,
            listCode: this.userPreferencesName,
            preferences: undefined
        };

        const expectedPreferences = this.getUserPreferencesValues();
        const userPreferencesFromStorage = sessionStorage.getItem(this.userPreferencesName);
        if (userPreferencesFromStorage === null || this.hasPreferencesStructureChanged(expectedPreferences, JSON.parse(userPreferencesFromStorage))) {
            this.getUserPreferences(expectedPreferences).then(() =>
                this.saveUserPreferencesSessionStorage()
            );
        } else {
            this.userPreferences = JSON.parse(userPreferencesFromStorage);
            this.visibleFields = JSON.parse(this.userPreferences.preferences).visibleFields;
            this.pageSize = JSON.parse(this.userPreferences.preferences).pageSize;
        }
    }

    public updateUserPreferences(): void {
        this.pageSize = this.paginator ? this.paginator.pageSize : this.PAGE_SIZE_DEFAULT;
        this.userPreferences.preferences = JSON.stringify(this.getUserPreferencesValues());
        this.saveUserPreferencesSessionStorage();
        this.userListPrefencesService.updateUserListPreferences(this.userPreferences).subscribe();
    }

    public updatePageSize(): void {
        this.pageSize = this.paginator ? this.paginator.pageSize : this.PAGE_SIZE_DEFAULT;
    }

    public filterHasValues(): boolean {
        const filter = JSON.parse(this.dataTable.filter);
        for (const key in filter) {
            if (filter[key] && Object.keys(filter[key]).length > 0) {
                return true;
            }
        }

        return false;
    }

    public loadSelectedPage(selectedPage: number): void {
        if (this.currentPage !== selectedPage) {
            const range = this.currentRequest.range.split('-').map(val => Number.parseInt(val, 10));
            const lowerRange = range[0];
            const upperRange = range[1];
            const difference = upperRange - lowerRange + 1;

            this.currentPage = selectedPage;
            this.currentRequest.range = `${(difference) * (selectedPage - 1)}-${((difference) * (selectedPage)) - 1}`;
            this.reloadTableContent();
        }
    }

    public changeElementsPerPage(amount: number): void {
        this.currentPage = 1;
        this.currentRequest.range = `0-${amount - 1}`;
        this.reloadTableContent();
    }

    public tableSearchFilterPredicate(): (data: any, filter: string) => boolean {
        return (data: any, filter: string): boolean => {
            let filterListResult = true;

            if (this.filterList) {
                this.filterList.forEach((element: FilterListComponent<T>) => filterListResult = filterListResult && element.getFilterPredicate(data, filter));
            }

            if (this.filterListDate) {
                this.filterListDate.forEach((element: FilterListDateComponent<T>) => filterListResult = filterListResult && element.getFilterPredicate(data, filter));
            }

            if (this.filterListColumn) {
                this.filterListColumn.forEach((element: FilterListColumnComponent<T>) => filterListResult = filterListResult && element.getFilterPredicate(data, filter));
            }

            let searchResult = true;
            if (this.searchList) {
                searchResult = this.searchList.getFilterPredicate(data, filter);
            }

            let filterDataByCustomFilters = true;
            if (this.filterDataByCustomFilters !== null && typeof (this.filterDataByCustomFilters) !== 'undefined') {
                filterDataByCustomFilters = this.filterDataByCustomFilters(data);
            }
          
            return filterListResult && searchResult && filterDataByCustomFilters;
        };
    }

    public hasElementsCustomSearch(): (searchTerm: Array<any>, dataObj: any, key: string) => boolean {
        return (searchTerm: Array<any>, dataObj: any, key: string): boolean => {
            if (!searchTerm || searchTerm.length === 0) {
                return true;
            }
            return searchTerm.some(x => x === (dataObj[key] && dataObj[key].length > 0));
        };
    }

    public exportSelection(nameSheetExcel?: string, nameExcel?: string): void {
        const elements: { column: string, columnName: string, width?: number }[] = this.getElementsToExportExcel();
        const widthColumnsExcel = elements.map(x => x.width);
        const heading = elements.map(x => x.columnName.toUpperCase());
        const dataSource = this.getArrayDataToExportExcel();

        if (dataSource.length > 0) {
            TableUtil.exportArrayToExcel(heading, widthColumnsExcel, dataSource, nameSheetExcel, nameExcel);
        }
    }

    public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

    public isKeyVisible(key: string): boolean {
        if (typeof (key) === 'undefined' || key === null || key === '') {
            return false;
        }

        return this.hiddenGearEntries.findIndex(val => val === key) === -1;
    }

    public abstract deleteSelection(): void;
    protected abstract reloadTableContent(): void;
    protected abstract parseValueToExportExcel(column: string, data: T): number | string;

    protected initializeRequestTracker(): void {
        this.currentRequest = {};
        this.currentRequest.requestAll = true;
    }

    protected saveUserPreferencesSessionStorage(): void {
        sessionStorage.setItem(this.userPreferencesName, JSON.stringify(this.userPreferences));
    }

    protected formatDate(date: Date): string {
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        const year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }

        if (day.length < 2) {
            day = '0' + day;
        }

        return [day, month, year].join('/');
    }

    protected resolveAllGetNotesPromises(promises: Promise<any>[], nameSheetExcel: string, nameExcel: string, parentIdProperty: string, changeRef?: ChangeDetectorRef): void {
        Promise.all(promises).then((response: Array<Array<INote>>) => {
            const notes: INote[] = this.concatPromisesResponses(response);
            this.notesGroupedByParentId = this.groupNotesByParentId(notes, parentIdProperty);
            this.exportSelection(nameSheetExcel, nameExcel);
            if (changeRef) {
                this.isLoading = false;
                changeRef.markForCheck();
            }
        });
    }

    protected concatPromisesResponses(response: INote[][]): INote[] {
        return response.reduce((prev, curr) => { prev.push(...curr); return prev; }, []);
    }

    protected groupNotesByParentId(notes: any[], property: string): {} {
        return  notes.reduce((notesByParentId, note: any) => ({
            ...notesByParentId,
            [note[property]]: [ ...(notesByParentId[note[property]] || []), note ],
        }), {});
    }

    protected transformNotesToString(id: string, datepipe: DatePipe): string {
        return this.notesGroupedByParentId[id] !== undefined ?
            this.notesGroupedByParentId[id]
                .map((note: INote) => '• ' + datepipe.transform(note.creationDate, 'dd/MM/yyyy HH:mm:ss') + ' → ' + note.body).join('\n\n') :
            '';
    }

    protected openNotesDialog(notes: GiocondaGenericDialogComponent): void {
        notes.openDialog({
            panelClass: 'cdk-overlay-notes-modal',
            maxWidth: '40vw',
            maxHeight: '80vh',
            minWidth: '40vw',
            minHeight: '80vh'
        });
    }

    private async getUserPreferences(expectedPreferences: any): Promise<void> {
        this.pageSize = this.PAGE_SIZE_DEFAULT;
        const existingUserPreferences: IUserPreferences = await this.userListPrefencesService.getUserListPreferences(this.userPreferencesName).toPromise();
        if (!existingUserPreferences) {
            this.userPreferences.preferences = JSON.stringify(expectedPreferences);
            this.userPreferences = await this.userListPrefencesService.saveUserListPreferences(this.userPreferences).toPromise();
        }
        else if (this.hasPreferencesStructureChanged(expectedPreferences, existingUserPreferences)) {
            this.userPreferences.id = existingUserPreferences.id;
            this.userPreferences.preferences = JSON.stringify(expectedPreferences);
            this.userPreferences = await this.userListPrefencesService.updateUserListPreferences(this.userPreferences).toPromise();
        }
        else {
            this.userPreferences = existingUserPreferences;
            const newValues = JSON.parse(existingUserPreferences.preferences) as { [key: string]: any };
            this.pageSize = newValues.pageSize;
            this.updateVisibleFields(newValues.visibleFields);
        }

        return Promise.resolve();
    }

    private getElementsToExportExcel(): { column: string, columnName: string, width: number }[] {
        const values = [];
        for (const key in this.visibleFields) {
            if (Object.prototype.hasOwnProperty.call(this.visibleFields, key)) {
                const element = this.visibleFields[key];
                if (element.value && element.exportExcel) {
                    const value = { column: key, columnName: element.valueName, width: element.widthColum };
                    values.push(value);
                }
            }
        }

        return values;
    }

    private getArrayDataToExportExcel(): any[] {
        const filter = (data: T) => {
            const columnsToExportExcel = this.getElementsToExportExcel().map(x => x.column);
            const values = [];
            columnsToExportExcel.forEach((column: string) => {
                values.push(this.parseValueToExportExcel(column, data));
            });

            return values;
        };

        return this.dataTable.filteredData.map(filter);
    }

    private updateVisibleFields(newVisibleFields: { [key: string]: any }): void {
        for (const key in newVisibleFields) {
            const visibleFieldAttributes = this.visibleFields[key];
            if (visibleFieldAttributes) {
                for (const attribute in newVisibleFields[key]) {
                    if (this.visibleFields[key].hasOwnProperty(attribute)) {
                        this.visibleFields[key][attribute] = newVisibleFields[key][attribute];
                    }
                }
            }
        }
    }

    private getUserPreferencesValues(): any {
        const preferences = {} as any;
        preferences.visibleFields = this.visibleFields;
        preferences.pageSize = this.pageSize;
        return preferences;
    }

    private hasPreferencesStructureChanged(expectedPreferences: any, givenPreferences: IUserPreferences): boolean {
        const expectedKeys = Object.keys(expectedPreferences.visibleFields);
        const givenKeys = Object.keys(JSON.parse(givenPreferences.preferences).visibleFields);
        return expectedKeys.some(x => !givenKeys.includes(x)) ||
            givenKeys.some(x => !expectedKeys.includes(x));
    }

    private checkIsElementDisabled(element: T): boolean {
        if (this.isElementDisabled === undefined || this.isElementDisabled === null) {
            return false;
        }

        return this.isElementDisabled(element);
    }
}
