import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Directive()
export abstract class GenericFilterDirective<T> implements OnChanges {
    @Input() public filterName: string = undefined;
    @Input() public dataTable: MatTableDataSource<T>;
    @Input() public isFieldVisible: boolean = undefined;
    @Input() public objectAttributeName: string = undefined;
    @Input() public customSearch: (searchTerm: Array<string>, dataObj: any, key?: string) => boolean = undefined;
    @Input() public customGetValue: (obj: any, key?: string) => string = undefined;
    @Output() public filterChanged = new EventEmitter();
    
    public abstract getFilterPredicate(data: T, filter: string): boolean;
           
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['filterName'] && changes['filterName'].currentValue) {
            this.filterName = changes['filterName'].currentValue;
        }

        if (changes['isFieldVisible'] && !changes['isFieldVisible'].currentValue) {
            this.resetFilters();
        }

        const dataSourceControl: SimpleChange = changes.dataSource;
        if (dataSourceControl && dataSourceControl.currentValue){
            this.dataTable = dataSourceControl.currentValue;
        }
    }

    public resetFilters(): void {
        if (!this.dataTable.filter) {
            return;
        }
        
        let currentFilter = [];
        try {
            currentFilter = JSON.parse(this.dataTable.filter);
            if (currentFilter[this.filterName]) {
                currentFilter[this.filterName] = [];
            }
        }
        finally {
            this.dataTable.filter = JSON.stringify(currentFilter);
            this.filterChanged.emit();
        }
    }

    protected getValue(obj: any, isFullObj = false): string {

        if (typeof(obj) === 'undefined' || obj === null || obj === '') {
            return '';
        }

        if (this.customGetValue) {
            return isFullObj ? this.customGetValue(obj, this.filterName) : obj;
        }

        obj = isFullObj ? obj[this.filterName] : obj;
        if (!this.objectAttributeName || !obj[this.objectAttributeName]) {
            return obj;
        }

        return obj[this.objectAttributeName];        
    }
}
