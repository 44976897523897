import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { AuthenticationService } from 'api/authentication-service/authentication.service';
import { GiocondaModules, ILoggedUserInfo } from 'api/authentication-service/logged-user-info.model';
import { AuthenticatedUserCacheService } from 'app/services/authenticated-user-cache/authenticated-user-cache.service';
import { LoginService } from 'app/services/login/login.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AllowCalendarTaskModuleGuard implements CanActivate {
    public constructor(
        private readonly authenticatedUserProvider: AuthenticatedUserCacheService,
        private readonly loginService: LoginService,
        private readonly authenticationService: AuthenticationService) { }

    public canActivate(): Observable<boolean | UrlTree> {
        return this.authenticationService.getLoggedUserInfo().pipe(
            map((loggedUserInfo: ILoggedUserInfo) => {
                this.authenticatedUserProvider.setLoggedUserInfo(loggedUserInfo);
                if (loggedUserInfo.giocondaModules.includes(GiocondaModules.CalendarTaskModule)) {
                    return true;
                }

                this.loginService.logout('/public/unauthorized?type=2');
            }), catchError (() => of (false) )
        );
    }
}
