import { Component, Input, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'gioc-generic-dialog',
    templateUrl: './gg-generic-dialog.component.html'
})
export class GiocondaGenericDialogComponent {

    @ViewChild('dialogcontent', { static: true }) template: TemplateRef<any>;

    @Input() public displayCloseOption: boolean;
    @Input() public displayButtonBar: boolean;
    @Input() public hasLogos: boolean;
    @Input() public additionalClassDialog: string;
    @Input() public additionalClass: string;
    @Input() public dialogTitle: string;
    @Input() public dialogTitleClass: string;
    @Input() public dialogBody: string;
    @Input() public dialogButton: string;
    @Input() public dialogCloseButon: string;
    @Input() public dialogMessage: string;

    @Output() public confirmDialogData: EventEmitter<any>;
    @Output() public cancelDialogData: EventEmitter<any>;

    public lastOpenedDialog: MatDialogRef<any>;
    private isDialogOpen: boolean;
    private dialogCloseSubscription: Subscription;

    constructor(
        public dialog: MatDialog) {
        this.confirmDialogData = new EventEmitter();
        this.cancelDialogData = new EventEmitter();
    }

    public openDialog(options?: MatDialogConfig): void {
        if (this.isDialogOpen) {
            this.closeDialog();
        }

        this.lastOpenedDialog = this.dialog.open(this.template, options);
        this.dialogCloseSubscription = this.lastOpenedDialog.afterClosed().subscribe((val: any) => {
            this.cancelDialogData.emit(val);
        });
        this.isDialogOpen = true;
    }

    public clickCloseOption(): void {
        if (this.isDialogOpen) {
            this.cancelDialogData.emit();
            this.closeDialog();
        }
    }

    public closeDialog(): void {
        if (this.isDialogOpen) {
            this.lastOpenedDialog.close();
            this.isDialogOpen = false;
            this.dialogCloseSubscription.unsubscribe();
            this.dialogCloseSubscription = undefined;
            this.lastOpenedDialog = undefined;          
        }
    }

    public acceptClick(): void {
        this.closeDialog();
        this.confirmDialogData.emit();
    }
    public cancelClick(): void {
        this.closeDialog();
        this.cancelDialogData.emit();
    }
}
