import { Routes } from '@angular/router';

import { AboutComponent } from 'app/components/gg-about/gg-about.component';
import { Error404Component } from './errors/404.component';
import { AllowExternalCreditValidationGuard } from './guards/allow-external-credit-validation.guard';

export const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'public/home',
        pathMatch: 'full',
    },
    {
        path: 'dentix',
        loadChildren: () => import('./pages/dentix/dentix-zone-entrypoint.module').then(m => m.DentixZoneEntryPointModule)
    },
    {
        path: 'public',
        loadChildren: () => import('./pages/public/public-zone-entrypoint.module').then(m => m.PublicZoneEntryPointModule),
        canActivate: [AllowExternalCreditValidationGuard]
    },
    {
        path: 'manager',
        loadChildren: () => import('./pages/manager/manager-zone-entrypoint.module').then(m => m.ManagerZoneEntrypointModule),
        canActivate: [AllowExternalCreditValidationGuard]
    },
    {
        path: 'creditor',
        loadChildren: () => import('./pages/creditor/creditor-zone-entrypoint.module').then(m => m.CreditorZoneEntryPointModule),
        canActivate: [AllowExternalCreditValidationGuard]
    },
    { 
        path: 'info', 
        component: AboutComponent
    },
    { 
        path: '**', 
        component: Error404Component
    }
];
