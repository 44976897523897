import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
    
    public constructor(
        private cookieService: CookieService) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const requestToken = this.cookieService.get('GIOCONDA-XSRF-REQUEST-TOKEN');                
        if (requestToken) {
            req = req.clone({
                headers: req.headers.set('GIOCONDA-XSRF-TOKEN', requestToken)
            });
        }

        return next.handle(req).pipe(
            catchError((error: any) => {
                const applicationError = error.headers.get('Application-Error');
                if (applicationError) {
                    console.error(applicationError);
                    return throwError(applicationError);
                }
                
                return throwError(error);
            })
        );
    }
}
export const HttpInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
};
