import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root'
})
export class MobileService {
    
    public isMobile: boolean;

    constructor(private _platform: Platform) { 
        if ( this._platform.ANDROID || this._platform.IOS ) {
            this.isMobile = true;
        }
    }

}
