import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'gg-panel-button-footer',
    templateUrl: './gg-panel-button-footer.component.html',
    styleUrls    : ['./gg-panel-button-footer.component.scss'],
    encapsulation  : ViewEncapsulation.None
})

export class PanelButtonFooterComponent implements OnInit {

    @Input() labelCancelButton: string;
    @Input() labelSuccessButton: string;
    @Input() showCancelButton: false;
    @Input() showSuccessButton: false;
    @Input() showImportButton: false;
    @Input() disabledSubmit: boolean;
    @Input() isConfirmButtonTypeSubmit = false;

    @Output() cancelButtonRequested: EventEmitter<any>;
    @Output() successButtonRequested: EventEmitter<any>;

    public titleCancelButton : string;
    public titleSuccessButton : string;

    public constructor() {

            this.cancelButtonRequested = new EventEmitter();
            this.successButtonRequested = new EventEmitter();
    }

    public ngOnInit(): void {
        if ((this.labelCancelButton === null) || (this.labelCancelButton === undefined) || (this.labelCancelButton === '')) {
            this.titleCancelButton = 'Cancelar';
        } else {
            this.titleCancelButton = this.labelCancelButton;
        }
        if ((this.labelSuccessButton === null) || (this.labelSuccessButton === undefined) || (this.labelSuccessButton === '')) {
            this.titleSuccessButton = 'Guardar';
        } else {
            this.titleSuccessButton = this.labelSuccessButton;
        }
    }

    public cancel(event: MouseEvent): void {
        if (event) {
            this.cancelButtonRequested.emit();
        }
    }

    public sucess(event: MouseEvent): void {
        if (event) {
            this.successButtonRequested.emit();
        }
    }
}
