import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'gg-content-iframe',
  templateUrl: './gg-content-iframe.component.html',
  styleUrls: ['./gg-content-iframe.component.scss']
})
export class ContentIframeComponent  implements OnInit {

    @Input() public url: SafeResourceUrl;
    @Input() public width: string;
    @Input() public height: string;
    @Input() public style: string;
    @Input() public name: string;
    @Input() public onload: string;
    
    @Input() public isAutoplay = true;
    @Input() public isVideo = true;

    public autoplay: string;

    public ngOnInit(): void {
        if (this.isVideo) {
            if (!this.isAutoplay) {
                this.autoplay = 'accelerometer; encrypted-media; gyroscope; picture-in-picture';
             } else {
                this.autoplay = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';
            }
        }
    }
}
