import { Component, EventEmitter, Input, Output, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { INote } from 'api/generic-interfaces/note.model';
import { GenericDialogComponent } from 'app/components/generic-dialog/generic-dialog.component';
import { InfoComponent } from 'app/components/gg-info/gg-info.component';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { RegexService } from 'app/services/regex-service/regex.service';
import { RegexCharatersValidatorService } from 'app/services/regex-characters-validator-service/regex-characters-validator.service';

@Component({
    selector: 'gg-notes',
    templateUrl: './gg-notes.component.html',
    styleUrls: ['./gg-notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {
    @ViewChild('creationFormDirective', { static: false }) public creationFormDirective: FormGroupDirective;

    public newNoteVisible = false;
    public ggNotes: any;
    public editMode = [];
    public creationFormGroup: UntypedFormGroup;
    public editionFormGroup: UntypedFormGroup;

    @Input() set saveSuccessful(subject: Subject<INote>)
    {
        if (typeof(subject) === 'undefined') { return; }    
        this.subscribeToSaveCompleted(subject);
    }

    @Input() set updateSuccessful(subject: Subject<INote>)
    {
        if (typeof(subject) === 'undefined') { return; }    
        this.subscribeToUpdateCompleted(subject);
    }

    @Input() set deleteSuccessful(subject: Subject<string>)
    {
        if (typeof(subject) === 'undefined') { return; }    
        this.subscribeToDeleteCompleted(subject);
    }
    @Input() headers = [];
    @Input() maxlength = 500;
    @Input() set notes(newNotes: Array<INote>)
    {
        if (typeof(newNotes) === 'undefined') { return; }       
        this.editMode = [];
        this.setNotes(newNotes);
    }
    @Output() public saveNote = new EventEmitter<INote>();
    @Output() public updateNote = new EventEmitter<INote>();
    @Output() public deleteNote = new EventEmitter<string>();
    @Output() public closeNotesForm = new EventEmitter();

    public get editionFormsGroup(): UntypedFormArray {
        return this.editionFormGroup.controls['notes'] as UntypedFormArray;
    }

    private updateSubscription: Subscription;
    private deleteSubscription: Subscription;
    private saveSubscription: Subscription;
    
    constructor(public dialog: MatDialog, private readonly regexService: RegexService, private regexCharatersValidator: RegexCharatersValidatorService) {}

    public ngOnInit(): void {
        this.creationFormGroup = new UntypedFormGroup({
            body: new UntypedFormControl('', [ Validators.required, Validators.maxLength(this.maxlength), this.regexCharatersValidator.charactersValidator()])
        });

        this.editionFormGroup = new UntypedFormGroup({
            notes: new UntypedFormArray([])
        });
    }
        
    public ngOnDestroy(): void {
        this.unSubscribeEvents();
    }

    public setNotes(notes: Array<INote>): void {
        if (this.editionFormGroup) {
            this.editionFormGroup.controls['notes'] = new UntypedFormArray([]);
        }

        notes.forEach((note: INote) => {
            this.addEditionForm(note);
        });

        this.ggNotes = notes;
        this.newNoteVisible = this.ggNotes.length === 0;         
    }
    
    public save(body: string): void{
        if (!this.creationFormGroup.valid) { 
            return;
        }

        const note: INote  = {       
            body: body,    
            id: null,            
            creationDate: null,
            lastTimeUpdated: null,
            createdUserName: null,
            editedUserName: null
        };        
        this.saveNote.emit(note);
    }

    public update(note: INote, newText: string, index: number): void {
        if (!this.editionFormsGroup.controls[index].valid) {
            return;
        }

        note.body = newText;     
        this.updateNote.emit(note);
    }

    public delete(noteId: string, index: number): void {
        this.removeEditionForm(index);
        this.deleteNote.emit(noteId);
    }

    public addEditionForm(note: INote): void {
        this.editionFormsGroup.push(new UntypedFormGroup({
            body: new UntypedFormControl(note.body, [ Validators.required, Validators.maxLength(this.maxlength), this.regexCharatersValidator.charactersValidator()])
        }));
    }

    public stackEditionForm(note: INote): void {
        this.editionFormsGroup.insert(0, new UntypedFormGroup({
            body: new UntypedFormControl(note.body, [ Validators.required, Validators.maxLength(this.maxlength), this.regexCharatersValidator.charactersValidator()])
        }));
    }

    public removeEditionForm(index: number): void {
        this.editionFormsGroup.removeAt(index);
    }

    public cancelEditMode(note: INote, index: number): void {
        this.editMode[note.id] = false;
        this.editionFormsGroup.controls[index].patchValue({body: note.body});        
    }

    public cancelCreationMode(): void {
        if (this.ggNotes.length === 0) {         
            this.closeNotesForm.emit();
        } else {
            this.newNoteVisible = false;  
        }

        this.creationFormDirective.resetForm();
    }

    private showError(message: string): void {
        this.dialog.open(GenericDialogComponent, {
            data: {
                template: InfoComponent,
                icon: 'info',
                message: message,
                displayButtonBar: true,
                dialogCloseButon: 'Aceptar',
            },
            disableClose: true
        });
        
    }

    private subscribeToSaveCompleted(saveSubject: Subject<INote>): void
    {
        this.saveSubscription = saveSubject.subscribe(
            {
                next: (note: INote) => {
                    this.newNoteVisible = false;
                    this.ggNotes.unshift(note);
                    this.stackEditionForm(note);
                    this.creationFormDirective.resetForm();
                }, 
                error: (error: any) => {
                    this.showError('Se ha producido un error guardando la nota');
                    console.error(error);
                }
            }
        );
    }

    private subscribeToDeleteCompleted(deleteSubject: Subject<string>): void
    {
        this.deleteSubscription = deleteSubject.subscribe(
            {
                next: (id: string) => {
                    const noteToDelete = this.ggNotes.find((x: INote) => x.id === id);
                    this.ggNotes.splice(this.ggNotes.indexOf(noteToDelete), 1);
                    this.newNoteVisible = this.ggNotes.length === 0;
                }, 
                error: (error: any) => {
                    this.showError('Se ha producido un error borrando la nota');
                    console.error(error);
                }
            }
        );
    }

    private subscribeToUpdateCompleted(updateSubject: Subject<INote>): void
    {
        this.updateSubscription = updateSubject.subscribe(
            {
                next: (note: INote) => {
                    const foundNote = this.ggNotes.find( (x: INote) => x.id === note.id);
                    const index = this.ggNotes.indexOf(foundNote);
                    this.ggNotes[index] = note;                    
                    this.editMode[note.id] = false;
                }, 
                error: (error: any) => {
                    this.showError('Se ha producido un error actualizando la nota');
                    console.error(error);
                }
            }
        );
    }
    
    private unSubscribeEvents(): void {
        if (this.updateSubscription) { this.updateSubscription.unsubscribe(); }
        if (this.deleteSubscription) { this.deleteSubscription.unsubscribe(); }
        if (this.saveSubscription) { this.saveSubscription.unsubscribe(); }
    }
}
