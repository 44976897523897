import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { LiquidationManagementButtonTypes } from 'app/components/gg-gioconda-liquidation-management-button/gg-gioconda-liquidation-management-button.component';

@Component({
    selector   : 'fuse-nav-vertical-collapsable',
    templateUrl: './collapsable.component.html',
    styleUrls  : ['./collapsable.component.scss'],
    animations : fuseAnimations
})
export class FuseNavVerticalCollapsableComponent implements OnInit, OnDestroy {
    @Input() item: FuseNavigationItem;
    @HostBinding('class') classes = 'nav-collapsable nav-item';
    @HostBinding('class.open') public isOpen = false;


    public liquidationManagementButtonTypes = LiquidationManagementButtonTypes;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _router: Router
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((event: NavigationEnd) => {
                    this.expand();
            });

        this._fuseNavigationService.onItemCollapsed
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (clickedItem) => {
                    if ( clickedItem && clickedItem.children ) {
                        if ( this.isChildrenOf(this.item, clickedItem) ) {
                            return;
                        }

                        if ( this.isUrlInChildren(this.item, this._router.url) ) {
                            return;
                        }

                        if ( this.item !== clickedItem ) {
                            // this.collapse();
                        }
                    }
                }
            );

        if ( this.isUrlInChildren(this.item, this._router.url) ) {
            this.expand();
        }
        else {
            this.expand();
        }

        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {
             this._changeDetectorRef.markForCheck();
         });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleOpen(ev): void {
        ev.preventDefault();

        this.isOpen = !this.isOpen;

        // Navigation collapse toggled...
        this._fuseNavigationService.onItemCollapsed.next(this.item);
        this._fuseNavigationService.onItemCollapseToggled.next();
    }

    expand(): void {
        if ( this.isOpen ) {
            return;
        }

        this.isOpen = true;

        this._changeDetectorRef.markForCheck();

        this._fuseNavigationService.onItemCollapseToggled.next();
    }

    collapse(): void {
        if ( !this.isOpen ) {
            return;
        }

        this.isOpen = false;

        this._changeDetectorRef.markForCheck();

        this._fuseNavigationService.onItemCollapseToggled.next();
    }

    isChildrenOf(parent, item): boolean {
        if ( !parent.children ) {
            return false;
        }

        if ( parent.children.indexOf(item) !== -1 ) {
            return true;
        }

        for ( const children of parent.children ) {
            if ( children.children ) {
                return this.isChildrenOf(children, item);
            }
        }
    }

    isUrlInChildren(parent, url): boolean {
        if ( !parent.children ) {
            return false;
        }

        for (const element of parent.children) {
            const urlContainedInNode = element.children && this.isUrlInChildren(element, url);
            const isElementUrl = element.url === url || url.includes(element.url);

            if (urlContainedInNode || isElementUrl) {
                return true;
            }
        }

        return false;
    }

}
