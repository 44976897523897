import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { ICreditorProceeding } from 'api/creditor-proceeding-service/creditor-proceeding.model';

import { Observable } from 'rxjs';
import { ICreditorDocument } from './creditor-document.model';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    private endpointDocumentUrl: string;
    private endpointProceedingCreditorDocuments: string;
    private endpointDocumentsListUrl: string;
    private endPointNewDocumentFromApplicationTemplateUrl: string;
    private endPointNewCustomFromApplicationTemplateUrl: string;
    
    constructor(
        private readonly apiProcessor: ApiProcessorService,
        private readonly environment: Configuration) {
        
        this.endpointDocumentUrl = this.environment.apiUrl + 'proceeding-management/documents';
        this.endpointDocumentsListUrl = this.environment.apiUrl + 'proceeding-management/proceedings';
        this.endpointProceedingCreditorDocuments = this.environment.apiUrl + 'credit-management/proceedings';
        this.endPointNewDocumentFromApplicationTemplateUrl = this.environment.apiUrl + 'proceeding-management/create-document-from-application-template';
        this.endPointNewCustomFromApplicationTemplateUrl = this.environment.apiUrl + 'proceeding-management/create-document-from-custom-template';
    }

    public getDocument(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointDocumentUrl}/${id}`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getProceedingDocuments(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.endpointUrl = `${this.endpointDocumentsListUrl}/${proceedingId}/documents`;
        apiRequest.operation = ApiOperation.GET;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getProceedingCreditorDocuments(proceedingId: string, creditorId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.endpointUrl = `${this.endpointProceedingCreditorDocuments}/${proceedingId}/documents?creditorId=${creditorId}`;
        apiRequest.operation = ApiOperation.GET;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public saveDocument(document: ICreditorDocument): Observable<any> {
        const creditorDocuments = document.proceedingCreditorDocumentsReduced;
        document.proceedingCreditorDocumentsReduced = null;

        const reqBody = this.apiProcessor.setBody(document);
        creditorDocuments.forEach((proceedingCreditorDocument, index: number) => {
            reqBody.append(`proceedingCreditorDocumentsReduced[${index}].proceedingCreditorId`, proceedingCreditorDocument.proceedingCreditorId);
        });

        return this.apiProcessor.postObject(this.endpointDocumentUrl, reqBody);
    }

    public updateDocument(documentId: string, document: ICreditorDocument): Observable<any> {
        const creditorDocuments = document.proceedingCreditorDocumentsReduced;
        document.proceedingCreditorDocumentsReduced = null;
        
        const reqBody = this.apiProcessor.setBody(document);
        creditorDocuments.forEach((proceedingCreditorDocument, index: number) => {
            reqBody.append(`proceedingCreditorDocumentsReduced[${index}].proceedingCreditorId`, proceedingCreditorDocument.proceedingCreditorId);
        });
        const url = `${this.endpointDocumentUrl}/${documentId}`;
        return this.apiProcessor.putObject(url, reqBody);
    }

    public markDocumentAsViewed(documentId: string, proceedingId: string, creditorId: string): Observable<any> {        
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.endpointDocumentUrl}/${documentId}/mark-document-as-viewed?proceedingId=${proceedingId}&creditorId=${creditorId}`;
        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public deleteDocumentById(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${this.endpointDocumentUrl}/${id}`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public deleteDocumentCollection(documentsIds: string[]): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl =  this.endpointDocumentUrl ;
        apiRequest.body = JSON.stringify(documentsIds);
        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getDocumentForDownload(documentId: string, fileName: string): Observable<any> {
        const url = `${this.endpointDocumentUrl}/${documentId}/file`;
        return this.apiProcessor.downloadFile(url, fileName);
    }

    public getDocumentForAttach(documentId: string, fileName: string): Observable<any> {
        const url = `${this.endpointDocumentUrl}/${documentId}/file`;
        return this.apiProcessor.downloadFilewithoutSaving(url, fileName);
    }

    public getUrl(documentId: string): string {
        return `${this.endpointDocumentUrl}/${documentId}/file`;
    }
    
    public newDocumentFromApplicationDocumentTemplate(
        proceedingId: string,
        applicationDocumentTemplateId: string,
        proceedingCreditors: ICreditorProceeding[] = []
    ): Observable<any> 
    {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = this.endPointNewDocumentFromApplicationTemplateUrl;
        apiRequest.body = {
            proceedingId: proceedingId,
            templateId: applicationDocumentTemplateId,
            proceedingCreditorIds: proceedingCreditors.map(pc => pc.id)
        };

        return this.apiProcessor.proccessRequest(apiRequest);
    }
    
    public newDocumentFromCustomDocumentTemplate(proceedingId: string, customDocumentTemplateId: string): Observable<any> 
    {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = this.endPointNewCustomFromApplicationTemplateUrl;
        apiRequest.body = {
            proceedingId: proceedingId,
            templateId: customDocumentTemplateId
        };

        return this.apiProcessor.proccessRequest(apiRequest);
    }   
}
