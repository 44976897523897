import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Observable } from 'rxjs';
import { ICreditNote } from './credit-note.model';

@Injectable({
  providedIn: 'root'
})

export class CreditNotesService {
    private creditNotesEndPointUrl = 'credit-notes';
    private endpointUrl: string;
    
    constructor(private readonly apiProcessorService: ApiProcessorService, private readonly environment: Configuration) {
        
        this.endpointUrl = this.environment.apiUrl + 'credit-management';
    }

    public getCreditNotesByCreditId(creditId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/credits/${creditId}/${this.creditNotesEndPointUrl}/`;
        
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getCreditNotesByCreditIds(creditIds: string[]): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.endpointUrl}/credit-notes/generate`;
    
        apiRequest.body = creditIds;
        
        return this.apiProcessorService.proccessRequest(apiRequest);
    }  

    public saveCreditNote(creditNote: ICreditNote): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.endpointUrl}/${this.creditNotesEndPointUrl}`;
        apiRequest.body = creditNote; 

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public updateCreditNote(creditNote: ICreditNote): Observable<any> {    
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${this.endpointUrl}/${this.creditNotesEndPointUrl}/${creditNote.id}`;
        apiRequest.body = creditNote;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public deleteCreditNote(creditNoteId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${this.endpointUrl}/${this.creditNotesEndPointUrl}/${creditNoteId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
