import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, concatMap, filter } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { IRequest } from 'api/generic-interfaces/generic-request.interface';
import { Router, UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ApiProcessorService {

    constructor(private readonly http: HttpClient, private readonly router: Router) { }

    public proccessRequest<T>(request: ApiRequest): Observable<ApiResponse<T> | UrlTree> {
        if (request.headers == null) { request.headers = new HttpHeaders({ 'Content-Type': 'application/json' }); }
        const requestHeaders = request.headers;
        return this.http.request<ApiResponse<T>>(request.operation, request.endpointUrl, {
            body: request.body, headers: requestHeaders, withCredentials: true
        }).pipe(catchError(this.handleError<ApiResponse<T>>()));
    }
    
    public setBody(objectToSend: any): FormData {
        const reqBody = new FormData();
        const propertyNames = Object.getOwnPropertyNames(objectToSend);

        for (const name of propertyNames) {
            const obj =  objectToSend[name];
            if (obj === null || typeof(obj) === 'undefined' ){ continue; }  
            else if (typeof obj.getMonth === 'function'){
                reqBody.append(name, objectToSend[name].toJSON());
            }         
            else if (typeof obj === 'boolean'){
                reqBody.append(name, JSON.stringify(objectToSend[name]));
            }
            else if (typeof(obj) === 'object' && Object.getOwnPropertyNames(obj).length > 0){
                const date = new Date(obj);
                if (!isNaN(date.getTime())) {
                    reqBody.append(name, date.toJSON());
                } else {
                    this.setBodyFromObject(obj, name, reqBody);
                }                 
            }
            else{
                reqBody.append(name, objectToSend[name]);
            }
        } 
        return reqBody;
    }

    public postObject(url: string, reqBody: any): Observable<any>{
        return this.http.post(url, reqBody, {reportProgress: true, observe: 'events', withCredentials: true});
    }

    public putObject(url: string, reqBody: any): Observable<any>{
        return this.http.put(url, reqBody, {reportProgress: true, observe: 'events', withCredentials: true});
    }

    public getFile(url: string): Observable<ArrayBuffer> {       
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.get(url, {responseType: 'arraybuffer', headers: headers, withCredentials: true});      
    }

    public downloadFile(url: string, fileName: string): Observable<any> {
        return new Observable((observer) => {    
            
            const ext = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase();
            let openNewTab = false;
            if (ext === 'pdf' || ext === 'txt')
            {
                openNewTab = true;
            }

            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            this.http.get(url, {responseType: 'arraybuffer', headers: headers, observe: 'events', withCredentials: true})            
            .subscribe({
                next: (response: any) => {
                    if (response.type === HttpEventType.Response) {
                        if (response.status === 200) {
                            const contetType = response.headers.get('content-type');
                            const nameWithoutDirectory = fileName.substring(fileName.lastIndexOf('/') + 1, fileName.length);
                            this.saveDownloadedFile(response.body, nameWithoutDirectory, contetType, openNewTab);
                            observer.complete();
                        }
                        else {
                            observer.error();
                        }
                    }                       
                },
                error: (error: any) => {
                    if (error && error.status === 401) {
                        observer.error(this.router.parseUrl('/public/unauthorized'));
                    } else {
                        observer.error(error);
                    }
                } 
            });
        });
    }

    public downloadFilewithoutSaving(url: string, fileName: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.get(url, {responseType: 'arraybuffer', headers: headers, observe: 'events', withCredentials: true})
            .pipe(filter((response: any) => response.type === HttpEventType.Response), concatMap((response: any) => {
                if (response.status === 200) {

                    let fileObject = {};
                    const contentType = response.headers.get('content-type');
                    const currentTime = new Date().getTime();
                    try {
                        fileObject = new File([response.body], fileName, { type: contentType, lastModified: currentTime });
                    } catch (exception) {
                        console.warn(exception); // Fallo al ejecutar el constructor del File, IE11 posiblemente
                        fileObject = new Blob([response.body], { type: contentType }) as any;
                        fileObject['name'] = fileName;
                        fileObject['fileName'] = fileName;
                        fileObject['lastModifiedDate'] = currentTime;
                    }

                    return of(fileObject);
                } else {
                    return throwError({ message: 'El fichero no ha podido recuperarse del servidor' });
                }
            }));
    }

    public saveDownloadedFile(data: any, fileName: string, contentType: string, openNewTab?: boolean): void {
        
        const blob = new Blob([data], {type: contentType});
        
        const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
        fileName = fileNameWithoutExtension.replace(/\./g, '_');
        
        if (openNewTab !== null && typeof(openNewTab) !== 'undefined' && openNewTab){
            this.openBlobInNewTab(fileName, blob);
        } else {
            this.saveBlobFromBrowser(fileName, blob);
        }
        
    }
    
    public getRequestBodyAsUrlParams(request: IRequest): string {
        const clonnedRequest = Object.assign({}, request);
        const params = [];
        const range = clonnedRequest['range'];

        delete clonnedRequest.range;

        if (range) {
            params.push(`range=${ range }`);
        }
                      
        for (const item in clonnedRequest) {
            if (item) {
                if (typeof clonnedRequest[item] === 'string'){
                    params.push(`${item}=${clonnedRequest[item]}`);    
                }else {
                    params.push(`${item}=${JSON.stringify(clonnedRequest[item])}`);
                }                
            }
        }

        return params.join('&');
    }
    
    private setBodyFromObject(obj: any, name: string, reqBody: FormData): void{
        if (obj instanceof Array) {                      
            obj.forEach((x, index: number) => { 
                const innerBody = this.setBody(x);
                (innerBody as any).entries().forEach((element: any) => {
                    reqBody.append(`${name}[${index}].${element[0]}`, element[1] as string | Blob);
                });
            });        
        }else {
            const innerBody = this.setBody(obj);
            (innerBody as any).entries().forEach((element: any) => {
                reqBody.append(`${name}.${element[0]}`, element[1] as string | Blob);
            });
        }       
    }

    private saveBlobFromBrowser(fileName: string, blob: Blob): void {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.href = url;            
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }

    private openBlobInNewTab(fileName: string, blob: Blob): void {
        const url = window.URL.createObjectURL(blob);
        const win  = window.open(url, '_blank');    
        win.onbeforeunload = () =>
        {
            window.URL.revokeObjectURL(url);
        };
    }

    private handleError<T>(): (arg: any) => Observable<T | UrlTree> {
        return (error: any): Observable<T> => {
            if (error && error.status === 401) {
                return new Observable((observer) => observer.error(this.router.parseUrl('/public/unauthorized')));
            } 

            return throwError(error);
        };
    }
}

export class ApiResponse<T> {
    public response: Response;
    public data: T;
}

export class ApiRequest {
    public operation: ApiOperation;
    public endpointUrl: string;
    public body?: any;
    public headers: HttpHeaders = null;
}

export enum ApiOperation {
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH'
}

export class UpdatingFileStatus {
    xMLHttpRequest: XMLHttpRequest;
    progressEvent: ProgressEvent;
}
