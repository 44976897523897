import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProceedingCreditorCommunicationEmailsService } from 'api/communication-service/proceeding-creditor-communication-emails-service/proceeding-creditor-communication-emails.service';

@Component({
    selector: 'proceeding-preview-modal',
    templateUrl: './proceeding-preview-modal.component.html',
    styleUrls: ['./proceeding-preview-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProceedingPreviewModalComponent implements OnInit {

    public previewTitle = 'Comunicación Enviada';
    public previewBody: string;
    public showResendEmailButton = false;
    private proceedingCreditorEmailId: string;
    
    public constructor(
        public dialogRef: MatDialogRef<ProceedingPreviewModalComponent>, 
        @Inject(MAT_DIALOG_DATA) public data,
        private readonly proceedingCreditorCommunicationEmailService: ProceedingCreditorCommunicationEmailsService) { }
    
    public ngOnInit(): void {
        if (!this.data) {
            return;
        }
        
        if (this.data.html) {
            this.previewBody =  this.data.html;
        }

        if (this.data.title) {
            this.previewTitle = this.data.title;
        }

        if (this.data.proceedingCreditorEmailId) {
            this.proceedingCreditorEmailId = this.data.proceedingCreditorEmailId;
        }

        if (this.data.showResendEmailButton) {
            this.showResendEmailButton = this.data.showResendEmailButton;
        }
    }

    public resendEmail(): void {
        if (!this.proceedingCreditorEmailId) {
            return;
        }

        this.proceedingCreditorCommunicationEmailService.resendEmail(this.proceedingCreditorEmailId)
            .subscribe(() => {
                this.dialogRef.close({ value: 'resendEmail' });
            });
    }

    public closeForm(): void {
        this.dialogRef.close();
    }
}
