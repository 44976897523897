import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICustomFilterMatcheckbox, ICustomFilterMatcheckboxOptions } from '../selection-list/selection-list.component';

@Component({
  selector: 'gg-custom-filter-matcheckbox-options',
  templateUrl: './gg-custom-filter-matcheckbox-options.component.html',
  styleUrls: ['./gg-custom-filter-matcheckbox-options.component.scss']
})
export class CustomFilterMatcheckboxOptionsComponent<T> {

    @Input() public set customFilterMatcheckboxOptions(value: ICustomFilterMatcheckboxOptions<T>) {
        if (value) {
            this.externalCustomFilterMatcheckboxOptions = value;
        }
    }
    
    @Output() public notifyCustomFilterCheckBoxChange = new EventEmitter();
    @Output() public notifyAllCustomFilterCheckBoxUnchecked = new EventEmitter();
    
    public externalCustomFilterMatcheckboxOptions: ICustomFilterMatcheckboxOptions<T>;
    public customFilterMatcheckboxOptionsChecked = new Array<ICustomFilterMatcheckbox>();
    public toolTip = 'No tiene Filtros Aplicados';
    public statusCheck = 'smaller off hidden';
    
    public onCustomFilterCheckBoxChange(customFilterMatcheckbox: ICustomFilterMatcheckbox, onChangeFunction: (isChecked: boolean) => void, isChecked: boolean): void {
        if (isChecked) {
            this.customFilterMatcheckboxOptionsChecked.forEach((cfm: ICustomFilterMatcheckbox) => {
                cfm.onChangeFunction(false);
                cfm.checked = false;
            });
            this.customFilterMatcheckboxOptionsChecked = new Array<ICustomFilterMatcheckbox>();     
            this.customFilterMatcheckboxOptionsChecked.push(customFilterMatcheckbox);
            this.toolTip = 'Eliminar Filtros Aplicados';
            this.statusCheck = 'smaller on';
        } else {
            const index = this.customFilterMatcheckboxOptionsChecked.indexOf(customFilterMatcheckbox);
            if (index >= 0) {
                this.customFilterMatcheckboxOptionsChecked.splice(index, 1);
            }
            this.toolTip = 'No tiene Filtros Aplicados';
            this.statusCheck = 'smaller off hidden';
        }

        onChangeFunction(isChecked);
        this.notifyCustomFilterCheckBoxChange.emit();
    }

    public removeFilters(): void {
        this.customFilterMatcheckboxOptionsChecked.forEach((cfm: ICustomFilterMatcheckbox) => {
            cfm.onChangeFunction(false);
            cfm.checked = false;
            this.toolTip = 'No tiene Filtros Aplicados';
            this.statusCheck = 'smaller off hidden';
        });

        this.customFilterMatcheckboxOptionsChecked = new Array<ICustomFilterMatcheckbox>();        
        this.notifyAllCustomFilterCheckBoxUnchecked.emit();        
    }

    public getCustomTableSearchFilterPredicate(): (data: T) => boolean {
        return this.externalCustomFilterMatcheckboxOptions.customTableSearchFilterPredicate;
    }
}
