import { Injectable } from '@angular/core';
import { ApiProcessorService } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable } from 'rxjs';

@Injectable()
export class StaticContentService {
    private readonly endpointUrl: string;

    public constructor(private readonly apiProcessorService: ApiProcessorService, private readonly environment: Configuration) {
        this.endpointUrl = `${ this.environment.apiUrl }staticcontent-management/assets`;
    }

    public downloadStaticFile(assetName: string): Observable<any> {
        const assetUrl = `${ this.endpointUrl }/?assetName=${ assetName }`;
        return this.apiProcessorService.downloadFile(assetUrl, assetName);
    }
}
