import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Observable } from 'rxjs';

@Injectable()
export class TemplatePhaseTypeService {
    private endpointUrl: string;
    
    constructor(private readonly apiProcessorService: ApiProcessorService, private readonly environment: Configuration) {
        
        this.endpointUrl = this.environment.apiUrl + 'masterdata-management/template-phase-types';
    }
        
    public getAll(): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.endpointUrl;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }
}
