import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FuseNavigation } from '@fuse/types';
import { GiocondaModules, ILoggedUserInfo } from 'api/authentication-service/logged-user-info.model';
import { Configuration } from 'api/configuration-service/configuration.model';
import { IOffice } from 'api/office-service/office.model';

import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthenticatedUserCacheService } from '../authenticated-user-cache/authenticated-user-cache.service';
import { SamlClientService } from '../saml-client-service/saml-client.service';

@Injectable()
export class FuseSegmentedNavigationService {
    public fuseNavigationEvent: BehaviorSubject<Array<FuseNavigation>>;

    private initialMenuEntries = ['proceedings', 'offices', 'insolvency-practice', 'liquidations-info'];
    private officesMenuEntries = ['offices', 'add-office', 'settings', 'emails-templates', 'documents-templates', 'insolvency-practice', 'liquidations-info'];
    private userMenuEntries = ['proceedings', 'creditors', 'credits', 'documents', 'emails', 'assets-proceeding', 'productive-units', 'accounting', 'accounting-creditors',
        'insolvency-practice', 'credits-against-estate', 'incomes', 'letters', 'liquidations-info', 'liquidations', 'bids', 'tenderers', 'calendar-tasks', 'liquidation-management'];

    private inAllMenus = ['insolvency-practice', 'training', 'liquidations-info'];

    private lastUrlFragment: string;
    private lastProcessedUrl: string;
    private urlHasGuid: boolean;
    private currentProceedingId: string;
    private currentOfficeId: string;

    private readonly GUID_V4_REGEX = /[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-4[a-zA-Z0-9]{3}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/;
    private readonly PROCEEDING_ID_REGEX = /(manager\/proceedings\/)[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-4[a-zA-Z0-9]{3}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/;
    private readonly OFFICE_ID_REGEX = /(manager\/offices\/)[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-4[a-zA-Z0-9]{3}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/;
    
    public constructor(
        private readonly router: Router,
        private readonly authenticatedUserProvider: AuthenticatedUserCacheService,
        private readonly environment: Configuration,
        private readonly samlClientService: SamlClientService,
    ) {

        this.fuseNavigationEvent = new BehaviorSubject(undefined);
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
            (event: NavigationEnd) => {
                if (event && event.url !== undefined) {

                    const urlFragments = (event.urlAfterRedirects || event.url).replace('/manager', '').split('/').filter((val: string) => val && val !== '');

                    this.urlHasGuid = this.GUID_V4_REGEX.test((event.urlAfterRedirects || event.url));
                    if (urlFragments.length === 0) {
                        return;
                    }

                    let nextUrlFragment = urlFragments[0];

                    if (this.initialMenuEntries.includes(nextUrlFragment) && urlFragments.length > 2){
                        nextUrlFragment = urlFragments[2];                        
                        this.urlHasGuid = this.GUID_V4_REGEX.test((event.urlAfterRedirects || event.url).replace('/manager/proceedings/' + urlFragments[1], ''));
                    }

                    const sectionToRender = this.getSectionToRender(urlFragments);
                    
                    if (sectionToRender === NavigationSection.UNDEFINED) {
                        return;
                    }

                    this.lastUrlFragment = nextUrlFragment;
                    this.lastProcessedUrl = (event.urlAfterRedirects || event.url);

                    const proceedingRegexResult = this.PROCEEDING_ID_REGEX.exec(this.lastProcessedUrl);
                    if (proceedingRegexResult){
                        this.currentProceedingId = proceedingRegexResult[0].replace('manager/proceedings/', '');
                    }

                    const OfficesgRegexResult = this.OFFICE_ID_REGEX.exec(this.lastProcessedUrl);
                    if (OfficesgRegexResult){
                        this.currentOfficeId = OfficesgRegexResult[0].replace('manager/offices/', '');
                    }

                    switch (sectionToRender) {
                        case NavigationSection.INITIAL:
                            this.getInitialMenu().then(menu => this.commonMenuOutcomeHandler(menu));
                            break;
                        case NavigationSection.USERLAND:
                            this.getUserlandMenu().then(menu => this.commonMenuOutcomeHandler(menu));
                            break;
                        case NavigationSection.OFFICESLAND:
                            this.getOfficesMenu().then(menu => this.commonMenuOutcomeHandler(menu));
                            break;
                        default:
                            this.getInitialMenu().then(menu => this.commonMenuOutcomeHandler(menu));
                            break;
                    }
                }
            }
        );
    }

    private commonMenuOutcomeHandler(menu: Array<FuseNavigation>): void {
        if (menu) {
            this.fuseNavigationEvent.next(menu);
        }
    }

    private getSectionToRender(urlFragments: string[]): NavigationSection {

        const section =  urlFragments[0];
        const isSubSection = urlFragments.length > 1;

        if (this.initialMenuEntries[0].includes(section) && (!isSubSection || urlFragments.includes('edit-proceeding') || urlFragments.includes('add-proceeding'))) {
            return NavigationSection.INITIAL;
        } else if (this.userMenuEntries[0].includes(section) && isSubSection) {
            return NavigationSection.USERLAND;
        } else if (this.officesMenuEntries[0].includes(section)) {
            return NavigationSection.OFFICESLAND;
        }

        if (this.inAllMenus.includes(section)) {
            if (urlFragments.length === 1){
                return NavigationSection.INITIAL;
            } else {
                return NavigationSection.USERLAND;
            }
        } else {
            return NavigationSection.UNDEFINED;
        }
    }

    private async setOfficeId(): Promise<void> {

        const offices: IOffice[] = await this.authenticatedUserProvider.getCurrentOffices();
        if (offices && offices.length > 0) {
            this.currentOfficeId = offices[0].id;
            return Promise.resolve();
        }
    }

    private async getInitialMenu(): Promise<Array<FuseNavigation>> {
        
        this.setOfficeId();

        const menuItems: Array<FuseNavigation> = [
            this.getProceedingsEntry(),
            this.getOfficesEntry(),
            this.getInsolvencyPracticeEntry(),
            this.getLiquidationsInfoEntry()
        ];

        return Promise.resolve(menuItems.filter(x => x != null));
    }

    private async getUserlandMenu(): Promise<Array<FuseNavigation>> {
        this.setOfficeId();

        const menuItems = [
            this.getProceedingLiquidationRequestButtonEntry(),
            this.getAssetsEntry(),
            this.getLiabilitiesEntry(),
            this.getDocumentsEntry(),
            this.getEmailSections(),
            this.getLiquidationsEntry(),
            this.getTreasurySections(),
            this.getAccountingEntry(),
            await this.getCalendarTasksEntry(),
            this.getInsolvencyPracticeEntry(),
            this.getLiquidationsInfoEntry()
        ];
        
        return Promise.resolve(menuItems.filter(x => x != null));
    }


    private async getOfficesMenu(): Promise<Array<FuseNavigation>> {
        this.setOfficeId();

        const menuItems: Array<FuseNavigation> = [
            this.getOfficeSettings(),
            this.getOfficeDocumentsTemplates(),
            this.getOfficeMailTemplates(),
            this.getInsolvencyPracticeEntry(),
            this.getLiquidationsInfoEntry(),
        ];

        return Promise.resolve(menuItems.filter(x => x != null));
    }

    private getProceedingsEntry(): FuseNavigation {

        const urlProceedings = `/manager/proceedings`;

        return {
            id: 'proceedings',
            title: 'Concursos',
            type: 'item',
            icon: 'news',
            url: urlProceedings,
            classes: this.lastUrlFragment === 'proceedings' || this.lastUrlFragment === 'edit-proceeding' || this.lastUrlFragment === 'add-proceeding' ? 'accent' : undefined,
        };
    }

    private getOfficesEntry(): FuseNavigation {
        const urlOfficesSettings = `/manager/offices/${this.currentOfficeId}/settings`;
        const urlOfficesNew = `/manager/offices/add-office`;
        let urlOffices: string;

        if (this.currentOfficeId) {
            urlOffices = urlOfficesSettings;
        } else  {
            urlOffices = urlOfficesNew;
        } 

        return {
            id: 'offices',
            title: 'Despacho',
            type: 'item',
            icon: 'lunchbox',
            url: urlOffices,
            classes: this.lastUrlFragment === urlOffices ? 'accent' : undefined,
        };
    }

    private getInsolvencyPracticeEntry(): FuseNavigation {

        const isSectionOffices: boolean = this.lastProcessedUrl.includes('manager/offices/');
        let practiceUrl = this.environment.contentSiteUrl;
        const samlClientIdQueryParam: string = this.samlClientService.getSamlClientId();
        if (samlClientIdQueryParam) {
            practiceUrl = this.samlClientService.appendSamlClientIdAsQueryParameter(practiceUrl);
        }

        let classItem = 'practice';
        if (isSectionOffices && !this.currentOfficeId) {
            classItem = 'practice disabled';
        }
        return {
            id: 'insolvency-practice',
            title: 'Práctica Concursal',
            externalUrl: true,
            openInNewTab: true,
            type: 'item',
            matIcono: 'book',
            url: practiceUrl,
            classes: classItem,
        };
    }
    
    private getLiquidationsInfoEntry(): FuseNavigation {

        const isSectionOffices: boolean = this.lastProcessedUrl.includes('manager/offices/');
        const isSectionProceeding: boolean = this.lastProcessedUrl.includes('manager/proceedings/');
        const urlOffices = this.currentOfficeId ? `/manager/offices/${this.currentOfficeId}/liquidations-info` : `/manager/offices/liquidations-info` ;
        const urlProceeding = this.currentOfficeId ? `/manager/proceedings/${this.currentProceedingId}/liquidations-info` : `/manager/proceedings/liquidations-info` ;
        
        let urlLiquidations = `/manager/liquidations-info`;

        if (isSectionOffices) {
            urlLiquidations = urlOffices;
        } else if (isSectionProceeding) {
            urlLiquidations = urlProceeding;
        } 

        let classItem: string;

        if (isSectionOffices && !this.currentOfficeId) {
            classItem = 'logo-auctions disabled';
        } else  {
            classItem = this.lastUrlFragment === 'liquidations-info' ? 'logo-auctions accent' : 'logo-auctions';
        }

        return {
            id: 'liquidations-info',
            title: '',
            type: 'item',
            icon: '',
            url: urlLiquidations,
            classes: classItem,
        };
    }

    private getProceedingLiquidationRequestButtonEntry(): FuseNavigation {        
        return {
            id: 'liquidation-management',
            title: '',
            type: 'item',
            icon: '',            
            classes: '',
            url: '',
            showProceedingLiquidationRequestButton: true,
        };
    }
    
    private getAssetsEntry(): FuseNavigation {
        return {
            id: 'assets',
            title: 'Activo',
            type: 'collapsable',
            icon: 'activo',
            children: [
                {
                    id: 'assets-list-proceeding',
                    title: 'Bienes',
                    type: 'item',
                    url: `/manager/proceedings/${this.currentProceedingId}/assets-proceeding`,
                    classes: this.lastUrlFragment === 'assets-proceeding' ? 'accent' : undefined,
                },
                {
                    id: 'productive-units',
                    title: 'Unidades Productivas',
                    type: 'item',
                    url: `/manager/proceedings/${this.currentProceedingId}/productive-units`,
                    classes: this.lastUrlFragment === 'productive-units' ? 'accent' : undefined,
                }
            ]
        };
    }

    private getLiabilitiesEntry(): FuseNavigation {
        const isCreditors = this.lastUrlFragment === 'creditors';
        const isCreditorCredits = this.lastUrlFragment === 'credits' && this.urlHasGuid && !this.lastProcessedUrl.includes('edit-credit');
        const isCreditorDocuments = this.isUrlWithSecondToLastFragment('documents');
        const isCreditorAccounting = this.isUrlWithSecondToLastFragment('accounting');
        const isCreditorEmails = this.isUrlWithSecondToLastFragment('emails');
        const isCreditorCreditsAgainstEstate = this.isUrlWithSecondToLastFragment('credits-against-estate');
        const isCredits = this.lastUrlFragment === 'credits' && !isCreditorCredits && !isCreditorDocuments;

        return {
            id: 'liabilities',
            title: 'Pasivo',
            type: 'collapsable',
            icon: 'pasivo',
            children: [
                {
                    id: 'creditors',
                    title: 'Acreedores',
                    type: 'item',
                    url: `/manager/proceedings/${this.currentProceedingId}/creditors`,
                    classes: (isCreditors || isCreditorCredits || isCreditorDocuments || isCreditorAccounting
                                || isCreditorEmails || isCreditorCreditsAgainstEstate) ? 'accent' : undefined,
                    children:
                        [
                            {
                                id: 'editCreditorCredit',
                                title: 'editCreditorCredit',
                                type: 'item',
                                url: `/manager/proceedings/${this.currentProceedingId}/creditors/add-creditor`,
                                hidden: true
                            },
                            {
                                id: 'addCreditor',
                                title: 'addCreditor',
                                type: 'item',
                                url: `/manager/proceedings/${this.currentProceedingId}/creditors/add-creditor/`,
                                hidden: true
                            }
                        ]
                },
                {
                    id: 'credits-list-proceeding',
                    title: 'Créditos',
                    type: 'item',
                    url: `/manager/proceedings/${this.currentProceedingId}/credits/proceeding-credits`,
                    classes: isCredits ? 'accent' : undefined,
                    children: [
                        {
                            id: 'editCredit',
                            title: 'editCredit',
                            type: 'item',
                            url: `/manager/proceedings/${this.currentProceedingId}/credits/edit-credit`,
                            hidden: true
                        },
                        {
                            id: 'addCredit',
                            title: 'addCredit',
                            type: 'item',
                            url: `/manager/proceedings/${this.currentProceedingId}/credits/add-credit`,
                            hidden: true
                        }
                    ]
                }
            ]
        };
    }

    private getDocumentsEntry(): FuseNavigation {
        const isDocuments = this.lastUrlFragment === 'documents';
        const isCreditorDocuments = this.isUrlWithSecondToLastFragment('documents');
        return {
            id: 'documents',
            title: 'Documentación',
            type: 'item',
            matIcono: 'description',
            url: `/manager/proceedings/${this.currentProceedingId}/documents`,
            classes: isDocuments && !isCreditorDocuments ? 'accent' : undefined,
        };
    }

    private getEmailSections(): FuseNavigation {
        const isCreditorEmails = this.isUrlWithSecondToLastFragment('emails');
        return {
            id: 'emails',
            title: 'Comunicaciones',
            type: 'item',
            matIcono: 'email',
            url: `/manager/proceedings/${this.currentProceedingId}/emails/list-emails`,
            classes: (this.lastUrlFragment === 'emails' && !isCreditorEmails) || this.lastUrlFragment === 'letters' ? 'accent' : undefined,
            children: [
                {
                    id: 'letters',
                    title: 'letters',
                    type: 'item',
                    url: `/manager/proceedings/${this.currentProceedingId}/letters`,
                    hidden: true
                }                
            ]
        };
    }

    private getLiquidationsEntry(): FuseNavigation {

        return {
            id: 'liquidations',
            title: 'Procesos de Liquidación',
            type: 'item',
            matIcono: 'shopping_cart',
            url: `/manager/proceedings/${this.currentProceedingId}/liquidations`,
            classes: this.lastUrlFragment === 'liquidations' || this.lastUrlFragment === 'bids' || this.lastUrlFragment === 'tenderers' ? 'accent' : undefined            
        };
    }

    private getTreasurySections(): FuseNavigation {
        const isCreditorCreditsAgainstEstate = this.isUrlWithSecondToLastFragment('credits-against-estate');
        return {
            id: 'treasury',
            title: 'Tesorería',
            type: 'collapsable',
            icon: 'payment',
            children: [
                {
                    id: 'credits-against-estate',
                    title: 'Créditos contra la Masa',
                    type: 'item',
                    url: `/manager/proceedings/${this.currentProceedingId}/credits-against-estate`,
                    classes: (this.lastUrlFragment === 'credits-against-estate' || this.lastUrlFragment === 'suppliers' || 
                             this.lastUrlFragment === 'credit-against-estate-payments') && !isCreditorCreditsAgainstEstate ? 'accent' : undefined,
                    children:
                        [
                            {
                                id: 'suppliers',
                                title: 'suppliers',
                                type: 'item',
                                url: `/manager/proceedings/${this.currentProceedingId}/suppliers`,
                                hidden: true,
                                children: [
                                    {
                                        id: 'editSupplier',
                                        title: 'editSupplier',
                                        type: 'item',
                                        url: `/manager/proceedings/${this.currentProceedingId}/suppliers/edit-supplier`,
                                        hidden: true
                                    },
                                    {
                                        id: 'addSupplier',
                                        title: 'addSupplier',
                                        type: 'item',
                                        url: `/manager/proceedings/${this.currentProceedingId}/suppliers/add-supplier`,
                                        hidden: true
                                    }
                                ]
                            },
                            {
                                id: 'credit-against-estate-payments',
                                title: 'credit-against-estate-payments',
                                type: 'item',
                                url: `/manager/proceedings/${this.currentProceedingId}/credit-against-estate-payments`,
                                hidden: true                                
                            }
                        ]
                },

                {
                    id: 'incomes',
                    title: 'Ingresos',
                    type: 'item',
                    url: `/manager/proceedings/${this.currentProceedingId}/incomes`,
                    classes: this.lastUrlFragment === 'incomes'  || this.lastUrlFragment === 'charges' || this.lastUrlFragment === 'customers' ? 'accent' : undefined,
                    children:
                        [
                            {
                                id: 'customers',
                                title: 'customers',
                                type: 'item',
                                url: `/manager/proceedings/${this.currentProceedingId}/customers`,
                                hidden: true,
                                children: [
                                    {
                                        id: 'editCustomer',
                                        title: 'editCustomer',
                                        type: 'item',
                                        url: `/manager/proceedings/${this.currentProceedingId}/customers/edit-customer`,
                                        hidden: true
                                    },
                                    {
                                        id: 'addCustomer',
                                        title: 'addCustomer',
                                        type: 'item',
                                        url: `/manager/proceedings/${this.currentProceedingId}/customers/add-customer`,
                                        hidden: true
                                    }
                                ]
                            },
                            
                            {
                                id: 'cobros',
                                title: 'cobros',
                                type: 'item',
                                url: `/manager/proceedings/${this.currentProceedingId}/charges`,
                                hidden: true
                            }
                        ]
                }
            ]
        };
    }

    private getAccountingEntry(): FuseNavigation {
        
        const isCreditorAccounting = this.isUrlWithSecondToLastFragment('accounting');
        return {
            id: 'accounting',
            title: 'Contabilidad',
            type: 'collapsable',
            icon: 'coins',
            children: [
                {
                    id: 'accounting-daily-book',
                    title: 'Importación Diario',
                    type: 'item',
                    url: `/manager/proceedings/${this.currentProceedingId}/accounting`,
                    classes: this.lastUrlFragment === 'accounting' && !isCreditorAccounting ? 'accent' : undefined,
                },
                {
                    id: 'accounting-creditors',
                    title: 'Relación de Acreedores',
                    type: 'item',
                    url: `/manager/proceedings/${this.currentProceedingId}/accounting-creditors`,
                    classes: this.lastUrlFragment === 'accounting-creditors' ? 'accent' : undefined
                }
            ]
        };
    }

    private async getCalendarTasksEntry(): Promise<FuseNavigation> {
        
        const userInfo: ILoggedUserInfo = await this.authenticatedUserProvider.getLoggedUserInfo();

        if (!userInfo || !userInfo.giocondaModules.includes(GiocondaModules.CalendarTaskModule)) {
            return null;
        }

        return {
            id: 'calendar-tasks',
            title: 'Tareas',
            type: 'item',
            matIcono: 'schedule',
            badge: {
                title: 'NUEVO',
                translate: 'NUEVO',
                bg: '#b80013',
                fg: '#ffffff',
            },
            url: `/manager/proceedings/${this.currentProceedingId}/calendar-tasks`,
            classes: this.lastUrlFragment === 'calendar-tasks' ? 'accent' : undefined            
        };
    }

    private getOfficeSettings(): FuseNavigation {

        const urlOfficesNew = `/manager/offices/add-office`;
        const urlOfficesSettings = `/manager/offices/${this.currentOfficeId}/settings`;
        let urlOffices: string;

        if (this.currentOfficeId) {
            urlOffices = urlOfficesSettings;
        } else  {
            urlOffices = urlOfficesNew;
        } 

        return {
            id: 'offices-settings',
            title: 'Configuración',
            type: 'item',
            matIcono: 'settings',
            url: urlOffices,
            classes: this.lastProcessedUrl === urlOffices ? 'accent' : undefined,
        };
    }

    private getOfficeMailTemplates(): FuseNavigation {
        const isSectionOffices: boolean = this.lastProcessedUrl.includes('manager/offices/');
        const urlOfficesNew = `/manager/offices/add-office`;
        const sectionMailsList = this.currentOfficeId ? `/manager/offices/${this.currentOfficeId}/emails-templates` : urlOfficesNew;
        const sectionMailsAddForm = this.currentOfficeId ? `/manager/offices/${this.currentOfficeId}/emails-templates/add-custom-email-template` : urlOfficesNew;
        const isSectionEditionCustomEmailTemplate: boolean = this.lastProcessedUrl.includes('edit-custom-email-template');


        let classItem: string;
       
        if (isSectionOffices && !this.currentOfficeId) {
            classItem = 'disabled';
        } else  {
            classItem = 
                (this.lastProcessedUrl === sectionMailsList 
                 || this.lastProcessedUrl === sectionMailsAddForm 
                 || isSectionEditionCustomEmailTemplate) 
                 ? 'accent' : undefined;
        }

        return {
            id: 'emails-templates',
            title: 'Plantillas de Comunicaciones',
            type: 'item',
            matIcono: 'folder',
            url: sectionMailsList,
            classes: classItem,
            children: [
                {
                    id: 'emails-templates-form',
                    title: 'Nueva Plantilla de Comunicaciones',
                    type: 'item',
                    url: sectionMailsList,
                    hidden: true
                }
            ]
        };
    }

    private getOfficeDocumentsTemplates(): FuseNavigation {
        const urlOfficesNew = `/manager/offices/add-office`;
        const urlTemplatesList = this.currentOfficeId ? `/manager/offices/${this.currentOfficeId}/documents-templates` : urlOfficesNew;
        const urlAddCustomTemplate = `/manager/offices/${this.currentOfficeId}/documents-templates/add-custom-document-templates`;
        const isEditionCustomTemplate: boolean = this.lastProcessedUrl.includes('edit-custom-document-templates');

        let classItem: string;

        if (this.currentOfficeId) {
            classItem = (this.lastProcessedUrl === urlTemplatesList || this.lastProcessedUrl === urlAddCustomTemplate || isEditionCustomTemplate) ? 'accent' : undefined;
        } else  {
            classItem = 'disabled';
        }

        return {
            id: 'documents-templates',
            title: 'Plantillas de Escritos',
            type: 'item',
            matIcono: 'folder',
            url: urlTemplatesList,
            classes: classItem,
            children: [
                {
                    id: 'documents-templates-form',
                    title: 'Nueva Plantilla de Escritos',
                    type: 'item',
                    url: urlAddCustomTemplate,
                    hidden: true
                }
            ]
        };
    }

    private isUrlWithSecondToLastFragment(secondFragment: string): boolean {
        let isSecondToLastFragment = false;
        let matchingGroups: RegExpMatchArray = null;
        let lastUrlFragment: string;
        let secondToLastFragment: string;
        const processedUrlFragments = this.lastProcessedUrl.split('/');

        if (processedUrlFragments.length > 1) {
            lastUrlFragment = processedUrlFragments[processedUrlFragments.length - 1];
            secondToLastFragment = processedUrlFragments[processedUrlFragments.length - 2];
            matchingGroups = lastUrlFragment.match(this.GUID_V4_REGEX);
        }

        if (matchingGroups && matchingGroups !== null) {
            isSecondToLastFragment = secondToLastFragment === secondFragment && lastUrlFragment.match(this.GUID_V4_REGEX).length > 0;
        }

        return isSecondToLastFragment;
    }
}

export enum NavigationSection {
    INITIAL,
    USERLAND,
    OFFICESLAND,
    UNDEFINED
}
