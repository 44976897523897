import { ChangeDetectorRef, Component,  EventEmitter,  OnDestroy, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatRadioButton } from '@angular/material/radio';
import { RegexService } from 'app/services/regex-service/regex.service';
import { Subscription } from 'rxjs';
import { IProccedingBankAccount } from 'api/proceedings-service/proceeding.model';
import { AbstractFormArrayComponentDirective } from '../abstract-form/abstract-form-array.component';
import { RegexCharatersValidatorService } from 'app/services/regex-characters-validator-service/regex-characters-validator.service';

@Component({
    selector: 'gg-form-bank-account',
    templateUrl: './gg-form-bank-account.component.html',
    styleUrls: ['./gg-form-bank-account.component.scss']
})

export class FormBankAccoutComponent extends AbstractFormArrayComponentDirective implements OnDestroy {
    @ViewChildren('radiobuttons') public set radiobuttons(value: any) {
        if (value && !this.privateRadiobuttons && value.length > 0) {                             
            this.privateRadiobuttons = value;
            this.queryListSubscriber = value.changes.subscribe(() => {
                this.changeDetector.detectChanges();
                setTimeout(() => { 
                    this.trackRadioButtonChanges();
                }, 10);               
            });            
        }        
    }

    @Output() deleteBankAccountEvent = new EventEmitter<string>();
    
    private queryListSubscriber: Subscription;
    private privateRadiobuttons: QueryList<MatRadioButton>;
    private EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
    private initialFocusValue: string;

    constructor(
        private regex: RegexService,
        private readonly changeDetector: ChangeDetectorRef,
        private regexCharatersValidator: RegexCharatersValidatorService
    ) {
        super();
    }

    public ngOnDestroy(): void {
        if (this.queryListSubscriber) {
            this.queryListSubscriber.unsubscribe();
        }
    }

    public add(): void {
        this.childrenControls.push(new UntypedFormGroup({
            id: new UntypedFormControl(this.EMPTY_GUID, [Validators.maxLength(100)]),
            bankName: new UntypedFormControl('', [Validators.maxLength(100), this.regexCharatersValidator.charactersValidator()]),
            iban: new UntypedFormControl('', [Validators.required, Validators.maxLength(33), Validators.pattern(this.regex.IBAN_REGEX())]),
            isDefaultAccount: new UntypedFormControl(this.childrenControls.controls.length === 0)
        }));
        this.changeDetector.detectChanges();
    }

    public remove(index: number): void {
        const bankAccountForm = this.childrenControls.at(index) as UntypedFormGroup;
        const ibanValue = bankAccountForm.controls['iban'].value;
        this.deleteBankAccountEvent.emit(ibanValue);
        this.childrenControls.removeAt(index);
        this.changeDetector.detectChanges();
        this.removeDuplicatedError(ibanValue);
    }

    public setBankAccounts(obj: IProccedingBankAccount[]): UntypedFormArray {
        const items = new UntypedFormArray([]);  
        for (const item of obj){                                            
            items.push(new UntypedFormGroup({
                id: new UntypedFormControl(item.id, [Validators.maxLength(100)]),
                bankName: new UntypedFormControl(item.bankName, [Validators.maxLength(100), this.regexCharatersValidator.charactersValidator()]),
                iban: new UntypedFormControl(item.iban, [Validators.required, Validators.maxLength(33), Validators.pattern(this.regex.IBAN_REGEX())]),
                isDefaultAccount: new UntypedFormControl(item.isDefaultAccount)
            }));
        }
        return items;
    }

    public onFocus(value: string): void {
        this.initialFocusValue = value;
    }

    public onFocusout(inputValue: string): void {
        this.addDuplicatedErrors(inputValue);
        this.removeDuplicatedError(this.initialFocusValue);
    }

    public changeStatusRadioGroup(event: any, i: number): void {
        this.privateRadiobuttons.forEach((radioButton: MatRadioButton, index: number) => {
            this.toggleRadioButton(radioButton, index, index === i);
        });
    }

    private toggleRadioButton(radioButton: MatRadioButton, index: number, value: boolean): void {
        radioButton.checked = value;
        const currentControl = this.childrenControls.controls[index];
        currentControl.patchValue({ isDefaultAccount: value });
        this.changeDetector.markForCheck();
    }

    private trackRadioButtonChanges(): void {
        if (this.privateRadiobuttons.length === 1) {
            this.toggleRadioButton(this.privateRadiobuttons.first, 0, true);
        } else if (this.privateRadiobuttons.length > 1) {
            const isAnyButtonToggled = this.privateRadiobuttons.some((val: MatRadioButton) => val.checked);
            if (!isAnyButtonToggled) {
                this.toggleRadioButton(this.privateRadiobuttons.first, 0, true);
            }
        }
    }

    private addDuplicatedErrors(inputValue: string): void {
        const controlsWithSameValue = this.childrenControls.controls
            .filter((control: AbstractControl) => control.get('iban').value.replace(/\s/g, '') === inputValue.replace(/\s/g, ''));
        if (controlsWithSameValue.length > 1) {
            for (let i = 1; i < controlsWithSameValue.length; i++) {
                controlsWithSameValue[i].get('iban').setErrors({ duplicated: true });
            }
        }
    }

    private removeDuplicatedError(value: string): void {
        const controlsWithSameInitialValue = this.childrenControls.controls
            .filter((control: AbstractControl) => control.get('iban').value.replace(/\s/g, '') === value.replace(/\s/g, ''));

        if (controlsWithSameInitialValue.length <= 1) {
            for (const element of controlsWithSameInitialValue) {
                element.get('iban').updateValueAndValidity();
            }
        }
    }
}
