import { Component, Inject, Input, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-import-result',
    templateUrl: './import-result.component.html',
    styleUrls: ['./import-result.component.scss'] ,
    encapsulation: ViewEncapsulation.None
})

export class ImportResultComponent {

    @Input() public importResults: any;
    @Input() public totalProcessed: number;
    @Input() public showUpdatedResultsSection: false;
    @Input() public showDuplicatedResultsSection: false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.showUpdatedResultsSection = data.showUpdatedResultsSection;
        this.showDuplicatedResultsSection = data.showDuplicatedResultsSection;
        this.importResults = data.importResults;
        this.totalProcessed = this.importResults.ok.length 
                            + this.importResults.error.length 
                            + this.importResults.updated.length 
                            + this.importResults.duplicated.length;
    }
}
