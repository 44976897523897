import { Component, Input } from '@angular/core';

@Component({
    selector: 'gg-template-download',
    templateUrl: './gg-template-download.component.html',
    styleUrls    : ['./gg-template-download.component.scss'],
})

export class TemplateDownloadComponent {

    @Input() isTemplateSettings: boolean;
    @Input() idCard: string;
    @Input() selectedFileName: string;
    
}
