import {Component, Input, Inject, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {ScrollStrategy, ScrollStrategyOptions} from '@angular/cdk/overlay';
import {DOCUMENT} from '@angular/common';

export enum IconHelpType {
    INFO = 'info',
    ALERT = 'warning'
}

@Component({
    selector: 'gg-help',
    templateUrl: './gg-help.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ContextualHelpComponent {

    @Input() state: string;

    public isOpen = false;
    public scrollStrategy: ScrollStrategy;

    constructor(
        public readonly sso: ScrollStrategyOptions,
        public viewContainerRef: ViewContainerRef, 
        @Inject(DOCUMENT) public _document: any) 
    {
        this.scrollStrategy = this.sso.close();
    }


    public openHelp(): void {
      this.isOpen = true;
      window.addEventListener('scroll', this.scrollEvent, true);
    }

    public closeHelp(): void {
        this.isOpen = false;
        window.addEventListener('scroll', this.scrollEvent, true);
    }

    public scrollEvent = (): void => {
        this.isOpen = false;
    }

    public get iconType(): string {
        switch (this.state) {           
            case IconHelpType.ALERT:
                return 'warning_amber';
            case IconHelpType.INFO:                    
            default:
                return 'info';
        }
    }
}
