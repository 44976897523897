import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { IProceeding } from 'api/proceedings-service/proceeding.model';
import { ProceedingsService } from 'api/proceedings-service/proceedings.service';
import { GenericDialogComponent } from 'app/components/generic-dialog/generic-dialog.component';
import { CardDataComponent } from 'app/components/gg-card-data/gg-card-data.component';
import { NavigationService } from 'app/services/navigation-service/navigation-service';

@Component({
    selector: 'gg-panel-title-bar',
    templateUrl: './gg-panel-title-bar.component.html',
    styleUrls: ['./gg-panel-title-bar.component.scss']
})

export class PanelTitleBarComponent  {
    
    @Input() sectionBar: string;
    @Input() titleBar: string;
    @Input() proceedingName: string;
    @Input() assetName: string;
    @Input() assetId: string;
    @Input() officeName: string;
    @Input() proceedingId: string;
    @Input() showBackButton: false;
    @Input() showOffice: false;
    @Input() showCreditor: false;
    @Input() showAsset: false;
    @Input() creditorName: string;
    @Input() creditorId: string;
    @Input() isCreditorExcluded: boolean;
    @Input() showSupplier: false;
    @Input() showTenderer: false;
    @Input() showLiquidation: false;
    @Input() supplierName: string;
    @Input() supplierId: string;
    @Input() tendererName: string;
    @Input() tendererId: string;
    @Input() liquidationName: string;
    @Input() liquidationId: string;
    @Input() showCustomer: false;
    @Input() customerName: string;
    @Input() customerId: string;
    @Input() goBackLink: string;
    @Input() goEditLink: string;
    @Input() helpInfoTitle: string;
    @Input() helpInfoText: string;
    @Input() isProceedingConcluded = false;    

    @Output() goBackRequested: EventEmitter<any>;
    @Output() editRequested: EventEmitter<any>;

    public constructor(
        private readonly proceedingService: ProceedingsService,
        private navigation: NavigationService,
        public dialog: MatDialog
        ) {
        this.goBackRequested = new EventEmitter();
        this.editRequested = new EventEmitter();
   }

    public goBack(): void {
        this.goBackRequested.emit();
        this.navigation.back();
    }

    public edit(): void {
        this.editRequested.emit();
    }

    public openProcedingsCard(): void {
        this.proceedingService.getProceeding(this.proceedingId).subscribe((proceeding: IProceeding) => {
            this.dialog.open(GenericDialogComponent, {
                minWidth: '40vw',
                minHeight: '60vh',
                maxWidth: '50vw',
                maxHeight: '80vh',
                data: {
                    template: CardDataComponent,
                    proceeding: proceeding,
                    dialogTitle: 'Datos del Concurso',
                    displayCloseOption: true
                }            
            });
        });        
    }   
}

