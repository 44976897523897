export function parseAmountNumber(amount: any): number {
    if (typeof amount === 'number') {
        return amount;
    }

    if (amount === null) {
        return 0.00;
    }

    let sAmount = amount.toString();
    if (sAmount === '') {
         return 0.00;
    }

    sAmount = sAmount.replace(/\./g, '').replace(',', '.');

    return parseFloat(sAmount);
}

export function removeThousandsSeparators(amount: any): number {
    return +amount.toLocaleString().replace(/\./g, '');
}
