import { detect } from 'detect-browser';

export interface BrowserInfo {
    name?: string;
    version?: string;
    os?: string;
}

export function detectBrowserInfo(): BrowserInfo {
    const browserInfo = detect();
    if (!browserInfo) {
        return null;
    }

    const { name, version, os } = browserInfo;
    return { name, version, os };
}

export interface ScreenResolution {
    width: number;
    height: number;
}

export function getScreenResolution(): ScreenResolution {
    const { width, height } = screen;
    return { width, height };
}
