import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITokenGroup } from 'api/template-token-service/template-token.model';

@Component({
    selector: 'gg-tokens-list',
    templateUrl: './tokens-list.component.html'
})
export class TokensListComponent {

    @Input() public tokenGroups: ITokenGroup[];
    @Output() public clickedToken = new EventEmitter<string>();

    public constructor() {

    }
}
