import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IOffice } from 'api/office-service/office.model';
import { AuthenticatedUserCacheService } from 'app/services/authenticated-user-cache/authenticated-user-cache.service';
import { from, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OfficesGuard implements CanActivate {
    
    private readonly ADD_OFFICE_RELATIVE_URL = '/manager/offices/add-office';
    private readonly PROCEEDINGS_LIST_URL = '/manager/proceedings';

    constructor(
        private readonly authenticatedUserProvider: AuthenticatedUserCacheService,
        private readonly router: Router) { }

    public canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return from(
            this.authenticatedUserProvider.getCurrentOffices().then(
                (offices: IOffice[]) => {
                    const officesId: Array<string> = offices.map(x => x.id);
                    return this.validateOffices(officesId, state);
                },
                () => {
                    return false;
                }
            )
        );
    }

    private validateOffices(officesId: Array<string>, state: RouterStateSnapshot): boolean {
        const hasUserOffices = officesId.length > 0;
        if (hasUserOffices) {
            if (state.url === this.ADD_OFFICE_RELATIVE_URL) {
                this.router.navigate([this.PROCEEDINGS_LIST_URL]);
                return false;
            }
            return true;
        }
        
        // Usuario no tiene asignado despacho. 
        if (state.url === this.ADD_OFFICE_RELATIVE_URL) {
            return true;
        }

        this.router.navigate([this.ADD_OFFICE_RELATIVE_URL]);
        return false;
    }
}
