import { Component, Input } from '@angular/core';
import { AbstractFormArrayComponentDirective } from '../abstract-form/abstract-form-array.component';
import { UntypedFormArray, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { CommonValidators } from '../abstract-form/common-form-validators';

@Component({
    selector: 'gg-form-email',
    templateUrl: './gg-form-email.component.html',
    styleUrls: ['./gg-form-email.component.scss']
})
export class FormEmailComponent extends AbstractFormArrayComponentDirective {

    @Input() public emailLabel: string;

    public constructor() {
        super();
    }

    public add(): void {
        (this.parentGroup.controls[this.controlName] as UntypedFormArray).push(new UntypedFormGroup({
            email: new UntypedFormControl('', [Validators.maxLength(100), CommonValidators.email]),
        }));
    }

    public remove(index: number): void {
        this.childrenControls.removeAt(index);
    }

    public setEmails(emails: string[]): UntypedFormArray {
        const items = new UntypedFormArray([]);
        for (const email of emails) {
            items.push(new UntypedFormGroup({
                email: new UntypedFormControl(email, [Validators.maxLength(100), CommonValidators.email]),
            }));
        }
        return items;
    }

    public setErrorsEmails(existingEmails: Array<string>): void {
        this.childrenControls.controls.forEach((formGroup: UntypedFormGroup) => {
            const index: number = existingEmails.findIndex(email => email === formGroup.controls['email'].value);
            if (index !== -1) {
                formGroup.controls['email'].setErrors({ existing: true });
            }           
        });        
    }
}
