import { Injectable  } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Configuration } from 'api/configuration-service/configuration.model';
import { filter } from 'rxjs/operators';

declare var gtag: any;

@Injectable()
export class GoogleAnalyticsService {
  private readonly googleAnalyticId: string;
  private readonly googleAnalyticIdLanding: string;

    constructor(
        private router: Router,
        private readonly environment: Configuration) {
        this.googleAnalyticId = this.environment.googleAnalytics;
        this.googleAnalyticIdLanding = this.environment.googleAnalyticsLanding;

        if (this.googleAnalyticIdLanding !== '') { 
            const navEndEvents = router.events.pipe (
                filter(event => event instanceof NavigationEnd),
            );
            
            navEndEvents.subscribe((event: NavigationEnd) => {
                gtag('config', this.googleAnalyticIdLanding, {
                    'page_path': event.urlAfterRedirects
                });
            });
        }

        if (this.googleAnalyticId !== '') { 
            gtag('js', new Date());
            gtag('config', this.googleAnalyticId );
        }

    }

    public pageLoaded(page: string): void{    
        if (this.googleAnalyticId === '') { return; }  
        const regex = new RegExp(/([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})/gi);
        const urlWithoutGuids = page.replace(regex, '').replace('//' , '/');     
        gtag('event', this.googleAnalyticId, {page_path: urlWithoutGuids});
    }
}
