import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RegexService } from 'app/services/regex-service/regex.service';
import { INameEmailPhone } from 'api/proceedings-service/proceeding.model';
import { AbstractFormArrayComponentDirective } from '../abstract-form/abstract-form-array.component';
import { CommonValidators } from '../abstract-form/common-form-validators';
import { RegexCharatersValidatorService } from 'app/services/regex-characters-validator-service/regex-characters-validator.service';

@Component({
    selector: 'gg-form-name-email-phone',
    templateUrl: './gg-form-name-email-phone.component.html',
    styleUrls: ['./gg-form-name-email-phone.component.scss']
})

export class FormNameEmailPhoneComponent extends AbstractFormArrayComponentDirective {            
    private EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
    
    constructor(private readonly regex: RegexService, private regexCharatersValidator: RegexCharatersValidatorService) {        
        super();
    }

    public add(): void {        
        (this.parentGroup.controls[this.controlName] as UntypedFormArray).push(new UntypedFormGroup({
            id: new UntypedFormControl(this.EMPTY_GUID, [Validators.maxLength(100)]),
            name: new UntypedFormControl('', [Validators.required, Validators.maxLength(100), this.regexCharatersValidator.charactersValidator()]),
            email: new UntypedFormControl('', [Validators.maxLength(100), CommonValidators.email]),
            phone: new UntypedFormControl('', [Validators.maxLength(100), Validators.pattern(this.regex.PHONE_REGEX())])            
        }));              
    }

    public remove(index: number): void {
        this.childrenControls.removeAt(index);
    }

    public setNameEmailPhoneValues(obj: INameEmailPhone[]): UntypedFormArray {        
        const items = new UntypedFormArray([]);  
        for (const item of obj){                                            
            items.push(new UntypedFormGroup({
                name: new UntypedFormControl(item.name, [Validators.required, Validators.maxLength(100), this.regexCharatersValidator.charactersValidator()]),
                email: new UntypedFormControl(item.email, [Validators.maxLength(100), CommonValidators.email]),
                phone: new UntypedFormControl(item.phone, [Validators.maxLength(100), Validators.pattern(this.regex.PHONE_REGEX())]),
                id: new UntypedFormControl(item.id, [Validators.maxLength(100)])
            }));
        }
        return items;        
    }
}
