import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Configuration } from 'api/configuration-service/configuration.model';
import { ITariff } from 'api/proceedings-service/proceeding-tariff.model';
import { ProceedingsService } from 'api/proceedings-service/proceedings.service';
import { RegexService } from 'app/services/regex-service/regex.service';
import { SamlClientService } from 'app/services/saml-client-service/saml-client.service';

@Component({
    selector: 'gg-tariff-calculator',
    templateUrl: './gg-tariff-calculator.component.html',
    styleUrls: ['./gg-tariff-calculator.component.scss']
})

export class TariffCalculatorComponent implements OnInit{
    public requestedTariff: ITariff = this.newTariff();
    public provisionalTariff: ITariff = this.newTariff();
    public definitiveTariff: ITariff = this.newTariff();
    public giocondaTariff: ITariff = this.newTariff();

    public url: string;
    public message = 'RD 1860/2004, de 6 de septiembre, por el que se establece el arancel de derechos de los administradores concursales';

    @Input() public parentGroup: UntypedFormGroup;
    @Input() public controlName: string;

    private EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

    constructor(
        private regex: RegexService,
        public proceedingService: ProceedingsService,
        private readonly environment: Configuration,
        private readonly samlClientService: SamlClientService
    ) { }
    
    public ngOnInit(): void {
        this.initializeFormGroup();
        this.url = this.environment.contentSiteUrl + this.environment.tarificCalculatorSiteUrl;
        const samlClientIdQueryParam = this.samlClientService.getSamlClientId();
        if (samlClientIdQueryParam) {
            this.url = this.samlClientService.appendSamlClientIdAsQueryParameter(this.url);  
        }
    }

    public calculateSimpleTariff(value: string, isActive: boolean, originalTariff: ITariff): void {
        const numericValue = parseFloat((value || '0,00').replace(/\./g, '').replace(',', '.'));

        if (isActive) {
            originalTariff.active = numericValue;
        }
        else {
            originalTariff.passive = numericValue;
        }
        
        this.proceedingService.calculateTariff(originalTariff).subscribe(
            (result: ITariff) => {
                if (isActive) {
                    originalTariff.activeCalculatedValue = result.activeCalculatedValue;
                }
                else {
                    originalTariff.passiveCalculatedValue = result.passiveCalculatedValue;
                }
            }
        );
    }

    public setRequestedTariff(requestedTariff: ITariff): void {
        this.requestedTariff = requestedTariff || this.newTariff();
        this.setTariffInternal(this.requestedTariff, this.parentGroup.get('requestedTariff'));
    }

    public setProvisionalTariff(provisionalTariff: ITariff): void {
        this.provisionalTariff = provisionalTariff || this.newTariff();
        this.setTariffInternal(this.provisionalTariff, this.parentGroup.get('provisionalTariff'));
    }

    public setDefinitiveTariff(definitiveTariff: ITariff): void {
        this.definitiveTariff = definitiveTariff || this.newTariff();
        this.setTariffInternal(this.definitiveTariff, this.parentGroup.get('definitiveTariff'));
    }

    public setGiocondaTariff(giocondaTariff: ITariff): void {
        this.giocondaTariff = giocondaTariff || this.newTariff();
        this.setTariffInternal(this.giocondaTariff, this.parentGroup.get('giocondaTariff'));
    }

    private setTariffInternal(tariff: ITariff, control: AbstractControl): void {
        control.setValue({
            id: (tariff !== null) ? tariff.id : this.EMPTY_GUID,
            active: (tariff !== null && tariff.active !== 0) ? tariff.active : '0,00',
            passive: (tariff !== null && tariff.active !== 0) ? tariff.passive : '0,00'
        });
    }

    private newTariff(): ITariff {
        return {
            id: this.EMPTY_GUID,
            active: 0.0,
            passive: 0.0,
            activeCalculatedValue: 0.0,
            passiveCalculatedValue: 0.0,
        };
    }

    private initializeFormGroup(): void {
        const requestedTariffFormGroup = (this.parentGroup.get('requestedTariff') as UntypedFormGroup);
        this.initializeTariff(requestedTariffFormGroup);

        const provisionalTariffFormGroup = (this.parentGroup.get('provisionalTariff') as UntypedFormGroup);
        this.initializeTariff(provisionalTariffFormGroup);

        const definitiveTariffFormGroup = (this.parentGroup.get('definitiveTariff') as UntypedFormGroup);
        this.initializeTariff(definitiveTariffFormGroup);

        const giocondaTariffFormGroup = (this.parentGroup.get('giocondaTariff') as UntypedFormGroup);
        this.initializeTariff(giocondaTariffFormGroup);
    }

    private initializeTariff(tariffFormGroup: UntypedFormGroup): void {
        tariffFormGroup
            .addControl('id', new UntypedFormControl(this.EMPTY_GUID, [Validators.maxLength(100)]));
        tariffFormGroup
            .addControl('active', new UntypedFormControl('', [Validators.maxLength(100), Validators.pattern(this.regex.EURO_REGEX())]));
        tariffFormGroup
            .addControl('passive', new UntypedFormControl('', [Validators.maxLength(100), Validators.pattern(this.regex.EURO_REGEX())]));
    }

}
