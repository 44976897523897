import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { ICommunicationEmailSubmission } from 'api/communication-service/proceeding-creditor-communication-service/communication-email-submission.model';
import { IPreviewRequest } from 'api/communication-service/proceeding-creditor-communication-service/proceeding-creditor-communication.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { IEmailTemplate } from 'api/generic-interfaces/email-template.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApplicationEmailTemplateService {
    private endpointUrl: string;
    
    constructor(private readonly apiProcessor: ApiProcessorService, private readonly environment: Configuration) {        
        this.endpointUrl = this.environment.apiUrl + 'office-management/application-email-templates';
    }
   
    public getApplicationEmailTemplate(id: string, withContent: boolean = false): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;        

        if (withContent) {
            apiRequest.endpointUrl = `${this.endpointUrl}/${id}?fields=id,templateName,subject,editableTemplateFileContent`;
        } else {
            apiRequest.endpointUrl = `${this.endpointUrl}/${id}?fields=id,templateName,subject`;
        }

        return this.apiProcessor.proccessRequest(apiRequest);
    }
  
    public updateApplicationEmailTemplate(applicationEmailTemplate: IEmailTemplate): Observable<any> { 
        const url = `${this.endpointUrl}/${applicationEmailTemplate.id}`;
        const reqBody = this.apiProcessor.setBody(applicationEmailTemplate);

        return this.apiProcessor.putObject(url, reqBody);
    }

    public calculatePreviewWithCommunication(communication: ICommunicationEmailSubmission, proceedingId: string, officeId: string): Observable<any> {
        const preview: IPreviewRequest = { 
            subject: communication.subject,
            body: communication.body,
            signatureTypeCode: communication.signatureTypeCode,
            proceedingId: proceedingId,
            emailTemplateId: communication.emailTemplateId,
            proceedingCreditorId: communication.proceedingCreditorIds[0],
            officeId: officeId
        };

        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${ this.endpointUrl }/calculate-preview`;
        apiRequest.body = preview;

        return this.apiProcessor.proccessRequest(apiRequest);
    }
    
    public calculatePreview(applicationEmailTemplateId: string, officeId: string): Observable<any> {
        const previewRequest: IPreviewRequest = {            
            emailTemplateId: applicationEmailTemplateId,
            officeId: officeId
        };

        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${ this.endpointUrl }/calculate-preview`;
        apiRequest.body = previewRequest;

        return this.apiProcessor.proccessRequest(apiRequest);
    }
}
