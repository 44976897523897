import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { RegexService } from 'app/services/regex-service/regex.service';

@Injectable({
    providedIn: 'root'
})

export class RegexCharatersValidatorService {
    public constructor(
        private regex: RegexService
    ){}

    public charactersValidator(regexExpression: RegExp = null): ValidatorFn {
        return (control: AbstractControl): { [key:string]: any } | null => {
            const currentRegexExpression = regexExpression !== null ? regexExpression : this.regex.WORDS_REGEX(); 
            const charactersNotValid = control.value !== undefined ? this.validateRegexExec(control.value, currentRegexExpression) : null;
            
            if (charactersNotValid !== null && charactersNotValid !== '') {
                return { 'charactersValidator': charactersNotValid };
            }

            return null;
        }
    }

    private validateRegexExec(word: string, regularExpression: RegExp): string {
        let notMatchRegEx: string[] = [];
        let execResult: RegExpExecArray;
        regularExpression.lastIndex = 0;

        do {
            execResult = regularExpression.exec(word);

            if (execResult){
                notMatchRegEx.push(execResult[0]);
            }

        } while (execResult);

        if (notMatchRegEx !== null) {
            return notMatchRegEx.join();
        }

        return null;
    }
}
