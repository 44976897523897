import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Configuration } from 'api/configuration-service/configuration.model';
import { FooterLinks } from 'api/configuration-service/footer-links.model';
import { Version } from 'api/configuration-service/version.model';
import * as moment from 'moment';
import { AppModule } from './app/app.module';

export async function parseJsonResponse<T>(response: Response): Promise<T> {
    // Se lee primero como texto para localhost
    const textResponse = await response.text();
    return JSON.parse(textResponse) as T;
}

export async function fetchConfiguration<T>(filePath: string): Promise<T> {
    let configuration: T;
    await fetch(filePath)
        .then(async res => {
            configuration = await parseJsonResponse<T>(res);
        })
        .catch((err) => console.error(err));

    return configuration as T;
}

export async function generateConfiguration(): Promise<void>{
    const footerLinksConfig =  await fetchConfiguration<FooterLinks>('config/config-footer-links.json');
    const configuration = await fetchConfiguration<Configuration>('config.json');
    configuration.footerLinks = footerLinksConfig;

    const version = await fetchConfiguration<Version>('version.json'); 
    configuration.version = version;

    if (configuration.production) {
        enableProdMode();
    }

    platformBrowserDynamic([
        { provide: Configuration, useValue: configuration },
    ])
        .bootstrapModule(AppModule)
        .catch((err: any) => console.error(err));
}

generateConfiguration();

Date.prototype.toJSON = function(): string {
    return moment(this, moment.ISO_8601).format();
};

moment.prototype.toJSON = function(): string {
    return moment(this, moment.ISO_8601).format();
};
