import { CanActivate, UrlTree } from '@angular/router';
import { IUserType } from 'api/user-service/user.type.model';
import { AuthenticatedUserCacheService } from 'app/services/authenticated-user-cache/authenticated-user-cache.service';
import { from, Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export abstract class AllowBaseAccessGuard implements CanActivate {
    private readonly NOT_USER_TYPE_CODE = 1;

    public constructor(
        public authenticatedUserProvider: AuthenticatedUserCacheService
    ) { }

    public canActivate(): Observable<boolean | UrlTree> {
        return from(this.authenticatedUserProvider.getCurrentUserType()).pipe(
            concatMap((userType: IUserType) => {
                return userType ? of(this.processUserTypeCode(userType.code)) : of(this.processUserTypeCode(this.NOT_USER_TYPE_CODE));
            })
        );
    }

    protected abstract processUserTypeCode(userType: number): boolean | UrlTree;
}
