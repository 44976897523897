import { Component, Input, ViewChild } from '@angular/core';
import { GiocondaGenericDialogComponent } from 'app/components/gg-generic-dialog/gg-generic-dialog.component';
import { PublicLinksButtonsService } from 'app/services/public-links-buttons/public-links-buttons.service';

export enum NotificationState {
    Info = 'dinfo',
    InfoT = 'tinfo',
    InfoM = 'minfo',
    Doctrine = 'dtinfo',
    Success = 'success',
    Warning = 'warning',
    WarningColor = 'warning-c',
    Error = 'error',
    Notice = 'notice',
    Change = 'change'
}

@Component({
    selector: 'gioc-notification',
    templateUrl: './gg-notification.component.html',
    styleUrls    : ['./gg-notification.component.scss']
})
export class NotificationComponent {
  
    @Input() title: string;
    @Input() content: any;
    @Input() data: any;
    @Input() url: any;
    @Input() openUrlInNewWindows = false;
    @Input() routerLink: any;
    @Input() state = NotificationState.Info;
    @Input() additionalClass: string;

    @ViewChild('dialogUrl', { static: true }) public dialogUrl: GiocondaGenericDialogComponent;

    public constructor(
        private readonly publicLinksbuttons: PublicLinksButtonsService
    ){ }

    public get iconClass(): string {
        switch (this.state) {
            case NotificationState.Info:
                return 'info';
            case NotificationState.InfoT:
                return 'info';
            case NotificationState.Doctrine:
                return 'account_balance';
            case NotificationState.InfoM:
                return 'contact_mail';
            case NotificationState.Error:
                return 'error_outline';
            case NotificationState.Success:
                return 'check_circle';
            case NotificationState.WarningColor:
                return 'info';
            case NotificationState.Warning:
                return 'info';
            case NotificationState.Change:
                return 'report_problem';
            case NotificationState.Notice:
                return 'report_problem';
            default:
                return '';
        }
    }

    public openUrl(): void {
        if (this.openUrlInNewWindows) {
            this.publicLinksbuttons.goToLink(this.url);
            return;
        }

        this.dialogUrl.openDialog({
            panelClass: 'cdk-overlay-big-modal',
            backdropClass: 'cdk-overlay-backdrop-dark'
        });
    }
}
