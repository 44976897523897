import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';

export enum ButtonStyle {
    SUCCESS = 'success',
    GOST = 'success',
    TAB = 'tab'
}

@Component({
    selector: 'gg-button',
    templateUrl: './gg-button.component.html',
    styleUrls    : ['./gg-button.component.scss'],
    encapsulation  : ViewEncapsulation.None,
})

export class GiocButtonComponent implements OnInit {
    
    @Input() public mode: ButtonStyle;
    @Input() public numberButton: number;
    @Input() public titleButton: string;
    @Input() public routerLink: string;
    @Input() public iconButton: string;
    @Input() public svgIcon: string;
    @Input() public toolTip: string;
    @Input() public additionalClass: string;
    @Input() public wkIcon: string;
    @Input() public iconArrow: string;
    @Input() public iconRight: boolean;
    @Input() public isGhost = false;
    @Input() public isSubmit = false;
    @Input() public isTab = false;
    @Input() public isSelected = false;
    @Input() public isDisabled = false;
    @Input() public isDefault: boolean;
    @Input() public isOutlined = true;
    @Input() public textAndIcon = false;
    @Input() public buttonType = 'button';
    @Input() public statusButton: any;
    @Input() public isAlert: string;
    @Input() public unabled = false;
    @Input() public isNotificacion: boolean;
    
    public showButton: boolean;
    public hasButtonIcon: boolean;
    public hasSvgIcon: boolean;
    public isTypeIcon: boolean;
    public addClass: string;
    public addedClass: string;

    get routerLinkButton(): string {
        if ((this.routerLink === null) || (this.routerLink === undefined) || (this.routerLink === '')) {
            return;
        } else {
            return '/' + this.routerLink ;
        }
    }

    public ngOnInit(): void {
        this.initialize();
        this.mode = ButtonStyle.SUCCESS;
    }

    private initialize(): void {
        this.hasButtonIcon = !(this.iconButton === null || this.iconButton === undefined || this.iconButton === '');
        this.hasSvgIcon = !(this.svgIcon === null || this.svgIcon === undefined || this.svgIcon === '');
        this.isTypeIcon = !(this.wkIcon === null || this.wkIcon === undefined || this.wkIcon === '');
        this.showButton = !(this.titleButton === null || this.titleButton === undefined || this.titleButton === '');
        this.isDefault = (this.isDefault === null || this.isDefault === undefined);
        this.addedClass = this.additionalClass ? this.additionalClass : '';
    }

    get classButton(): string | void {
        if (!this.textAndIcon && this.hasButtonIcon) {
            this.addClass = 'mat-icon-button iconbutton';
        } else if (!this.textAndIcon && this.hasSvgIcon) {
            this.addClass =  'mat-icon-button svgicon';
        } else if (!this.textAndIcon && this.isTypeIcon) {
            this.addClass = 'mat-icon-button';
        } else if (this.isGhost) {
            this.addClass = 'mat-stroked-button';
        } else if (this.isTab) {
            this.addClass = 'mat-flat-button tab';
        } else if (this.isDefault) {
            this.addClass = 'mat-flat-button';
        } else {
            this.addClass = 'mat-button';
        }
        return this.addClass + ' ' + this.addedClass;
    }
}
