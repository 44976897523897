import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DocumentIssuingTypesService {
    private endpointUrl: string;
    
    constructor(private readonly apiProcessor: ApiProcessorService, private readonly environment: Configuration) {
        
        this.endpointUrl = this.environment.apiUrl + 'masterdata-management/document-issuing-types';
    }

    public getAllDocumentIssuingTypes(): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }`;
        return this.apiProcessor.proccessRequest(apiRequest);
    }
}
