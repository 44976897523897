import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'gg-panel',
    templateUrl: './gg-panel.component.html',
    styleUrls: ['./gg-panel.component.scss']
})

export class PanelComponent {

    @Input() idPanel: string;
    @Input() additionalClass: string;
    @Input() isTypeForm = false;
    @Input() isTypeGrey = false;
    @Input() hasTabs = false;

    
    @Output() expandPanel: EventEmitter<boolean>;

    constructor() {
        this.expandPanel = new EventEmitter<boolean>();
    }

}
