import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from 'app/services/login/login.service';
@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
    public constructor(
        private readonly router: Router,        
        private readonly loginService: LoginService) { }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {        
        const isUserLoggedIn = await this.loginService.isUserLoggedIn().toPromise();
        if (isUserLoggedIn) {
            return true;
        }
        else {
            this.loginService.returnUrl.next(state.url);
            return this.router.parseUrl('/public/unauthorized');
        }
    }
}
