import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { FuseSidebarModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { Configuration } from 'api/configuration-service/configuration.model';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { appRoutes } from './app.routes';
import { GiocondaGenericComponentsModule } from './components/gioconda-generic-components.module';
import { NavbarModule } from './components/navbar/navbar.module';
import { Error404Component } from './errors/404.component';
import { GiocondaServicesModule } from './gioconda-services.module';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { EditorModule } from '@progress/kendo-angular-editor';


registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [
        AppComponent,
        Error404Component
    ],
    imports: [
        // Common components modules
        NavbarModule,
        GiocondaGenericComponentsModule,

        // Common angular modules
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            enableTracing: false,
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'legacy'
        }),
        TranslateModule.forRoot(),

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseSidebarModule,

        // Common services module
        GiocondaServicesModule,       
        ScrollViewModule,
        EditorModule,
        ServiceWorkerModule.register('ngsw-worker.js')
    ],
    exports: [
        RouterModule,
        MatDialogModule,
        MatRadioModule
    ],
    providers: [{
        provide: SwRegistrationOptions,
        useFactory: () => ({enabled: isProduction}),
        deps: [Configuration]
    }        
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}

export function isProduction(env: Configuration): boolean {
    return env.production;
}
