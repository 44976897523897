import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CreditorsService } from 'api/creditors-service/creditors.service';
import { ICreditor } from 'api/creditors-service/creditor.model';

@Injectable({
    providedIn: 'root'
})
export class CreditorWithProceedingsGuard implements CanActivate {
    public constructor(
        private readonly creditorService: CreditorsService,
        private readonly router: Router) { }

    public canActivate(): Observable<boolean | UrlTree> {        
        return this.creditorService.getCreditorsByUserId().pipe(map((creditors: Array<ICreditor>) => {
            if (creditors && creditors.length > 0) {
                return true;
            }
            return this.router.parseUrl('/public/unauthorized?type=3');
        }), catchError(() => of(this.router.parseUrl('/public/unauthorized?type=3'))));
    }
}
