import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { ApiProcessorService, ApiRequest, ApiOperation } from '../../../api/api-processor-service/api-processor.service';
import { ICreditQualification } from 'api/credit-qualification/credit-qualification.model';
import { Configuration } from 'api/configuration-service/configuration.model';

export interface Dropdown {
    value: string | boolean;
    viewValue: string;
    extraData?: any;
    code?: number;
}
@Injectable()
export class DropdownsService {
    private envUrl: string;
   

    courtVisibles: Dropdown[] = [ {
            value: true,
            viewValue: 'Si, quiero que el juzgado pueda acceder a los datos de este concurso'
        }, {
            value: false,
            viewValue: 'No, este concurso y todos sus datos no son visibles para el juzgado'
        }
    ];

    private creditTypesDropdown: Dropdown[] = [];
    private qualificationsDropdown: Dropdown[] = [];
    private proceedingsDropdown: Dropdown[] = [];
    private requiredsDropdown: Dropdown[] = [];
    private phasesDropdown: Dropdown[] = [];
    private courtRegimeDropdown: Dropdown[] = [];
    private provinceDropdown: Dropdown[] = [];

    private _creditTypeObservableList: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]);
    private _qualificationObservableList: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]);
    private _proceedingsObsersableList: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]); 
    private _requiredsObsersableList: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]); 
    private _phasesObsersableList: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]); 
    private _courtRegimeObsersableList: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]); 
    private _provinceObsersableList: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]);

    public get creditTypeObservableList(): Observable<Dropdown[]> { 
        if (this.creditTypesDropdown.length === 0 ) { this.getCreditTypeDropdownList(); }
        return this._creditTypeObservableList.asObservable(); 
    }
    public get qualificationObservableList(): Observable<Dropdown[]> { 
        if (this.qualificationsDropdown.length === 0 ) { this.getQualificationDropdownList(); }
        return this._qualificationObservableList.asObservable(); 
    }  

    public get ProceedingPhaseTypesObservableList(): Observable<Dropdown[]> { 
        if (this.phasesDropdown.length === 0 ) { this.getPhaseTypeDropdownList(); }
        return this._phasesObsersableList.asObservable(); 
    }   

    public get ProceedingRequiredTypesObservableList(): Observable<Dropdown[]> { 
        if (this.requiredsDropdown.length === 0 ) { this.getRequiredTypeDropdownList(); }
        return this._requiredsObsersableList.asObservable(); 
    }   

    public get ProceedingCourtRegimeTypesObservableList(): Observable<Dropdown[]> { 
        if (this.courtRegimeDropdown.length === 0 ) { this.getCourtRegimeTypesDropdownList(); }
        return this._courtRegimeObsersableList.asObservable(); 
    } 
    
    public get ProvincesObservableList(): Observable<Dropdown[]> { 
        if (this.provinceDropdown.length === 0 ) { this.getProvincesDropdownList(); }
        return this._provinceObsersableList.asObservable(); 
    }
 

    constructor(private http: HttpClient, private readonly apiProcessorService: ApiProcessorService, private readonly environment: Configuration) {
        this.envUrl = this.environment.apiUrl;
    }

   

    public getProceedingAdministratorTypesDropdownList(): Dropdown[] {
        const adminTypes: Dropdown[] = [];
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.envUrl + 'masterdata-management/proceeding-administrator-types/';

        this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<any>('getProceedingAdministratorTypesDropdownList')))
            .subscribe(adminTypesList => {
                adminTypesList.map(adminType => adminTypes.push({ value: adminType.id, viewValue: adminType.name, code: adminType.code}));
            });

        return adminTypes;
    }

    public getProceedingTypesDropdownList(): Dropdown[] {
        const proceedingTypes: Dropdown[] = [];
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.envUrl + 'masterdata-management/proceeding-types/';
        
        this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<any>('getProceedingTypeDropdownList')))
            .subscribe(typesList => {
                typesList.map((type: { id: any; name: any; code: any; }) => proceedingTypes.push({ value: type.id, viewValue: type.name, code: type.code })); 
            });

        return proceedingTypes;
    }

    public async getDeadlinesDropdownList(): Promise<Dropdown[]> {
        const deadlines: Dropdown[] = [];
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.envUrl + 'masterdata-management/communication-deadlines/';

        await this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<any>('getDeadlinesDropdownList')))
            .toPromise()
            .then(deadlinesList => {
                deadlinesList.map((deadline: { id: any; name: any; }) => deadlines.push({ value: deadline.id, viewValue: deadline.name}));
            });

        return deadlines;
    }

    public getCourtsDropdownList(): Dropdown[] {
        const courts: Dropdown[] = [];  
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.envUrl + 'masterdata-management/courts/';

        this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<any>('getCourtsDropdownList')))
            .subscribe(courtsList => {
                courtsList.map((court: { id: any; name: any; }) => courts.push({ value: court.id, viewValue: court.name}));
            });

        return courts;
    }

    public getCourtVisiblesDropdownList(): Dropdown[] {
        return [
            {
                value: true,
                viewValue: 'Si, quiero que el juzgado pueda acceder a los datos de este concurso'
            },
            {
                value: false,
                viewValue: 'No, este concurso y todos sus datos no son visibles para el juzgado'
            }
        ];
    }

    public mapToDropdown(arrayToMap, viewValueName, valueName): Array<Dropdown>{
        return arrayToMap.map(val => {
           return {
               viewValue: val[viewValueName],
               value: val[valueName]
           };
       });           
   }

    private getCreditTypeDropdownList(): void{
        const that = this;
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.envUrl + 'masterdata-management/credit-types/';
        
        this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<any>('getCreditTypesDropdownList')))
            .subscribe
            ({
                next: (typesList) => {
                    typesList.map(
                        (type: { id: any; name: any; }) => that.creditTypesDropdown.push({ value: type.id, viewValue: type.name })
                    ); 
                },
                complete: () => { 
                    that._creditTypeObservableList.next(that.creditTypesDropdown);                  
                }
            });
    }

    private getPhaseTypeDropdownList(): void{        
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.envUrl + 'masterdata-management/phase-types/';
        
        this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<any>('getPhaseTypeDropdownList')))
            .subscribe
            ({
                next: (typesList) => {
                    typesList.map(
                        (type: { id: any; name: any; }) => this.phasesDropdown.push({ value: type.id, viewValue: type.name })
                    ); 
                },
                complete: () => { 
                    this._phasesObsersableList.next(this.phasesDropdown);                  
                }
            });
    }

    private getRequiredTypeDropdownList(): void{        
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.envUrl + 'masterdata-management/required-types/';
        
        this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<any>('getRequiredTypeDropdownList')))
            .subscribe
            ({
                next: (typesList) => {
                    typesList.map(
                        (type: { id: any; name: any; }) => this.requiredsDropdown.push({ value: type.id, viewValue: type.name })
                    ); 
                },
                complete: () => { 
                    this._requiredsObsersableList.next(this.requiredsDropdown);                  
                }
            });
    }

    private getQualificationDropdownList(): void{
        const that = this;
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.envUrl + 'masterdata-management/credit-qualifications/';
        
        this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<any>('getQualificationDropdownList')))
            .subscribe
            ({
                next: (qualificationList) => {
                        qualificationList.map(
                            (q: ICreditQualification) => {
                                return that.qualificationsDropdown.push(
                                    {
                                        value: q.id,
                                        viewValue: q.name,
                                        extraData: {
                                            allowsGuarantees: q.allowsGuarantees,
                                            trlcName: q.trlcName,
                                            name: q.name,
                                            code: q.code, 
                                            requiredQuantity: q.requiredQuantity
                                        }
                                    }
                                );
                            }
                    ); 
                },
                complete: () => {
                    that._qualificationObservableList.next(that.qualificationsDropdown);                  
                }
            });
    } 

    getCreditGuaranteesDropdownList(allowGuarantees: boolean = false): Array<Dropdown> {
        const creditGuaranteesDropdownList: Dropdown[] = [];
        
        this.http.get(this.envUrl + 'masterdata-management/guarantees/' + '?allowsGuarantees=' + allowGuarantees, { withCredentials: true })
            .pipe(catchError(this.handleError<any>('getCreditGuaranteesDropdownList')))
            .subscribe(guaranteeList => {
                guaranteeList.map((g: { id: any; name: any; }) => creditGuaranteesDropdownList.push({ value: g.id, viewValue: g.name})
                );
            });

        return creditGuaranteesDropdownList;
    }

    
    getAccountingCategoriesDropdownList(id): Array<Dropdown> {
        const accountingCategories: Dropdown[] = [];
        
        this.http.get(this.envUrl + 'masterdata-management/accounting-categories/' + id, { withCredentials: true })
            .pipe(catchError(this.handleError<any>('getAccountingCategoriesDropdownList')))
            .subscribe(accountingCategoriesList => {
                accountingCategoriesList.map((g: { id: any; name: any; }) => accountingCategories.push({ value: g.id, viewValue: g.name})
                );
            });

        return accountingCategories;
    }
    getAssetsTypesDropdownList(): Array<Dropdown> {
        const assetsTypesDropdownList: Dropdown[] = [];
        
        this.http.get(this.envUrl + 'masterdata-management/assets-types/', { withCredentials: true })
            .pipe(catchError(this.handleError<any>('getAssetsTypesDropdownList')))
            .subscribe(assetsTypesList => {
                assetsTypesList.map((g: { id: any; name: any; }) => assetsTypesDropdownList.push({ value: g.id, viewValue: g.name})
                );
            });

        return assetsTypesDropdownList;
    }

    private getCourtRegimeTypesDropdownList(): void{        
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.envUrl + 'masterdata-management/proceeding-court-regime-types/';
        
        this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<any>('getCourtRegimeTypesDropdownList')))
            .subscribe({
                next: (typesList) => {
                    typesList.map(
                        (type: { id: any; name: any; }) => this.courtRegimeDropdown.push({ value: type.id, viewValue: type.name })
                    ); 
                },
                complete: () => { 
                    this._courtRegimeObsersableList.next(this.courtRegimeDropdown);                  
                }
            });
    }

    private getProvincesDropdownList(): void {

        const provinces: Dropdown[] = [];

        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.envUrl + 'masterdata-management/provinces/';

        this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<any>('getProvincesDropdownList')))
            .subscribe
            ({
                next: (provincesList) => {
                    provincesList.map((province: { id: any; name: any; code: any; }) => 
                        this.provinceDropdown.push({ value: province.id, viewValue: province.name, code : province.code })
                    );
                },
                complete: () => {
                    this._provinceObsersableList.next(this.provinceDropdown);
                }
            });
    }

    private handleError<T>(operation = 'operation', result?: T): (val: any) => Observable<T> {
        return (error: any): Observable<T> => {
            console.error(error);
            return throwError(result);
        };
    }
}
