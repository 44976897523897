import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable } from 'rxjs';
import { IBid } from './bid.model';

@Injectable({
  providedIn: 'root'
}) 
export class BidService {    
    private endpointUrlBids: string;
    private endpointUrlBidFiles: string;

    constructor(private readonly apiProcessorService: ApiProcessorService, private readonly environment: Configuration) {                
        this.endpointUrlBids = this.environment.apiUrl + 'liquidation-management/bids';
        this.endpointUrlBidFiles = this.environment.apiUrl + 'liquidation-management/bid-files';
    }    
    
    public getBid(bidId: any): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointUrlBids}/${bidId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public downloadBidFile(fileName: string, fileId: string): Observable<any> {
        const url = `${ this.endpointUrlBidFiles }/${fileId}`;
        return this.apiProcessorService.downloadFile(url, fileName);
    }

    public getRequestFilesUrl(bidId: string): string{
        return `${ this.endpointUrlBids }/${ bidId }/files`;
    }

    public getBidFiles(bidId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrlBids }/${ bidId }/files`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public deleteBid(bidIds: string[]): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = this.endpointUrlBids;
        apiRequest.body = JSON.stringify(bidIds);

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
    
    public createBid(bid: IBid): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = this.endpointUrlBids;
        apiRequest.body = bid;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
    
    public updateBid(bid: IBid): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${ this.endpointUrlBids }/${ bid.id }`;
        apiRequest.body = bid;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getDownloadFileUrl(fileId: string): string{
        let url = this.endpointUrlBidFiles;
        if (!url.endsWith('/')) { 
            url += '/'; 
        }
        url += fileId;
        return url;
    }
}
