import { Injectable } from '@angular/core';
import { ICreditProceeding } from 'api/credit-proceeding-service/credit-proceeding.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ICreditor } from 'api/creditors-service/creditor.model';
import {  } from 'events';

@Injectable({
  providedIn: 'root'
})
export class CreditorSharedService {

    private creditProceeding = new BehaviorSubject<ICreditProceeding>(undefined);
    private creditor = new BehaviorSubject<ICreditor>(undefined);

    private changeCreditor = new Subject<string>();

    constructor() { }

    public saveCreditProceeding(creditProceeding: ICreditProceeding): void {
        this.creditProceeding.next(creditProceeding);
    }

    public saveCreditor(creditor: ICreditor): void {
        this.creditor.next(creditor);
    }

    public notifyChangeCreditor(creditorId: string): void {
        this.changeCreditor.next(creditorId);
    }

    public get getCreditProceeding(): Observable<ICreditProceeding> {
        return this.creditProceeding.asObservable();
    }

    public get getCreditor(): Observable<ICreditor> {
        return this.creditor.asObservable();
    }

    public get onChangeCreditor(): Observable<any> {
        return this.changeCreditor.asObservable();
    }
}
