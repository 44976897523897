import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IProceedingLiquidationRequestStatus } from 'api/proceeding-liquidation-request-status-service/proceeding-liquidation-request-status.model';
import { ProceedingLiquidationRequestStatusService } from 'api/proceeding-liquidation-request-status-service/proceeding-liquidation-request-status.service';
import { IProceeding } from 'api/proceedings-service/proceeding.model';
import { ProceedingsService } from 'api/proceedings-service/proceedings.service';
import { ProceedingProviderService } from 'app/services/proceeding-provider-service/proceeding-provider.service';
import { Subscription } from 'rxjs';
import { GenericDialogComponent } from 'app/components/generic-dialog/generic-dialog.component';
import { GiocondaLiquidationManagementInfoComponent } from 'app/components/gg-gioconda-liquidation-management-info/gg-gioconda-liquidation-management-info.component';

export enum LiquidationManagementButtonTypes {
    Breadcrumb = 1,
    Fuse = 2
}

@Component({
  selector: 'gg-gioconda-liquidation-management-button',
  templateUrl: './gg-gioconda-liquidation-management-button.component.html'  
})
export class GiocondaLiquidationManagementButtonComponent implements OnInit, OnDestroy {
    @Input() public liquidationManagementButtonType: LiquidationManagementButtonTypes;

    public proceeding: IProceeding;
    public titleButton: string;
    public showButton: boolean;    
    public classButton: string;
    public alertButton: string;
    public iconButton: string;
    public textAndIcon: boolean;
    
    public isButtonDisabled: boolean;

    private proceedingLiquidationRequestStatuses: { [code: number]: IProceedingLiquidationRequestStatus; } = {};
    private subscription: Subscription;

    private readonly WITHOUT_REQUEST_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE = 1;
    private readonly NEW_REQUEST_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE = 2;
    private readonly ACCEPTED_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE = 3;
    private readonly REJECTED_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE = 4;
    private readonly PENDING_REVIEW_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE = 5;

    public constructor(
        public dialog: MatDialog,
        private readonly proceedingProviderService: ProceedingProviderService,
        private readonly proceedingService: ProceedingsService,
        private readonly proceedingLiquidationRequestStatusService: ProceedingLiquidationRequestStatusService,
        private readonly activatedRoute: ActivatedRoute,

        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        this.subscription = this.proceedingProviderService.proceedingSourceObservable.subscribe((proceeding: IProceeding) => {
            this.proceeding = proceeding;
            this.setTitleButton();
        });
    }

    public ngOnInit(): void {
        if (!this.proceeding) {
            const proceedingId: string = this.activatedRoute.snapshot.params['proceedingId'];

            if (proceedingId) {
                this.proceedingService.getProceeding(proceedingId).subscribe((proceeding: IProceeding) => {
                    this.proceeding = proceeding;
                    this.setTitleButton();
                });
            } else {
                this.showButton = false;
            }
        }

        this.loadProceedingLiquidationRequestStatuses();
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }    
    }

    public setTitleButton(): void {
        if (!this.proceeding || !this.liquidationManagementButtonType) {
            this.showButton = false;
            return;
        }

        this.proceedingProviderService.shareProceedingLiquidationRequestStatusCode(this.proceeding.proceedingLiquidationRequestStatus.code);
        if (this.liquidationManagementButtonType === LiquidationManagementButtonTypes.Fuse) {
            this.setFuseButtonValues();
        } 
        else if (this.liquidationManagementButtonType === LiquidationManagementButtonTypes.Breadcrumb) {
            this.setBreadcrumbButtonValues();
        }

        this.changeDetectorRef.detectChanges();        
    }

    public openGiocondaLiquidationInfoDialog(): void {
        const proceedingId = this.proceeding ? this.proceeding.id : undefined;
        const proceedingLiquidationCode = this.proceeding ? this.proceeding.proceedingLiquidationRequestStatus.code : undefined;
        if (!this.isButtonDisabled) {
            this.dialog.open(GenericDialogComponent, {
                data: {
                    template: GiocondaLiquidationManagementInfoComponent,
                    hasLogos: true,
                    dialogTitle: '¿Necesitas liquidar o vender activos?',
                    proceeding: this.proceeding,
                    proceedingId: proceedingId,
                    proceedingLiquidationRequestStatusCode: proceedingLiquidationCode,
                    displayCloseOption: true,
                    },
                disableClose: true
            });
        }
    }

    private setBreadcrumbButtonValues(): void {
        if (this.proceeding.proceedingLiquidationRequestStatus.code === this.NEW_REQUEST_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE ||
            this.proceeding.proceedingLiquidationRequestStatus.code === this.PENDING_REVIEW_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE) {

            this.titleButton = 'Solicitud de Liquidación Enviada';
            this.classButton = 'none pending';
            this.iconButton = 'info';
            this.textAndIcon = true;            
            this.isButtonDisabled  = this.showButton = true;
            return;

        }
        else if (this.proceeding.proceedingLiquidationRequestStatus.code === this.ACCEPTED_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE) {
            this.titleButton = 'Liquidación con Gioconda';
            this.classButton = 'none accepted';
            this.iconButton = 'check_circle';            
            this.textAndIcon = true;            
            this.isButtonDisabled  = this.showButton = true;
            return;
        }

        this.showButton = false;
    }

    private setFuseButtonValues(): void {
        if (this.proceeding.proceedingLiquidationRequestStatus.code === this.WITHOUT_REQUEST_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE ||
           this.proceeding.proceedingLiquidationRequestStatus.code === this.REJECTED_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE) {
 
             this.titleButton = 'Liquida con Gioconda';
             this.classButton = 'rejected';
             this.iconButton = '';
             this.textAndIcon = false;
             this.isButtonDisabled  = false;
             this.showButton = true;
             return;
        }

        this.showButton = false;
    }

    private loadProceedingLiquidationRequestStatuses(): void {
        this.proceedingLiquidationRequestStatusService.getProceedingLiquidationRequestStatuses()
            .subscribe((proceedingLiquidationRequestStatuses: IProceedingLiquidationRequestStatus[]) => {
                proceedingLiquidationRequestStatuses.forEach((proceedingLiquidationRequestStatus) => {
                    this.proceedingLiquidationRequestStatuses[proceedingLiquidationRequestStatus.code] = proceedingLiquidationRequestStatus;
            });
        });
    }
}
