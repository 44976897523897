import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ISelectedButton } from 'app/interfaces/selected-button.model';
import { GenericListComponentDirective } from 'app/components/generic-list/generic-list.component';
import { GiocondaGenericDialogComponent } from 'app/components/gg-generic-dialog/gg-generic-dialog.component';
import { UserListPrefencesService } from 'api/user-list-preferences/user-prefences.service';
import { IAssetStockAssociationWithAssetInfo } from 'api/assets-service/stock-association/asset-stock-association-with-asset-info.model';
import { IRequest } from 'api/generic-interfaces/generic-request.interface';
import { CurrencyDirective } from 'app/directives/currency.directive';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IAssetAvailableStockAmount } from 'api/assets-service/stock-association/asset-available-stock-amount.model';
import { removeThousandsSeparators } from 'app/utils/number-utils';

@Component({
    selector: 'gg-associated-asset-stock-associations-list',
    templateUrl: './associated-asset-stock-associations-list.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [CurrencyDirective]
})
export class AssociatedAssetStockAssociationsListComponent extends GenericListComponentDirective<IAssetStockAssociationWithAssetInfo> {
    @ViewChild('confirmNavigationDialog', { static: true }) public confirmNavigationDialog: GiocondaGenericDialogComponent;

    @Input() public proceedingId: string;
    @Input() public set associatedAssetStockAssociationsWithAssetInfo(value: Array<IAssetStockAssociationWithAssetInfo>) {
        if (value) {
            this._associatedAssetStockAssociationsWithAssetInfo = value;
            this.initializeFormArray();
        }
    }

    @Input() public set initialAssetStockAssociationsWithAssetInfo(value: Array<IAssetStockAssociationWithAssetInfo>) {
        if (value) {
            this._initialAssetStockAssociationsWithAssetInfo = value;
        }
    }

    @Input() public set isAutomaticLiquidationProcess(value: boolean) {
        this._isAutomaticLiquidationProcess = value;
        this.buildVisibleFields();
        this.initializeVisibleFields();
    }

    @Input() public parentGroup: UntypedFormGroup;
    @Input() public controlName: string;
    @Input() public isOpenedConfirmNavigationDialog = true;
    @Input() public set blockedFields(value: boolean) {
        if (value !== undefined && value !== null) {
            this._blockedFields = value;            

            this.childrenControls.controls.forEach((formGroup: UntypedFormGroup) => 
                this._blockedFields ? formGroup.controls.associatedUnits.disable() : formGroup.controls.associatedUnits.enable()
            );
        }
    }
            
    @Output() public associatedAssetStockAssociationsWithAssetInfoChange = new EventEmitter<Array<IAssetStockAssociationWithAssetInfo>>();
    @Output() public removeAssociatedAsset = new EventEmitter<string>();
    @Output() public navigationConfirmed = new EventEmitter<{url: string,  state: any}>();

    public get childrenControls(): UntypedFormArray {
        if (!this.parentGroup) {
            return undefined;
        }
        return this.parentGroup.get(this.controlName) as UntypedFormArray;
    }
    
    public displayedColumns: string[];
    public assets: MatTableDataSource<IAssetStockAssociationWithAssetInfo> = null;
    public selection = new SelectionModel<IAssetStockAssociationWithAssetInfo>(true, []);
    public idProperty: string;
    public selectedItems: ISelectedButton[] = [];
    public isAnyItemListSelected = false;
    public _blockedFields = false;
    public _associatedAssetStockAssociationsWithAssetInfo: Array<IAssetStockAssociationWithAssetInfo>;
    public _initialAssetStockAssociationsWithAssetInfo: Array<IAssetStockAssociationWithAssetInfo>;
    public _isAutomaticLiquidationProcess: boolean;

    protected currentRequest: IRequest;

    private navigationData: {url: string, state: any};
    
    constructor(
        public dialog: MatDialog,
        userListPrefencesService: UserListPrefencesService
    ) {
        super(userListPrefencesService);
        this.idProperty = 'id';
        this.userPreferencesName = 'liquidationProcessAssetsUserPreferences';
    }
    
    public filter(event: Event): void {
        const filter = (event.target as HTMLInputElement).value;
        this.dataTable.filter = filter.trim().toLowerCase();
    }
     
    public deleteAssociatedAsset(assetId: string): void {
        if (this._blockedFields) {
            return;
        }

        this.removeAssociatedAsset.emit(assetId);
    }

    public checkAssetAssociatedUnits(assetId: string, associatedUnits: number, index: number): void {
        associatedUnits = removeThousandsSeparators(associatedUnits);

        const associatedAssetStockAssociation: IAssetStockAssociationWithAssetInfo = this._associatedAssetStockAssociationsWithAssetInfo
            .find((alpa: IAssetStockAssociationWithAssetInfo) => alpa.assetId === assetId);
        
        const initialAssetStockAssociation: IAssetStockAssociationWithAssetInfo = this._initialAssetStockAssociationsWithAssetInfo
            .find((lpa: IAssetStockAssociationWithAssetInfo) => lpa.assetId === assetId);
        
        if (associatedUnits > initialAssetStockAssociation.associatedUnits) {
            if (associatedUnits - initialAssetStockAssociation.associatedUnits > initialAssetStockAssociation.availableStockAmount) {
                const formGroup = this.childrenControls.controls[index] as UntypedFormGroup;
                formGroup.controls['associatedUnits'].setErrors({invalidAssociatedUnits: true});
                associatedAssetStockAssociation.availableStockAmount = initialAssetStockAssociation.availableStockAmount;
                return;
            }
            
            associatedAssetStockAssociation.availableStockAmount = initialAssetStockAssociation.availableStockAmount - 
                (associatedUnits - initialAssetStockAssociation.associatedUnits);
        } else {
            associatedAssetStockAssociation.availableStockAmount = initialAssetStockAssociation.availableStockAmount + 
                (initialAssetStockAssociation.associatedUnits - associatedUnits);
        }

        associatedAssetStockAssociation.associatedUnits = associatedUnits;
        this.associatedAssetStockAssociationsWithAssetInfoChange.emit(this._associatedAssetStockAssociationsWithAssetInfo);
    }

    public setAssetAssociatedUnitsWithErrors(assetsWithIncorrectAssociatedUnits: Array<IAssetAvailableStockAmount>): void {
        const assetsControlsWithIncorrectAssociatedUnits: AbstractControl[] = this.childrenControls.controls.filter((c: UntypedFormGroup) => 
            assetsWithIncorrectAssociatedUnits.map((a: IAssetAvailableStockAmount) => a.assetId).includes(c.controls['assetId'].value));
                
        assetsControlsWithIncorrectAssociatedUnits.forEach((fg: UntypedFormGroup) => {
            fg.patchValue({associatedUnits: 0});
            fg.controls['associatedUnits'].setErrors({invalidAssociatedUnits: true});
            fg.updateValueAndValidity();

            const currentAssetId = fg.controls['assetId'].value;
            const associatedAssetStockAssociation: IAssetStockAssociationWithAssetInfo = this._associatedAssetStockAssociationsWithAssetInfo
                .find((a: IAssetStockAssociationWithAssetInfo) => a.assetId === currentAssetId);
            
            const initialAssetStockAssociation: IAssetStockAssociationWithAssetInfo = this._initialAssetStockAssociationsWithAssetInfo
                .find((a: IAssetStockAssociationWithAssetInfo) => a.assetId === currentAssetId);

            const assetWithIncorrectAssociatedUnits: IAssetAvailableStockAmount = assetsWithIncorrectAssociatedUnits
                .find((a: IAssetAvailableStockAmount) => a.assetId === currentAssetId);

            initialAssetStockAssociation.availableStockAmount = associatedAssetStockAssociation.availableStockAmount = 
                assetWithIncorrectAssociatedUnits.availableStockAmount;
            associatedAssetStockAssociation.associatedUnits = 0;
        });
    }

    public openConfirmNavigationDialog(url: string, state?: any): void {
        this.navigationData = {url, state};

        if (!this.isOpenedConfirmNavigationDialog) {
            this.confirmNavigation();
            return;
        }

        this.confirmNavigationDialog.openDialog({
            maxWidth: '50vw',
            maxHeight: '60vh'
        });
    }

    public confirmNavigation(): void {
        this.navigationConfirmed.emit(this.navigationData);
    }

    public cancelNavigation(): void {
         this.confirmNavigationDialog.closeDialog();
    }

    public deleteSelection(): void { 
        throw new Error('Method not implemented.');
    }

    protected reloadTableContent(): void {
        throw new Error('Method not implemented.');
    }    

    protected parseValueToExportExcel(column: string, data: IAssetStockAssociationWithAssetInfo): number | string {
        throw new Error('Method not implemented.');
    }

    private initializeFormArray(): void {
        if (this.childrenControls) {
            this.childrenControls.clear();
        }
        this._associatedAssetStockAssociationsWithAssetInfo.forEach((alpa: IAssetStockAssociationWithAssetInfo) => {
            this.childrenControls.push(new UntypedFormGroup({
                assetId: new UntypedFormControl(alpa.assetId),
                associatedUnits: new UntypedFormControl(alpa.associatedUnits, [Validators.required])
            }));
        });
    }

    private buildVisibleFields(): void {
        this.visibleFields = {
            name: { value: true, exportExcel: false, valueName: 'Nombre Descriptivo' },
            associatedUnits: { value: true, exportExcel: false, valueName: 'Unidades Asociadas' },
            availableStockAmount: { value: true, exportExcel: false, valueName: 'Stock Disponible' },
            associatedCreditsAmount: { value: true, exportExcel: false, valueName: 'Créditos Asociados' },
            delete: { value: true, exportExcel: false, valueName: 'Eliminar' }
        };

        if (this._isAutomaticLiquidationProcess) {
            this.visibleFields = {
                name: { value: true, exportExcel: false, valueName: 'Nombre Descriptivo' },
                associatedUnits: { value: true, exportExcel: false, valueName: 'Unidades Asociadas' },
                associatedCreditsAmount: { value: true, exportExcel: false, valueName: 'Créditos Asociados' },
                delete: { value: true, exportExcel: false, valueName: 'Eliminar' }
            };
        }

        this.displayedColumns = Object.keys(this.visibleFields);
    }
}
