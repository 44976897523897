import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

    public useSSL = 'https:' === document.location.protocol;
    private divHomeBanner: HTMLElement; 
    private scriptBanner: HTMLScriptElement; 
    private functionScriptBanner = 'googletag.cmd.push(function() {googletag.display(\'div-gpt-ad-1646396883044-0\');}); ';

    public load(): void {

        this.scriptBanner = document.createElement('script');
        this.scriptBanner.append(this.functionScriptBanner);

        this.divHomeBanner = document.createElement('div');
        this.divHomeBanner.setAttribute('class', 'home-banner');
        this.divHomeBanner.setAttribute('id', 'div-gpt-ad-1646396883044-0');
        this.divHomeBanner.appendChild(this.scriptBanner);

        document.body.appendChild(this.divHomeBanner);
    }

}
