import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { IBidFile } from 'api/liquidations/bid-service/bid-file.model';
import { IBidForList } from 'api/liquidations/bid-service/bid-for-list.model';
import { BidService } from 'api/liquidations/bid-service/bid.service';
import { LoginService } from 'app/services/login/login.service';

@Component({
  selector: 'app-bid-attached-files-modal',
  templateUrl: './bid-attached-files-modal.component.html'
})
export class BidAttachedFilesModalComponent {

    public bid: IBidForList;
    public proceedingId: string;
    public navigateToBid: boolean;
    public tendererId: string;

    public constructor(
        private readonly bidService: BidService,
        private readonly router: Router,
        private readonly loginService: LoginService,
        public dialogRef: MatDialogRef<BidAttachedFilesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.bid = data.bid;
        this.proceedingId = data.proceedingId;
        this.navigateToBid = data.navigateToBid;
        this.tendererId = data.tendererId;
    }

    public downloadFile(file: IBidFile): void {
        this.bidService.downloadBidFile(file.fileName, file.id).subscribe({
            error: (error: any) => {
                if (error instanceof UrlTree) {
                    this.closeDialog();
                    this.loginService.returnUrl.next(this.router.url);
                    this.router.navigate([error.toString()]);                    
                }
            }
        });
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public goToBid(): void {
        this.dialogRef.close();

        if (this.tendererId) {
            const extraParams: NavigationExtras = {
                state: {
                    tendererId: this.tendererId
                }
            };

            this.router.navigate(['/manager/proceedings/' + this.proceedingId + '/bids/' + this.bid.id + '/edit-bid'], extraParams);
        }
        else {
            this.router.navigate(['/manager/proceedings/' + this.proceedingId + '/bids/' + this.bid.id + '/edit-bid']);
        }
    }
}
