import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    private endpointUrl: string;
    
    constructor(private readonly apiProcessor: ApiProcessorService, private readonly environment: Configuration) {
        
        this.endpointUrl = `${ this.environment.apiUrl }user-management/users`;
    }

    public synchronizeUser(): Observable<any> {
        const request = new ApiRequest();
        request.operation = ApiOperation.POST;
        request.endpointUrl = `${ this.endpointUrl }/synchronize`;

        return this.apiProcessor.proccessRequest(request);
    }

    public getUserByToken(token: string): Observable<any> {
        const request = new ApiRequest();
        request.operation = ApiOperation.GET;
        request.endpointUrl = `${ this.endpointUrl }?token=${ token }`;

        return this.apiProcessor.proccessRequest(request);
    }
    
    public getUsersByEmail(email: string): Observable<any> {
        const request = new ApiRequest();
        request.operation = ApiOperation.GET;
        request.endpointUrl = `${ this.endpointUrl }?email=${ email }`;

        return this.apiProcessor.proccessRequest(request);
    }

    public deleteUserToken(token: string): Observable<any> {
        const request = new ApiRequest();
        request.operation = ApiOperation.DELETE;
        request.endpointUrl = `${ this.endpointUrl }?token=${ token }`;

        return this.apiProcessor.proccessRequest(request);
    }
}
