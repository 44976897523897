import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

export class TableUtil {
    public static exportArrayToExcel(heading: string[], columnsWidth: number[], data: any[], sheetName?: string, excelName?: string): void {
        const fileName = excelName || 'Resultados';

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(sheetName || 'Resultados');
        const columns = [];
        for (let i = 0; i < heading.length; i++) {
            columns.push({
                header: heading[i],
                key: heading[i].toLowerCase(),
                width: columnsWidth[i],
                style: { alignment: { vertical: 'middle', horizontal: 'left', wrapText: true } }
            });
        }
        worksheet.columns = columns;
        worksheet.addRows(data);

        workbook.xlsx.writeBuffer().then((dataBuffer: Buffer) => {
            const blob = new Blob([dataBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fileName + '.xlsx');
        });
    }
}
