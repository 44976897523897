import { Component } from '@angular/core';
import { Configuration } from 'api/configuration-service/configuration.model';
import { FooterLinks } from 'api/configuration-service/footer-links.model';
import { PublicLinksButtonsService } from 'app/services/public-links-buttons/public-links-buttons.service';


@Component({
    selector: 'gioc-footer',
    templateUrl: './footer.component.html',
    styleUrls : ['./footer.component.scss']
})

export class FooterComponent {

    public footerLinks: FooterLinks;

    public constructor(
        private readonly environment: Configuration,
        protected readonly publicLinksbuttons: PublicLinksButtonsService
    ) {
        this.footerLinks = this.environment.footerLinks;
    }

    public loadScript(): void {
        const privacyButton = document.getElementById('qc-cmp2-persistent-link');
        privacyButton.click();
    }

    public goToLink(link: any): void {
        this.publicLinksbuttons.goToLink(link);
    }
}
