import { Component, Input, OnChanges } from '@angular/core';
import { GenericFilterDirective } from '../generic-filter/generic-filter';

@Component({
    selector: 'gg-filter-list-column',
    templateUrl: './gg-filter-list-column.component.html'
})
export class FilterListColumnComponent<T> extends GenericFilterDirective<T> implements OnChanges {                
    public inputText: string;
    @Input() public isDisabled = false;
    @Input() public type: string;
    
    public getFilterPredicate(data: T, filter: string): boolean {
        if (!this.filterName || !this.isFieldVisible) {
            return true;
        }
      
        const filterTerms = JSON.parse(filter);
        
        if (this.customSearch && filterTerms[this.filterName] && filterTerms[this.filterName] !== ''){
            const searchTerms = new Array<string>(filterTerms[this.filterName]);
            return this.customSearch(searchTerms, data, this.filterName);
        }

        return this.nameSearch(filterTerms[this.filterName], data[this.filterName]);      
    }

    public onTextChange(): void {
        if (this.inputText === '') {
            this.resetFilters();
        }

        this.dataTable.filter = this.getNewFilter(this.inputText);
        this.filterChanged.emit();
    }

    public resetFilters(): void {
        this.inputText = '';
        super.resetFilters();
    }

    private getNewFilter(text: string): string {
        let newFilter = {};

        if (this.dataTable.filter) {
            newFilter = JSON.parse(this.dataTable.filter);
        }
    
        newFilter[this.filterName] = text;
        return JSON.stringify(newFilter);
    }

    private nameSearch(searchTerm: string, dataObj: any): boolean {
        const data: string = this.getValue(dataObj);
        if (!searchTerm || searchTerm === ''){
            return true;
        }

        return data.startsWith(searchTerm);
    }
}
