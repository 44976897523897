import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, UrlTree } from '@angular/router';
import { IAsset } from 'api/assets-service/asset.model';
import { AssetsService } from 'api/assets-service/assets.service';
import { IAssetFile } from 'api/assets-service/assetFile.model';
import { LoginService } from 'app/services/login/login.service';

@Component({
    selector: 'app-assets-files-modal',
    templateUrl: './assets-attached-files-modal.component.html'
})
export class AssetsAttachedFilesModalComponent {
    public asset: IAsset;
    public navigateToAsset: boolean;
    private proceedingId: string;

    public constructor(
        private readonly assetsService: AssetsService,
        private readonly loginService: LoginService,
        private readonly router: Router,
        public dialogRef: MatDialogRef<AssetsAttachedFilesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.asset = data.asset;
        this.proceedingId = data.proceedingId;
        this.navigateToAsset = data.navigateToAsset;
    }

    public downloadFile(file: IAssetFile): void {
        this.assetsService.downloadAssetFile(this.asset.id, file.fileName, file.id).subscribe({ 
            error: (error: any) => {
                if (error instanceof UrlTree) {
                    this.closeDialog();
                    this.loginService.returnUrl.next(this.router.url);
                    this.router.navigate([error.toString()]);                 
                }    
             }
        });
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public goToAsset(): void {
        this.dialogRef.close();
        this.router.navigate(['/manager/proceedings/' + this.proceedingId + '/assets-proceeding/edit-asset', this.asset.id]);
    }
}
