import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiProcessorService, ApiRequest, ApiOperation } from 'api/api-processor-service/api-processor.service';

import { Configuration } from 'api/configuration-service/configuration.model';

@Injectable({
    providedIn: 'root'
})
export class CreditorTypeService {
    private endpointUrl: string;
    
    constructor(private readonly apiProcessor: ApiProcessorService, private readonly environment: Configuration) {
        
        this.endpointUrl = this.environment.apiUrl + 'masterdata-management/creditor-types';
    }

    getAllCreditorTypes(): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }
}
