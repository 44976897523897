import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomIconService {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) { }

    init(): void {
        const svgIcons = [
            { name: 'news', url: '../../assets/icons/gio-icons/news.svg' },
            { name: 'lunchbox', url: '../../assets/icons/gio-icons/lunchbox.svg' },
            { name: 'activo', url: '../../assets/icons/gio-icons/activo.svg' },
            { name: 'pasivo', url: '../../assets/icons/gio-icons/pasivo.svg' },
            { name: 'file-list', url: '../../assets/icons/gio-icons/file-list.svg' },
            { name: 'email', url: '../../assets/icons/gio-icons/email.svg' },
            { name: 'coins', url: '../../assets/icons/gio-icons/coins.svg' },
            { name: 'box', url: '../../assets/icons/gio-icons/box.svg' },
            { name: 'filled-contrast', url: '../../assets/icons/gio-icons/filled-contrast.svg' },
            { name: 'home', url: '../../assets/icons/gio-icons/home.svg' },
            { name: 'pencil-square', url: '../../assets/icons/gio-icons/pencil-square.svg' },
            { name: 'settings', url: '../../assets/icons/gio-icons/settings.svg' },
            { name: 'trash', url: '../../assets/icons/gio-icons/trash.svg' },
            { name: 'user', url: '../../assets/icons/gio-icons/user.svg' },
            { name: 'wk-training', url: '../../assets/icons/gio-icons/wk-training.svg' },
            { name: 'payment', url: '../../assets/icons/gio-icons/payment.svg' },
            { name: 'quotes', url: '../../assets/icons/gio-icons/quotes.svg' },
            { name: 'laley-logo', url: '../../assets/images/logos/laley-logo.svg' },
            { name: 'laley-isotipo', url: '../../assets/images/logos/gioconda-logo-isotipo.svg' },
            { name: 'gioc-logo', url: '../../assets/images/logos/gioc-logo.svg' },
            { name: 'gioc-isotipo', url: '../../assets/images/logos/gioc-isotipo.svg' },
            { name: 'auctions-logo', url: '../../assets/images/logos/gioc-auctions-logo.svg' },
            { name: 'auctions-isotipo', url: '../../assets/images/logos/gioc-auctions-isotipo.svg' },
            { name: 'auctions-white', url: '../../assets/images/logos/gioc-auctions-logo-white.svg' },
            { name: 'quotes', url: '../../assets/icons/gio-icons/quotes.svg' },
        ];
        svgIcons.forEach(svgIcon => this.matIconRegistry.addSvgIcon(
            svgIcon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(svgIcon.url)
        ));


    }
}
