import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from 'api/authentication-service/authentication.service';
import { ILoggedUserInfo } from 'api/authentication-service/logged-user-info.model';
import { AuthenticatedUserCacheService } from 'app/services/authenticated-user-cache/authenticated-user-cache.service';
import { LoginService } from 'app/services/login/login.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserRightsGuard implements CanActivate {
    public constructor(
        private readonly loginService: LoginService,
        private readonly router: Router,
        private readonly authenticatedUserProvider: AuthenticatedUserCacheService,
        private readonly authenticationService: AuthenticationService) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.authenticationService.getLoggedUserInfo().pipe(
            map((loggedUserInfo: ILoggedUserInfo) => {
                if (!loggedUserInfo) {
                    this.loginService.returnUrl.next(state.url);
                    return this.router.parseUrl('/public/unauthorized');
                }

                this.authenticatedUserProvider.setLoggedUserInfo(loggedUserInfo);
                if (loggedUserInfo.hasUserRights) {
                    return true;
                }
                return this.router.parseUrl('/public/unauthorized?type=1');
            }), catchError (() => of (false) )
        );                      
    }
}
