import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable } from 'rxjs';
import { IIncome } from './income.model';

@Injectable({
  providedIn: 'root'
})
export class IncomeService {

    private endpointIncomesUrl: string;
    private endpointProceedingIncomesUrl: string;
    private endpointCustomerIncomesUrl: string;
    private endpointIncomeFilesUrl: string;

    constructor(private readonly apiProcessor: ApiProcessorService, private readonly environment: Configuration) {
        this.endpointIncomesUrl = this.environment.apiUrl + 'treasury-management/incomes';
        this.endpointProceedingIncomesUrl = this.environment.apiUrl + 'treasury-management/proceedings';
        this.endpointCustomerIncomesUrl = this.environment.apiUrl + 'treasury-management/customers';
        this.endpointIncomeFilesUrl = this.environment.apiUrl + 'treasury-management/income-files';
    }

    public getIncome(incomeId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointIncomesUrl}/${incomeId}`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getIncomes(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointProceedingIncomesUrl}/${proceedingId}/incomes`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getCustomerIncomes(customerId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointCustomerIncomesUrl}/${customerId}/incomes`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public createIncome(income: IIncome): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = this.endpointIncomesUrl;
        apiRequest.body = income;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public updateIncome(income: IIncome): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${this.endpointIncomesUrl}/${income.id}`;
        apiRequest.body = income;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public deleteIncomes(incomeIds: string[]): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = this.endpointIncomesUrl;
        apiRequest.body = JSON.stringify(incomeIds);

        return this.apiProcessor.proccessRequest(apiRequest);  
    }

    public getIncomeFiles(incomeId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointIncomesUrl}/${incomeId}/files`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public downloadIncomeFile(fileName: string, fileId: string): Observable<any> {
        const url = this.getDownlodFileUrl(fileId);
        return this.apiProcessor.downloadFile(url, fileName);
    }

    public getDownlodFileUrl(fileId: string): string{
        let url = this.endpointIncomeFilesUrl;
        if (!url.endsWith('/')) { 
            url += '/'; 
        }
        url += fileId;
        return url;
    }
}
