import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable, of } from 'rxjs';
import { IUserPreferences } from './user-preferences.model';

@Injectable({
  providedIn: 'root'
})
export class UserListPrefencesService {
    private usersUrl: string;

    constructor(private readonly apiProcessorService: ApiProcessorService, private readonly environment: Configuration) { 
        this.usersUrl = this.environment.apiUrl + 'user-management/user-preferences';
    }

    public getUserListPreferences(code: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.usersUrl }?listCode=${code}`;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public updateUserListPreferences(userPreferences: IUserPreferences): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${ this.usersUrl }/${userPreferences.id}`;
        apiRequest.body = userPreferences;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public saveUserListPreferences(userPreferences: IUserPreferences): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = this.usersUrl;
        apiRequest.body = userPreferences;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

}
