import { Component, ViewEncapsulation } from '@angular/core';
import { MobileService } from 'app/services/mobile-service/mobile.service';

@Component({
    selector: 'gg-panel-button-bar',
    templateUrl: './gg-panel-button-bar.component.html',
    styleUrls    : ['./gg-panel-button-bar.component.scss'],
    encapsulation  : ViewEncapsulation.None,
})

export class PanelButtonBarComponent  {

    public isMobile: boolean;
    
    constructor(
        public mobile: MobileService,
    ){
        this.isMobile = this.mobile.isMobile;
    }

}

