import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Observable } from 'rxjs';
import { ApiOperation, ApiProcessorService, ApiRequest } from '../api-processor-service/api-processor.service';
import { ICreditorInProceedingRequest } from './creditor-proceeding-request';
import { ICreditorProceeding } from './creditor-proceeding.model';
import { IJsonPatchDocument } from 'api/api-processor-service/json-patch-document.model';

@Injectable({
    providedIn: 'root'
})
export class CreditorProceedingService {
    private endpointUrl = this.environment.apiUrl + 'proceeding-creditors-management';
    private endPointProceedingCreditorNotes = this.environment.apiUrl + 'proceeding-management/proceeding-creditors';
    
    constructor(
        private readonly apiProcessor: ApiProcessorService,
        private readonly http: HttpClient,
        private readonly environment: Configuration) {

        
        this.endpointUrl = this.environment.apiUrl + 'proceeding-creditors-management';
    }

    public getCreditorsInProceedingByProceedingId(request: ICreditorInProceedingRequest): Observable<any> {
        const baseUrlEndpoint = `${ this.endpointUrl }/proceeding/${ request.proceedingId }/creditorsInProceeding/?`;
        const clonnedRequest = Object.assign({}, request);
        delete clonnedRequest.proceedingId;
        const apiRequest = this.buildProceedingCreditorRequest(clonnedRequest, baseUrlEndpoint);        
        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getCreditorsInProceeding(request: ICreditorInProceedingRequest): Observable<any> {
        const baseUrlEndpoint = `${ this.endpointUrl }/proceeding-creditors/?`;
        const apiRequest = this.buildProceedingCreditorRequest(request, baseUrlEndpoint);
        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getCreditorInProceedingById(creditorInProceedingId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/proceeding-creditors/${ creditorInProceedingId }`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getCreditorInProceedingByTaxAndProceedingId(taxId: string, proceedingId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/proceeding-creditors?proceedingId=${ proceedingId }&taxId=${ taxId }`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getCreditorInProceedingByCreditorIdAndProceedingId(creditorId: string, proceedingId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/proceeding-creditors?proceedingId=${ proceedingId }&creditorId=${ creditorId }`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getProceedingCreditorNotes(proceedingCreditorId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endPointProceedingCreditorNotes }/${proceedingCreditorId}/proceeding-creditor-notes`;
        
        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public saveCreditorInProceeding(creditor: ICreditorProceeding): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${ this.endpointUrl }/proceeding-creditors`;
        apiRequest.body = creditor;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public updateCreditorInProceeding(creditor: ICreditorProceeding): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${this.endpointUrl}/proceeding-creditors/${creditor.id}`;
        apiRequest.body = creditor;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public patchCreditorInProceeding(jsonPatchDocument: Array<IJsonPatchDocument>, proceedingCreditorId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.endpointUrl = `${this.endpointUrl}/proceeding-creditors/${proceedingCreditorId}`;
        apiRequest.body = JSON.stringify(jsonPatchDocument);

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public deleteCreditorInProceeding(creditorId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${ this.endpointUrl }/proceeding-creditors/${ creditorId }`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public deleteCreditorsInProceedingCollection(creditorsIds: Array<string>): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${ this.endpointUrl }/proceeding-creditors/`;
        apiRequest.body = JSON.stringify(creditorsIds);

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public importCreditorsFile(proceedingId: string, dataFile: File): Observable<any> {
        const endpointUrl = `${ this.endpointUrl }/proceeding-creditors/files/${ proceedingId }`;

        const reqBody = new FormData();
        reqBody.append('file', dataFile, dataFile.name);

        return this.http.post(endpointUrl, reqBody, { withCredentials: true });
    }
    

    private buildProceedingCreditorRequest(request: ICreditorInProceedingRequest, baseUrlEndpoint: string): ApiRequest {
        const apiRequest = new ApiRequest();
        const params = this.apiProcessor.getRequestBodyAsUrlParams(request);

        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${baseUrlEndpoint}&${params}`;
        return apiRequest;
    }
}
