import { MatPaginatorIntl } from '@angular/material/paginator';

const rangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) { return `0 de ${length}`; }
  
  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${numberToString(startIndex + 1)} - ${numberToString(endIndex)} de ${numberToString(length)}`;
};

const numberToString = (page: number) => {
  return String(page).replace(/(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.');
};

export function getSpanishPaginatorIntl(): any {
  const paginatorIntl = new MatPaginatorIntl();
  
  paginatorIntl.itemsPerPageLabel = 'Elementos por página:';
  paginatorIntl.nextPageLabel = 'Página siguiente';
  paginatorIntl.previousPageLabel = 'Página anterior';
  paginatorIntl.getRangeLabel = rangeLabel;
  
  return paginatorIntl;
}
