import { Injectable } from '@angular/core';
import { IJsonPatchDocument } from 'api/api-processor-service/json-patch-document.model';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable } from 'rxjs';
import { ApiOperation, ApiProcessorService, ApiRequest } from '../api-processor-service/api-processor.service';
import { ITariff } from './proceeding-tariff.model';
import { IProceeding } from './proceeding.model';

@Injectable({
    providedIn: 'root'
})
export class ProceedingsService {
    private proceedingsUrl: string;
    private reducedProceedingUrl: string;
    private calculateTariffUrl: string;
    
    constructor(private readonly apiProcessorService: ApiProcessorService, private readonly environment: Configuration) {
        
        this.proceedingsUrl = this.environment.apiUrl + 'proceeding-management/proceedings';
        this.reducedProceedingUrl = this.environment.apiUrl + 'credit-management/proceedings';
        this.calculateTariffUrl = this.environment.apiUrl + 'proceeding-management/calculate-tariff';
    }

    public getProceedingsByUserId(): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.proceedingsUrl }`;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public getProceedingsByCreditorIdAndUserId(creditorId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.reducedProceedingUrl }?creditorId=${ creditorId }`;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public getProceeding(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.proceedingsUrl }/${ proceedingId }`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
           
    public saveProceeding(proceeding: IProceeding): Observable<any> {
        const reqBody = this.apiProcessorService.setBody(proceeding); 
        return this.apiProcessorService.postObject(this.proceedingsUrl, reqBody);
    }

    public updateProceeding(proceedingId: string, proceeding: IProceeding): Observable<any>  {
        const reqBody = this.apiProcessorService.setBody(proceeding); 
        const endpointUrl = `${ this.proceedingsUrl }/${ proceedingId }`;
        return this.apiProcessorService.putObject(endpointUrl, reqBody);
    }

    public patchProceeding(jsonPatchDocument: Array<IJsonPatchDocument>, proceedingId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.endpointUrl = `${this.proceedingsUrl}/${proceedingId}`;
        apiRequest.body = JSON.stringify(jsonPatchDocument);

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public deleteProceeding(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${ this.proceedingsUrl }/${ proceedingId }`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public calculateTariff(tariff: ITariff): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = this.calculateTariffUrl;
        apiRequest.body = tariff; 

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public changeStatus(proceedingIds: Array<string>, newProceedingStatusCode: number): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${ this.proceedingsUrl }/change-status`;
        apiRequest.body = {
            proceedingIds: proceedingIds,
            ProceedingStatusCode: newProceedingStatusCode
        };

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
