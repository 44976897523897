import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AccountingService } from 'api/accounting-service/accounting-service';
import { ApiProcessorService } from 'api/api-processor-service/api-processor.service';
import { AssetsService } from 'api/assets-service/assets.service';
import { AuthenticationService } from 'api/authentication-service/authentication.service';
import { CreditNotesService } from 'api/credit-notes-service/credit-notes.service';
import { CreditProceedingService } from 'api/credit-proceeding-service/credit-proceeding.service';
import { CreditorProceedingService } from 'api/creditor-proceeding-service/creditor-proceeding.service';
import { CreditorTypeService } from 'api/creditor-type-service/creditor-type.service';
import { CreditorsService } from 'api/creditors-service/creditors.service';
import { CreditsService } from 'api/credits-service/credits.service';
import { DocumentIssuingTypesService } from 'api/document-issuing-types-service/document-issuing-types.service';
import { DocumentService } from 'api/document-service/document.service';
import { DocumentTypesService } from 'api/document-types-service/document-types.service';
import { ApplicationEmailTemplateService } from 'api/application-email-template-service/application-email-template.service';
import { OfficeService } from 'api/office-service/office.service';
import { ProceedingsService } from 'api/proceedings-service/proceedings.service';
import { SignatureTypesService } from 'api/signature-types-service/signature-types.service';
import { StaticContentService } from 'api/static-content-service/static-content.service';
import { TemplatePhaseTypeService } from 'api/template-phase-types-service/template-phase-type.service';
import { UserService } from 'api/user-service/user.service';
import { AllowCreditorAccessGuard } from './guards/allow-creditor.guard';
import { AllowExternalCreditValidationGuard } from './guards/allow-external-credit-validation.guard';
import { AllowManagerAccessGuard } from './guards/allow-manager.guard';
import { AuthenticationGuard } from './guards/authentication.guard';
import { CreditorWithProceedingsGuard } from './guards/creditor-with-proceedings.guard';
import { OfficesGuard } from './guards/offices.guard';
import { UserRightsGuard } from './guards/user-rights.guard';
import { AngularUpdateService } from './services/angular-update-service/angular-update.service';
import { CacheContainerService } from './services/cache/cache-container.service';
import { CreditorSharedService } from './services/creditor-shared-service/creditor-shared.service';
import { CustomIconService } from './services/custom-icon-service/custom-icon.service';
import { DropdownsService } from './services/dropdowns-service/dropdowns.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { HttpInterceptorProvider } from './services/http-interceptor/http-interceptor.service';
import { FuseSegmentedNavigationService } from './services/navigation-service/fuse-segmented-navigation';
import { AccountingProceedingCreditorsService } from '../api/accounting-proceeding-creditors-service/accounting-proceeding-creditors.service';
import { TemplateTokenGroupProviderService } from './services/template-token-group-provider/template-token-group-provider.service';
import { ProceedingProviderService } from './services/proceeding-provider-service/proceeding-provider.service';
import { ProceedingLiquidationRequestStatusService } from 'api/proceeding-liquidation-request-status-service/proceeding-liquidation-request-status.service';
import { CreditorListHelper } from 'api/creditors/creditor-list-helper';
import { CurrentOfficesResolver } from './resolvers/current-offices.resolver';
import { AllowCalendarTaskModuleGuard } from './guards/allow-calendar-task-module';
import { LoginService } from './services/login/login.service';
import { MobileService } from './services/mobile-service/mobile.service';


import { AuthenticatedUserCacheService } from './services/authenticated-user-cache/authenticated-user-cache.service';

const COMMON_SERVICE_PROVIDERS = [
    // API provider
    CookieService,
    GoogleAnalyticsService,
    ProceedingsService,
    OfficeService,
    CreditorsService,
    CreditorProceedingService,
    CreditsService,
    CreditNotesService,
    ApiProcessorService,
    AssetsService,
    AccountingService,
    CreditorTypeService,
    DocumentService,
    DocumentIssuingTypesService,
    DocumentTypesService,
    SignatureTypesService,
    ProceedingLiquidationRequestStatusService,
    ApplicationEmailTemplateService,
    CacheContainerService,    
    AuthenticationService,
    UserService,
    CreditProceedingService,
    TemplatePhaseTypeService,
    StaticContentService,
    AccountingProceedingCreditorsService,
    CreditorListHelper,

    // Angular & Misc providers
    DropdownsService,
    CustomIconService,
    AuthenticationGuard,
    OfficesGuard,
    UserRightsGuard,
    CreditorWithProceedingsGuard,
    CurrentOfficesResolver,
    FuseSegmentedNavigationService,
    AuthenticatedUserCacheService,        
    LoginService,
    MobileService,
    ProceedingProviderService,
    TemplateTokenGroupProviderService,
    CreditorSharedService,
    AllowExternalCreditValidationGuard,
    AllowCreditorAccessGuard,
    AllowManagerAccessGuard,
    AllowCalendarTaskModuleGuard,
    DecimalPipe,
    DatePipe,
    AngularUpdateService,
    HttpInterceptorProvider,
    // Locale provider
    { provide: LOCALE_ID, useValue: 'es-ES' },
];

@NgModule({
    providers: [...COMMON_SERVICE_PROVIDERS],
    imports: [CommonModule]
})
export class GiocondaServicesModule {

}
