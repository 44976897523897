import { AbstractControl, ValidationErrors } from '@angular/forms';

function isEmptyInputStringValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

export class CommonValidators {

    // tslint:disable-next-line: max-line-length
    private static CUSTOM_EMAIL_REGEX = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(?:\.(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){2,})$/;

    static email(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputStringValue(control.value)) {
            return null;  // don't validate empty values to allow optional controls
        }
        return CommonValidators.CUSTOM_EMAIL_REGEX.test(control.value) ? null : { email: true};
    }
}
