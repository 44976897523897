export interface ITemplateToken {
    id: string;
    token: string;
    description: string;
    helpDescription: string;
    tokenGroup: ITokenGroup;
}

export interface ITokenGroup {
    id: string;
    code: number;
    name: string;
    templateTokens: ITemplateToken[];
}

export enum ApplicationTokenSections {
    DOCUMENTS = 1,
    COMMUNICATIONS = 2
}
