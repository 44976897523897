import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable } from 'rxjs';
import { ICreditAgainstEstate } from './credit-against-estate.model';

@Injectable({
  providedIn: 'root'
})
export class CreditsAgainstEstateService {

    private endpointCreditsAgainstEstateUrl: string;
    private endpointProceedingCreditsAgainstEstateUrl: string;
    private endpointSupplierCreditsAgainstEstateUrl: string;
    private endpointCreditsAgainstEstateFilesUrl: string;

    constructor(
        private readonly apiProcessor: ApiProcessorService,
        private readonly http: HttpClient,
        private readonly environment: Configuration) {
        this.endpointCreditsAgainstEstateUrl = this.environment.apiUrl + 'treasury-management/credits-against-estate';
        this.endpointProceedingCreditsAgainstEstateUrl = this.environment.apiUrl + 'treasury-management/proceedings';
        this.endpointSupplierCreditsAgainstEstateUrl = this.environment.apiUrl + 'treasury-management/suppliers';
        this.endpointCreditsAgainstEstateFilesUrl = this.environment.apiUrl + 'treasury-management/credits-against-estate-files';
    }

    public getCreditAgainstEstate(creditAgainstEstateId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointCreditsAgainstEstateUrl}/${creditAgainstEstateId}`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getCreditsAgainstEstate(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointProceedingCreditsAgainstEstateUrl}/${proceedingId}/credits-against-estate`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getReducedCreditsAgainstEstateWithPayments(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointProceedingCreditsAgainstEstateUrl}/${proceedingId}/reduced-credits-against-estate-with-payments`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getReducedCreditsAgainstEstate(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointProceedingCreditsAgainstEstateUrl}/${proceedingId}/reduced-credits-against-estate`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getSupplierCreditsAgainstEstate(supplierId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointSupplierCreditsAgainstEstateUrl}/${supplierId}/credits-against-estate`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getSupplierReducedCreditsAgainstEstateWithPayments(supplierId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointSupplierCreditsAgainstEstateUrl}/${supplierId}/reduced-credits-against-estate-with-payments`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getSupplierReducedCreditsAgainstEstate(supplierId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointSupplierCreditsAgainstEstateUrl}/${supplierId}/reduced-credits-against-estate`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public createCreditAgainstEstate(creditAgainstEstate: ICreditAgainstEstate): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = this.endpointCreditsAgainstEstateUrl;
        apiRequest.body = creditAgainstEstate;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public updateCreditAgainstEstate(creditAgainstEstate: ICreditAgainstEstate): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${this.endpointCreditsAgainstEstateUrl}/${creditAgainstEstate.id}`;
        apiRequest.body = creditAgainstEstate;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public deleteCreditAgainstEstate(creditAgainstEstateId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${this.endpointCreditsAgainstEstateUrl}/${creditAgainstEstateId}`;

        return this.apiProcessor.proccessRequest(apiRequest);  
    }

    public deleteCreditsAgainstEstate(creditsAgainstEstateIds: string[]): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = this.endpointCreditsAgainstEstateUrl;
        apiRequest.body = JSON.stringify(creditsAgainstEstateIds);

        return this.apiProcessor.proccessRequest(apiRequest);  
    }

    public getCreditAgainstEstateFiles(creditAgainstEstateId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointCreditsAgainstEstateUrl}/${creditAgainstEstateId}/files`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public downloadCreditAgainstEstateFile(fileName: string, fileId: string): Observable<any> {
        const url = this.getDownlodFileUrl(fileId);
        return this.apiProcessor.downloadFile(url, fileName);
    }

    public getDownlodFileUrl(fileId: string): string{
        let url = this.endpointCreditsAgainstEstateFilesUrl;
        if (!url.endsWith('/')) { 
            url += '/'; 
        }
        url += fileId;
        return url;
    }

    public importCreditsAgainstEstateFile(proceedingId: string, dataFile: File): Observable<any> {
        const endpointUrl = `${ this.endpointProceedingCreditsAgainstEstateUrl }/${ proceedingId }/import-credits-against-estate`;
        const reqBody = new FormData();
        reqBody.append('file', dataFile, dataFile.name);
        return this.http.post(endpointUrl, reqBody, { withCredentials: true });
    }
}
