import { Component, Inject, ViewChild, ViewContainerRef, ComponentRef, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum dialogTypes {
    Info = 'info',
    Success = 'success',
    Confirm = 'confirm',
    DataError = 'dataerror',
    Notice = 'notice',
    Change = 'change'
}

@Component({
    selector: 'app-generic-dialog',
    templateUrl: './generic-dialog.component.html'
})

export class GenericDialogComponent implements OnInit, OnDestroy {

    @ViewChild('dialogbody', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef;

    @Input() public displayCloseOption: boolean;
    @Input() public displayButtonBar: boolean;
    @Input() public hasLogos: boolean;
    @Input() public additionalClassDialog: string;
    @Input() public additionalClass: string;
    @Input() public dialogTitleClass: string;
    @Input() public dialogTitle: string;
    @Input() public dialogBody: string;
    @Input() public dialogButton: string;
    @Input() public dialogCloseButon: string;
    @Input() public dialogMessage: string;
    @Input() public dialogPrintButton: string;
    @Input() public dialogTypes = dialogTypes.Info;
    @Input() public pageReport: string;
    @Input() public pageList: string;

    @Output() public confirmDialogData: EventEmitter<any>;
    @Output() public cancelDialogData: EventEmitter<any>;

    public componentRef: ComponentRef<any>;
    public addClassCloseButon: string;
    public addClassButton: string;
    public addedClass: string;

   constructor(
        public dialogRef: MatDialogRef<GenericDialogComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any)
        {
            this.confirmDialogData = new EventEmitter();
            this.cancelDialogData = new EventEmitter();
        }

    public ngOnInit(): void {
        this.componentRef = this.vcRef.createComponent(this.data.template);
        this.hasLogos = this.data.hasLogos;
        this.additionalClassDialog = this.data.additionalClassDialog;
        this.dialogTitle = this.data.dialogTitle;
        this.dialogTitleClass = this.data.dialogTitleClass;
        this.dialogBody = this.data.dialogBody;
        this.displayCloseOption = this.data.displayCloseOption;
        this.displayButtonBar = this.data.displayButtonBar;
        this.dialogButton = this.data.dialogButton;
        this.dialogCloseButon = this.data.dialogCloseButon;
        this.dialogMessage = this.data.dialogMessage;
        this.dialogPrintButton = this.data.dialogPrintButton;
        this.dialogTypes = this.data.dialogTypes;
        this.pageReport = this.data.pageReport;
        this.pageList = this.data.pageList;
        this.addedClass = this.additionalClass ? undefined : '';
        this.addClassCloseButon = 'dialog-action secondary ' + this.addedClass;
        this.addClassButton = 'dialog-action ' + this.addedClass;
    }
    
    public ngOnDestroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }
    
    public printDialog(): void {
        const appHtml = document.getElementsByClassName('app-main')[0];
        appHtml.setAttribute('style', 'display:none');
        window.print();
        appHtml.removeAttribute('style');
    }

    public closeClick(): void {
        this.dialogRef.close();
        this.confirmDialogData.emit();
    }

    public onConfirm(): void {
        if (this.pageList) {
            this.dialogRef.close({ control: 'pageList' });
        } else {
            this.dialogRef.close(true);
        }
        this.confirmDialogData.emit();


    }
    public onDismiss(): void {
        if (this.pageReport) {
            this.dialogRef.close({ control: 'pageReport' });
        } else {
            this.dialogRef.close(false);
        }
        this.cancelDialogData.emit();
    }

    public get dialogConfig(): string {
        switch (this.dialogTypes) {
            case dialogTypes.Info:
                return 'info';
            case dialogTypes.Success:
                return 'success';
            case dialogTypes.Confirm:
                return 'confirm';
            case dialogTypes.DataError:
            default:
                return '';
        }
    }
    
}
