import { Injectable } from '@angular/core';
import { AuthenticationService } from 'api/authentication-service/authentication.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticatedUserCacheService } from '../authenticated-user-cache/authenticated-user-cache.service';

@Injectable()
export class LoginService {

    public constructor(
        private readonly environment: Configuration,
        private readonly authenticatedUserProvider: AuthenticatedUserCacheService,
        private readonly authenticationService: AuthenticationService) {}

    public returnUrl =  new BehaviorSubject<string>('');
    public get getReturnUrl(): Observable<string> {
        return this.returnUrl.asObservable();
    }

    public isUserLoggedIn(): Observable<boolean> {
        return this.authenticationService.isUserLogged().pipe(
            map(() => true),
            catchError(() => of(false))
        );
    }

    public logout(returnUrl?: string): void {
        let myAccountLogoutReturnUrl = this.environment.clientUrl;
        if (returnUrl) {
            myAccountLogoutReturnUrl = this.environment.clientUrl.concat(returnUrl);
        }
        sessionStorage.clear();
        this.authenticatedUserProvider.clearAuthenticatedUserCache();
        const myAccountLogoutUrl = `${ this.environment.urlMyAccountHome }${ this.environment.myAccountLogoutUrl }?returnUrl=${ myAccountLogoutReturnUrl }`;
        document.location.href = myAccountLogoutUrl;
    }
}
