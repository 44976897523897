import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProceedingLiquidationRequestStatusService {

    private endPointUrl = '';

    public constructor(private readonly apiProcessorService: ApiProcessorService, environment: Configuration) {        
        this.endPointUrl = environment.apiUrl + 'masterdata-management/proceeding-liquidation-request-statuses';
    }

    public getProceedingLiquidationRequestStatuses(): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.endPointUrl;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
