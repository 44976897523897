import { ViewChildren, QueryList, ElementRef, Input, Directive } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Directive()
export class AbstractFormArrayComponentDirective {
    @Input() public type: string;  
    @Input() public parentGroup: UntypedFormGroup;
    @Input() public controlName: string;
    
    @ViewChildren('formInput') formInputs: QueryList<ElementRef>;

    public get childrenControls(): UntypedFormArray {
        return this.parentGroup.get(this.controlName) as UntypedFormArray;
    }
    
    constructor() {}
    
    public focusToFirstErroredField(): void {
        const erroredControl = this.firstErroredInputName;
        if (erroredControl && this.formInputs && this.formInputs.length > 0) {
            const targetControl = this.formInputs.find(
                control => control.nativeElement && control.nativeElement.id === erroredControl
            );
            if (targetControl) {
                this.focusInnerElement(targetControl.nativeElement);  
                return;           
            }     
            const domControl = document.getElementById(erroredControl);

            if (domControl) {
                this.focusInnerElement(domControl);
            }     
        }
    }

    private get firstErroredInputName(): string {
        const erroredControls = [];
        this.childrenControls.controls.forEach((formGroup: UntypedFormGroup, index: number) => {            
            for (const control in formGroup.controls){
                if (formGroup.controls[control].invalid){
                    erroredControls.push(control + this.controlName + index);
                }
            }           
        });
        
        if (erroredControls.length > 0) {
            return erroredControls[0];
        }
        
        return '';
    }

    private focusInnerElement(element: HTMLElement): void {        
        element.click();
        element.focus();
    }
}
