import { Component, Input } from '@angular/core';

export interface ITotalsListElement {
    label: string;
    value: number;
}

@Component({
    selector: 'gg-list-totals',
    templateUrl: './gg-list-totals.component.html',
    styleUrls: ['./gg-list-totals.component.scss']
})
export class ListTotalesComponent {

    @Input() public totalsList: ITotalsListElement[];
    @Input() public totalsListTitle: string;
    @Input() public displayTotalsTitle = true;
   
}

