import { IFilterOptions } from 'app/components/selection-list/selection-list.component';

export class CreditorListHelper {
    public customSearchIsExcludedFilter(): (searchTerm: Array<any>, dataObj: any, key: string) => boolean {
        return (searchTerm: Array<any>, dataObj: any, key: string): boolean => {
            return dataObj && searchTerm.length > 0 && key === 'isExcluded' ?
            searchTerm.some(sT => (sT === 1 && !dataObj.isExcluded)) ||
            searchTerm.some(sT => (sT === 2 && dataObj.isExcluded)) :
            true;
        };
    }

    public filterCustomGetIsExcluded(obj: any, key: string): any {
        if (!obj || key !== 'isExcluded') {
            return 0;
        }
        if (obj.isExcluded) {
            return 2;
        }
        else {
            return 1;
        }
    }

    public getDefaultFilterOptions(): IFilterOptions { 
        return {
        options: this.getOptionsIsExcluded(), 
        optionsByInput: true, 
        customSearch: this.customSearchIsExcludedFilter().bind(this),
        customGetValue: this.filterCustomGetIsExcluded };
    }  

    public getOptionsIsExcluded(): Array<{viewValue: string, value: any, checked: boolean}> {
        return [
            {viewValue: 'Acreedores sin excluir', value: 1, checked: false},
            {viewValue: 'Acreedores excluidos', value: 2, checked: false}
        ];
    }
}
