import { AssetPhotosConfig } from './asset-photos-config.model';
import { FooterLinks } from './footer-links.model';
import { Version } from './version.model';

export class Configuration {
    public production: boolean;
    public hmr: boolean;
    public clientUrl: string;
    public apiUrl: string;
    public urlMyAccountHome: string;
    public myAccountLoginScriptLocation: string;    
    public myAccountExternalLoginWithoutRegisterUrl: string;
    public myAccountExternalLoginUrl: string;
    public myAccountLogoutUrl: string;
    public myAccountLicensesAdministrationUrl: string;
    public myAccountLoginSamlUrl: string;
    public contentSiteUrl: string;
    public tarificCalculatorSiteUrl: string;
    public Trlc2020Url: string;
    public Lc2003Url: string;
    public LcVSTrLcUrl: string;
    public contactFormScript: string;
    public contactForm: string;
    public auctionsUrl: string;
    public auctionsForm: string;
    public contactMail: string;
    public contactMailBankinter: string;
    public googleAnalytics: string;
    public googleAnalyticsLanding: string;
    public angularCheckUpdateHour: number;
    public typeACIrpf: any;
    public trainingSiteUrl: string;
    public allowExternalCreditValidation: boolean;
    public cookiePoliciesUrl: string;
    public cookieEloquaUrl: string;
    public includeEloquaCookie: boolean;
    public liquidationsVideoUrl: string;
    public auctionsPdfUrl: string;
    public footerLinks: FooterLinks;
    public urlAgenciaTributria: string;
    public urlSeguridadSocial: string;
    public urlRegistroPropiedad: string;
    public urlRegistroPublicoConcursal: string;
    public urlIcam: string;
    public playerVimeoUrl: string;
    public cadastralUrl: string;
    public cadastraVizcayaUrl: string;
    public cadastraAlavaUrl: string;
    public cadastraGuipuzcoaUrl: string;
    public cadastraNavarraUrl: string;
    public assetPhotos: AssetPhotosConfig;
    public version: Version;
    public customerNotificationConfig: string;
    public customerNotificationFunction: string;
}
