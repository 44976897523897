import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-generic-files-modal',
    templateUrl: './generic-attached-files-modal.component.html'
})
export class GenericAttachedFilesModalComponent {
    public navigateToEntity: boolean;
    public entityLabel: string;
    public filesAttached: string;
    public entityName: string;
    private entityUrl: string;
    private entityId: string;

    public constructor(
        private readonly router: Router,
        public dialogRef: MatDialogRef<GenericAttachedFilesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.navigateToEntity = data.navigateToEntity;
        this.entityLabel = data.entityLabel;
        this.filesAttached = data.filesAttached;
        this.entityUrl = data.entityUrl;
        this.entityId = data.entityId;
        this.downloadFile = data.downloadFile;
        this.entityName = data.entityName;
    }

    public downloadFile: (file: any) => void;

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public goToEntity(): void {
        this.dialogRef.close();
        this.router.navigate([this.entityUrl, this.entityId]);
    }
}
