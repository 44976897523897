import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/services/login/login.service';
import { AuthenticatedUserCacheService } from 'app/services/authenticated-user-cache/authenticated-user-cache.service';
import { ILoggedUserInfo } from 'api/authentication-service/logged-user-info.model';
import { IUserType, UserTypes } from 'api/user-service/user.type.model';
import { Configuration } from 'api/configuration-service/configuration.model';
import { BrowserInfo, detectBrowserInfo, getScreenResolution, ScreenResolution } from 'app/utils/browser-utils';

@Component({
    selector: 'gg-about',
    templateUrl: './gg-about.component.html',
    styleUrls: ['./gg-about.component.scss']
})
export class AboutComponent implements OnInit {

    public versionNumber: string;
    public buildNumber: string;
    public buildDate: string;
    public userAndLoginType: string;
    public browserName: string;
    public browserVersion: string;
    public platformName: string;
    public screenResolution: string;
    
    public constructor(
        private readonly loginService: LoginService,
        private readonly authenticatedUserProvider: AuthenticatedUserCacheService,
        private readonly environment: Configuration) { }

    public async ngOnInit(): Promise<void> {        
        const versionInformation = this.environment.version;
        if (versionInformation) {
            const { versionNumber, buildNumber, buildDate } = versionInformation;
            this.versionNumber = versionNumber;
            this.buildNumber = buildNumber;
            this.buildDate = buildDate;
        }

        this.userAndLoginType = await this.getUserAndLoginType();

        const browserInfo: BrowserInfo = detectBrowserInfo();
        if (browserInfo) {
            const { name, version, os } = browserInfo;
            this.browserName = name;
            this.browserVersion = version;
            this.platformName = os;
        }

        const screenResolution: ScreenResolution = getScreenResolution();
        const { width, height } = screenResolution;
        this.screenResolution = `${width}x${height}`;
    }

    private async getUserAndLoginType(): Promise<string> {
        const isUserLoggedIn = await this.loginService.isUserLoggedIn().toPromise();
        if (!isUserLoggedIn) {
            return 'Usuario no logado';
        }

        let userEmail = 'Usuario desconocido';
        const userInfo: ILoggedUserInfo = await this.authenticatedUserProvider.getLoggedUserInfo();
        if (!!userInfo) {
            userEmail = userInfo.email;
        }

        let userTypeDescription = '';
        const userType: IUserType = await this.authenticatedUserProvider.getCurrentUserType();
        const { code } = userType;
        switch (code) {
            case UserTypes.BankruptcyAdministrator:
                userTypeDescription = 'Administrador consursal';
                break;
            case UserTypes.Creditor:
                userTypeDescription = 'Acreedor';
                break;
            default:
                userTypeDescription = 'Tipo de usuario desconocido';
                break;
        }

        return `${userEmail} (${userTypeDescription})`;
    }
}
