import { Injectable } from '@angular/core';
import { Configuration } from 'api/configuration-service/configuration.model';

declare var loadQuantcast: any;

@Injectable({
  providedIn: 'root'
})
export class CookiePoliciesService {
  private script: HTMLScriptElement; 

  constructor(private readonly environment: Configuration) { }
  
  public init(): void {
    this.script = document.createElement('script');
    this.script.onload = () => { loadQuantcast(); };
    this.script.src = this.environment.cookiePoliciesUrl;
    this.script.async = true;
    this.script.defer = true;
    document.body.appendChild(this.script);
  }
}
