import { Component, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GenericDialogComponent } from 'app/components/generic-dialog/generic-dialog.component';

export interface IGalleryItem {
    id: string;
    url?: string | SafeResourceUrl;
    label?: string;
    type?: string;
    principal?: boolean;
}

@Component({
    selector: 'gg-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent  {
    
    @Input() public principalImage: string;
    @Input() public galleryItems: IGalleryItem[];

    public gallerySource: IGalleryItem[];
    
    public width = '100%';
    public height = '100%';
    public arrows = true;
    public pageable = true;
    public pagerOverlay = 'light';
    public activeIndex: number;
    public selectedIndex: number;
    public isFullScreen = false;
    
    constructor(
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data,
    )
    {
        this.gallerySource = data.gallerySource;
        this.isFullScreen = data.isFullScreen;
        this.activeIndex = data.activeIndex;
    }
    
    public onItemChange(e): void {
        this.selectedIndex = e.index;
    }

    public onTabChange(tabChangeEvent: MatTabChangeEvent): void {
        this.activeIndex  = tabChangeEvent.index;
    }


    public onZoomImage(e, isFullScreen: boolean): void {
        if (!isFullScreen) {
            this.dialog.open(GenericDialogComponent, {
                data: {
                    template: GalleryComponent,
                    isFullScreen: true,
                    activeIndex: this.activeIndex,
                    gallerySource: this.gallerySource,
                    additionalClassDialog: 'transparent',
                    displayCloseOption: true

                },
                disableClose: true,
                panelClass: 'cdk-overlay-transparent',
                backdropClass: 'cdk-overlay-backdrop-dark',
                maxWidth: '100vw',
                maxHeight: '100vh',
                minWidth: '100vw',
                minHeight: '100vh',
            });
        }
    }

}
