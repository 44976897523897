import { Component, Inject, Input} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'gg-info',
    templateUrl: './gg-info.component.html',
    styleUrls: ['./gg-info.component.scss']
})

export class InfoComponent {

    @Input() public icon: string;
    @Input() public title: string;
    @Input() public subTitle: string;
    @Input() public message: any;
    @Input() public filename: string;
    @Input() public isLoading: boolean;
    @Input() public isDataError: boolean;
    @Input() public isConfirm: boolean;
    @Input() public numType: number;
    @Input() public strType: string;
    @Input() public extraText: string;
    @Input() public loadingText: string;

    constructor(        
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.icon = data.icon;
        this.title = data.title;
        this.subTitle = data.subTitle;
        this.message = data.message;
        this.filename = data.filename;
        this.isLoading = data.isLoading;
        this.isConfirm = data.isConfirm;
        this.isDataError = data.isDataError;
        this.numType = data.numType;
        this.strType = data.strType;
        this.extraText = data.extraText;
        this.loadingText = data.loadingText;
    }
    
}
