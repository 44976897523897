export enum UserTypes {
    BankruptcyAdministrator = 1,
    Creditor = 2
}

export interface IUserType {
    id: string;
    code: number;
    name: string;
}
