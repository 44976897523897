import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Observable, of } from 'rxjs';

@Injectable()
export class AllowExternalCreditValidationGuard implements CanActivate {
    public constructor(
        private readonly router: Router,
        private readonly environment: Configuration) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const allowedUrlTree = this.router.parseUrl('/dentix');

        if (this.environment.allowExternalCreditValidation) {
            return of(allowedUrlTree);
        }

        return of(true);
    }

}
