import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'api/authentication-service/authentication.service';
import { ILoggedUserInfo } from 'api/authentication-service/logged-user-info.model';
import { Configuration } from 'api/configuration-service/configuration.model';
import { ICreditor } from 'api/creditors-service/creditor.model';
import { CreditorsService } from 'api/creditors-service/creditors.service';
import { IUser } from 'api/user-service/user.model';
import { UserService } from 'api/user-service/user.service';
import { UserTypes } from 'api/user-service/user.type.model';
import { GiocondaGenericDialogComponent } from 'app/components/gg-generic-dialog/gg-generic-dialog.component';
import { PublicLinksButtonsService } from 'app/services/public-links-buttons/public-links-buttons.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LoginService } from 'app/services/login/login.service';
import { AuthenticatedUserCacheService } from 'app/services/authenticated-user-cache/authenticated-user-cache.service';
import { SamlClientService } from 'app/services/saml-client-service/saml-client.service';

@Component({
    selector: 'gg-account-button',
    templateUrl: './gg-account-button.component.html'
})

export class AccountButtonComponent implements OnInit {           
    @Input() public name: string;
    @Input() public surname: string;
    @Input() public email: string;
    @Input() public isAdmin: boolean;     
    @Input() public hasUserRights: boolean;   
    @Input() public isUserLogged: boolean;    
    @Input() public isPublicPage: boolean;
    @Input() public userTypeCode: number;
    @Input() public showInsolvencyPracticeButton: boolean;
    @Input() public showTrainingButton: boolean;

    @Output() public accessButtonClicked: EventEmitter<void>;
    
    @ViewChild('dialogUrl', { static: true }) public dialogUrl: GiocondaGenericDialogComponent;

    public readonly URL_TRLC_2020: string;
    public readonly URL_LCVSTRLC: string;

    public urlSite: any;
    
    public constructor(
        private router: Router, 
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UserService,
        private readonly authenticatedUserProvider: AuthenticatedUserCacheService,
        private readonly loginService: LoginService,
        private readonly creditorService: CreditorsService,
        private readonly samlClientService: SamlClientService,
        public readonly environment: Configuration,
        protected readonly publicLinksbuttons: PublicLinksButtonsService
    ) {
        this.accessButtonClicked = new EventEmitter();
        this.URL_TRLC_2020 = this.environment.contentSiteUrl + this.environment.Trlc2020Url;
        this.URL_LCVSTRLC = this.environment.contentSiteUrl + this.environment.LcVSTrLcUrl;
        
        const samlClientIdQueryParam = this.samlClientService.getSamlClientId();
        if (samlClientIdQueryParam) {
            this.URL_TRLC_2020 = this.samlClientService.appendSamlClientIdAsQueryParameter(this.URL_TRLC_2020);
            this.URL_LCVSTRLC += this.samlClientService.appendSamlClientIdAsQueryParameter(this.URL_LCVSTRLC);
        }
    }

    public ngOnInit(): void {
        this.initialize();
    }

    private initialize(): void {
        this.authenticationService.getLoggedUserInfo()
            .pipe(
                map((loggedUserInfo: ILoggedUserInfo) => {
                    if (!loggedUserInfo) {
                        return of(undefined);
                    }

                    this.isUserLogged = true;
                    this.setLoggedUserInfo(loggedUserInfo);
                    return of();
                }),
                mergeMap(() => this.userService.synchronizeUser()),                                    
                mergeMap(() => this.userService.getUsersByEmail(this.email))
            ).subscribe((users: IUser[]) => {
                this.userTypeCode = users[0].userType.code;
                this.authenticatedUserProvider.setCurrentUserType(users[0].userType);
                if (this.userTypeCode === UserTypes.Creditor) {
                    this.creditorService.getCreditorsByUserId().pipe(
                        map((creditors: Array<ICreditor>) => creditors.length > 0),
                        catchError(() => of(false)))
                    .subscribe((hasCreditors: boolean) => {
                        if (!hasCreditors) {
                            this.router.navigate(['/public/unauthorized'], {queryParams: {type : '3'}});
                        }
                    });
                }
            }, () => {
                this.hasUserRights = false;
                this.isAdmin = false;
            });
    }

    public logout(): void {
        this.loginService.logout();
    }

    public navigateToMyAccount(): void {
        const myAccountUrl = `${ this.environment.urlMyAccountHome }`;
        window.open(myAccountUrl);
    }

    public navigateToLicensesAdministration(): void {
        const myAccountLicensesUrl = `${ this.environment.urlMyAccountHome }${ this.environment.myAccountLicensesAdministrationUrl }`;
        window.open(myAccountLicensesUrl);
    }

    public IsBankruptcyAdministrator(): boolean {
        if (this.userTypeCode){
            return this.userTypeCode === UserTypes.BankruptcyAdministrator;
        }

        return false;
    }

    public IsCreditor(): boolean {
        if (this.userTypeCode){
            return this.userTypeCode === UserTypes.Creditor;
        }

        return false;
    }

    public goToTraining(): void {
        this.router.navigate(['/manager/training']);
    }

    public goToLink(link: any): void {
        this.publicLinksbuttons.goToLink(link);
    }

    public openDialog(link: any): void {
        this.urlSite = link; 
        this.dialogUrl.openDialog({
            panelClass: 'cdk-overlay-big-modal',
            backdropClass: 'cdk-overlay-backdrop-dark'
        });

    }

    private setLoggedUserInfo(loggedUserInfo: ILoggedUserInfo): void {
        this.email = loggedUserInfo.email;
        this.name = loggedUserInfo.name;
        this.surname = loggedUserInfo.surname;
        this.isAdmin = loggedUserInfo.isAdmin;
        this.hasUserRights = loggedUserInfo.hasUserRights;
    }
}
