import { Directive, HostListener, ElementRef, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[numberWithSeparators]'
})
export class CurrencyDirective implements OnInit {
    @Input() public currency = '';
    @Input() public decimalLenght = 2;
    @Input() public allowNegatives = true;
    @Input() public isReadOnly = false;    
    @Input() public allowZeroValue = false;
    @Input() public allowEmptyValue = false;

    private decimalSeparator = ',';
    private numberSeparator = '.';
    private oldValue = '';
    private keyPressed: any;
    private supKeyValue = 46;
    private isFirstLoad = true;

    private readonly nonPrintableKeys = ['Shift', 'Escape', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter', 'Control', 'Tab'];
    private readonly numericSequenceRegex = /[0-9]|\-|\,/;
    
    constructor(private _elemRef: ElementRef, private changeDetectorRef: ChangeDetectorRef, private control: NgControl) {}     
      
    public ngOnInit(): void {
        const abstractControl = this.control.control;
        if (abstractControl && abstractControl.value) {
            this.onInputChange(abstractControl.value);
        }
    }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event: string): void {
        if (typeof (event) !== 'undefined') {
            this.onInputChange(event);
        }
    }

    @HostListener('focus', ['$event'])
    onFocus(event: any): void {
        const isEmptyValue = event.target.value === '0' + this.currency || event.target.value === '0,00' + this.currency || event.target.value === '0,00 €';
        if (isEmptyValue && !this.isReadOnly && !this.allowZeroValue) {
            if (this.oldValue === '') {
                this.oldValue = event.target.value;
            }
            this._elemRef.nativeElement.value = '';
        }
    } 

    @HostListener('blur', ['$event.target.value']) 
    onBlur(event: any): void {        
        const value = this._elemRef.nativeElement.value;

        if (this.isEmptyValue(value) && this.allowEmptyValue) {
            return;
        }

        let valueDecimals = this.SetDecimals(event);

        if (!this.allowZeroValue) {
            valueDecimals = this.RemoveInitialZero(valueDecimals);
        }
        
        if (value !== valueDecimals){
            this._elemRef.nativeElement.value = valueDecimals;
            this.reportValueHasChanged();
        }
    }

    @HostListener('keydown', ['$event']) 
    onKeyDown(e: KeyboardEvent): any {
        if (this.decimalLenght === 0 && !this.allowNegatives)
        {
            return this.checkIntegerNumber(e);
            
        }
        if (this.nonPrintableKeys.findIndex(val => val === e.key) !== -1) {
            return e;
        }

        if (e.key === 'Delete')
        {
            if (this._elemRef.nativeElement.value.substring(this._elemRef.nativeElement.selectionStart, this._elemRef.nativeElement.selectionStart + 1) === '.'){
                const position = this._elemRef.nativeElement.selectionStart + 1;
                this._elemRef.nativeElement.setSelectionRange(position, position);
                e.preventDefault();
                e.stopPropagation();
                return false;
            }     
            return e;
        }
        else if (e.key ===  'Backspace')
        {
            if (this._elemRef.nativeElement.selectionStart === this._elemRef.nativeElement.value.length && 
                this._elemRef.nativeElement.value.substring(this._elemRef.nativeElement.selectionStart - 1, this._elemRef.nativeElement.selectionStart) === this.currency.trim() ){
                
                const position = this._elemRef.nativeElement.selectionStart - this.currency.length ;
                this._elemRef.nativeElement.setSelectionRange(position, position);
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            return e;
        }


        const invalidNegativeOrSequence =
            !this.numericSequenceRegex.test(e.key) ||
            !this.allowNegatives && e.key === '-';

        if (invalidNegativeOrSequence) {
            return false;
        }

        this.keyPressed = e;
        this.isFirstLoad = false;
        if (this.oldValue === ''){
            this.oldValue = this._elemRef.nativeElement.value;
        }

        return e;
    }

    @HostListener('cut', ['$event'])
    handleCut(): void {  
        this.oldValue = '';
    }

    private checkIntegerNumber(event: any): any{
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96  || charCode > 106)) {
            return false;
        }
        return event;
    }

    private onInputChange(event: string): void {  
   
        if (event === null || typeof(event) === 'undefined') { return; }

        event = event.toString().trim();
        const isNegative = event.startsWith('-');
        
        if (this.currency.length !== 0){
            event = event.replace(this.currency, '');
        }
        if (isNegative){
            event = event.replace('-', '');
        }
        let mousePosition = this._elemRef.nativeElement.selectionStart;
        const setPosition = this._elemRef.nativeElement.selectionStart !== this._elemRef.nativeElement.value.length;
        
        if (this.keyPressed !== undefined && !this.IsNumberOrSpecialChar(this.keyPressed)) {
            event = this.oldValue;
        }

        let head = '';
        let value = this.ParseValue(event);
        
        const commaIndex = value.search(this.decimalSeparator);
        let tail = '';
        let commaValue = '';

        if (commaIndex === -1){
            tail = value.substring(value.length % 3);
        }
        else{
            commaValue = value.substring(commaIndex, commaIndex + this.decimalLenght + 1);
            value = value.substring(0, commaIndex);
            tail = value.substring(value.length % 3, commaIndex);
        }

        head = value.substring(0, value.length % 3);
        
        if (tail !== '' && head !== '' && value.search(/^([0-9]{3})([0-9]{3})/) === -1 ){
            head = head + this.numberSeparator;
        }
        
        while (tail !== '' && tail.search(/^([0-9]{3})([0-9]{3})/) > -1 ){
            tail = tail.replace(/^([0-9]{3})([0-9]{3})/, '$1.$2');
            head = head !== '' && head[head.length - 1] !== this.numberSeparator ? head + this.numberSeparator + tail.substring(0, 4) : head + tail.substring(0, 4);
            tail = tail.substring(4);
        }

        let newValue = head + tail + commaValue;
        if (isNegative && this.allowNegatives)
        {
            newValue = '-' + newValue;
        }
        this._elemRef.nativeElement.value = newValue + this.currency;
        
        if (setPosition){
            mousePosition = this.SetMousePosition(newValue, mousePosition);
            this._elemRef.nativeElement.focus();
            this._elemRef.nativeElement.setSelectionRange(mousePosition, mousePosition);
        }

        if ( this.oldValue !== newValue){
            
            this.oldValue = newValue;
            this.reportValueHasChanged();
        }

        this.changeDetectorRef.detectChanges();
    }

    private IsNumberOrSpecialChar(evt: any): boolean{ 
        if (evt === undefined || evt === null){ 
            return false;
        }
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode >= 96 && charCode <= 106) || charCode === 188 || charCode === 109 )
        {
            return true;
        }
        else if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)){
            return false;
        }
        
        return true;
    }
    
    private SetMousePosition(newValue: string, mousePosition: number): number
    {
        if (this.keyPressed !== undefined){
            if (this.IsNumberOrSpecialChar(this.keyPressed)) {
                if (newValue === this.oldValue && this.keyPressed.keyCode === this.supKeyValue) {
                    mousePosition += 1;
                }
            }
            else
            {
                mousePosition -= 1;
            }
            const newValuePoints = newValue.split('.');
            const oldValuePoints = this.oldValue.split('.');
    
            if (newValuePoints.length > oldValuePoints.length){
                mousePosition += 1;
            }else if (newValuePoints.length < oldValuePoints.length && mousePosition !== 0){
                mousePosition -= 1;
            }
        }
        return mousePosition;
    }

    private RemoveInitialZero(value: string): string {
        if (value === '') { return value; }
        while (value.startsWith('.') || (value.startsWith('0') && !value.startsWith('0,')) ){
            value = value.substring(1 , value.length);
        }

        return value;
    }
    
    private SetDecimals(value: string): string {
        if (this.decimalLenght === 0) {
            return value;
        }

        if (this.currency.length !== 0){
            value = value.replace(this.currency, '');
        }
        if (value === ''){
            value = '0' + this.decimalSeparator + '00';
        }
        else if (value.indexOf(this.decimalSeparator) === -1){
            value += this.decimalSeparator + '00';
        }else{
            const numb = value.split(this.decimalSeparator);
           
            switch (numb[1].length){
                case 0:
                    value += '00';
                    break;
                case 1:
                    value += '0';
                    break;
            }
        }

        if (this.currency.length !== 0){
            value += this.currency;
        }
        return value;
    }

    private ParseValue(value: string): string{
        if (this.isFirstLoad){
            value = value.replace('.', ',');
            value = this.SetDecimals(value);
            this.isFirstLoad = false;
        }else{
            value = value.replace(/\./g, '');
        }
        
        value = this.parseCommas(value);
        const match = value.match(/[0-9]|\.|\,/g);
        return value === '' || match === null ?  '' : match.join('');
    }

    private parseCommas(value: string): string{
        const lastComma = value.lastIndexOf(',');
        if (lastComma !== -1){
            const head = value.substring(0, lastComma);
            const tail = value.substring(lastComma);
            value = head.replace(',', '') + tail;
        }
        return value;
    }

    private reportValueHasChanged(): void
    {
        const eventChangeValue: Event = document.createEvent('Event');
        eventChangeValue.initEvent('input', true, true);
        Object.defineProperty(eventChangeValue, 'target', {value: this._elemRef.nativeElement, enumerable: true});        
        this._elemRef.nativeElement['dispatchEvent'].apply(this._elemRef.nativeElement, [eventChangeValue]);
    }

    private isEmptyValue(value: any): boolean {
        return value === null || typeof(value) === 'undefined' || value === '';
    }
}
