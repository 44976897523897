import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject as BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface ITab {
    label: string;
    link: string;
}

@Component({
    selector: 'gg-mat-tab-group',
    templateUrl: './mat-tab-group.component.html'
})
export class MatTabGroupComponent implements OnInit, OnDestroy {

    public _tabs: ITab[];
    public selectedTabIndex: number;

    @Input() public set tabs(value: Array<ITab>) {
        if (value) {
            this._tabs = value;
            this.selectTab(this.router.url);
        }
    }

    @ViewChild('tabgroup', { static: false }) public set matTabGroup(value: MatTabGroup) {
        if (value) {
            this.innerTabGroup = value;
            this.isInnerTabGroupSetted = true;
            this.initializationSubject.next();
        }
    }
    
    private innerTabGroup: MatTabGroup;
    private isInnerTabGroupSetted = false;
    private isSelectedIndexSetted = false;
    private initializationSubject: BehaviorSubject<void>;
    private initializationSubscription: Subscription;

    public constructor(
        private readonly router: Router) {

        this.initializationSubject = new BehaviorSubject<void>();
        this.router.events
            .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
            .subscribe((event: RouterEvent) => {
                this.selectTab(event.url);
            });
    }

    public ngOnInit(): void {
        this.initializationSubscription = this.initializationSubject.subscribe(() => {
            if (this.isSelectedIndexSetted && this.isInnerTabGroupSetted) {
                this.innerTabGroup.selectedIndex = this.selectedTabIndex;
            }
        });

        this.selectTab(this.router.url);
    }
    
    public ngOnDestroy(): void {
        if (this.initializationSubscription) {
            this.initializationSubscription.unsubscribe();
            this.initializationSubject.complete();
        }
    }

    private selectTab(url: string): void {
        const tabFound: ITab = this._tabs.find(tab => url.includes(tab.link));

        if (tabFound) {
            const index = this._tabs.indexOf(tabFound);
            this.selectedTabIndex = index;
            this.isSelectedIndexSetted = true;
            this.initializationSubject.next();
        }
    }
}
