import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { AbstractFormArrayComponentDirective } from 'app/components/abstract-form/abstract-form-array.component';
import { RegexService } from 'app/services/regex-service/regex.service';

@Component({
  selector: 'gg-form-accounting-account',
  templateUrl: './gg-form-accounting-account.component.html'
})
export class FormAccountingAccountComponent extends AbstractFormArrayComponentDirective implements OnInit {
    @Output() public focusOutOfInput = new EventEmitter<{accountingAccount: string, formArrayIndex: number}>();
    
    private get accountingAccountValidators(): ValidatorFn | ValidatorFn[] {
        return [Validators.maxLength(15), Validators.minLength(4),
                Validators.pattern(this.regexService.ACCOUNTING_ACCOUNT())];
    }

    private initialAccountingAccounts = new Array<string>();
    private initialFocusValue: string;

    public constructor(private readonly regexService: RegexService) {
        super();
    }

    ngOnInit(): void {
        this.childrenControls.push(new UntypedFormControl('', this.accountingAccountValidators));
    }

    public add(): void {
        const indexes = new Array<number>();
        this.childrenControls.controls.forEach((control: AbstractControl, i: number) => {
            if (control.hasError('existing')) {
                indexes.push(i);
            }
        });

        this.childrenControls.push(new UntypedFormControl('', this.accountingAccountValidators));
        indexes.forEach(index => {
            this.childrenControls.controls[index].setErrors({existing: true});
        });
        
        this.childrenControls.updateValueAndValidity();
    }

    public remove(index: number): void {
        const formControlToDelete: AbstractControl = this.childrenControls.controls[index];
        const controlsFound = this.childrenControls.controls
                .filter((control: AbstractControl) => control.value === formControlToDelete.value);

        this.childrenControls.removeAt(index);
        if (controlsFound.length > 1 && controlsFound.length > controlsFound.filter((control: AbstractControl) => control.hasError('existing')).length) {
            const controlFound = this.childrenControls.controls.find((control: AbstractControl) => control.value === formControlToDelete.value);
            controlFound.setErrors({existing: false});
            controlFound.updateValueAndValidity();
        }
    }
    
    public notifyFocusout(inputValue: string, index: number): void {
        const currentFormControl: AbstractControl = this.childrenControls.controls[index];

        if (this.initialAccountingAccounts.length > 0 && !currentFormControl.hasError('existing')) {
            this.initialAccountingAccounts.forEach((accountingAccount: string) => {
                const controlFound = this.childrenControls.controls.
                        find((control: AbstractControl, i: number) => control.value === accountingAccount && i !== index && control.value !== inputValue);

                if (controlFound && controlFound.hasError('existing')) {
                    controlFound.setErrors({existing: false});
                    controlFound.updateValueAndValidity();
                }
            });            
        }
    
        if (inputValue !== this.initialFocusValue) {
            const controlsFound = this.childrenControls.controls.filter(c => c.value === this.initialFocusValue);
            if (controlsFound && controlsFound.length > 0) {
                controlsFound[0].setErrors({existing: false});
                controlsFound[0].updateValueAndValidity();
            }          
        }
        
        if (this.childrenControls.controls.some((control: UntypedFormControl, i: number) => 
            control.value === inputValue && index !== i && !control.errors)) {
            currentFormControl.setErrors({existing: true});          
        }
        
        this.focusOutOfInput.emit({accountingAccount: inputValue, formArrayIndex: index});
    }

    public onFocus(value: string): void {
        this.initialFocusValue = value;        
    }

    public setExistingAccountingAccountError(index: number): void {
        this.childrenControls.controls[index].setErrors({existing: true});
    }

    public setAccountingAccounts(accountingAccounts: string[]): void {
        const items = new UntypedFormArray([]);
        for (const accountingAccount of accountingAccounts.filter(ac => ac !== undefined && ac.trim() !== '')) {
            items.push(new UntypedFormControl(accountingAccount, this.accountingAccountValidators));
            this.initialAccountingAccounts.push(accountingAccount);
        }

        if (items.length > 0) {
            this.parentGroup.setControl(this.controlName, items);
        }
    }    
}
