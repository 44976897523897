import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
    private endpointUrl: string;
    
    constructor(private readonly apiProcessor: ApiProcessorService, private readonly environment: Configuration) {
        
        this.endpointUrl = `${ this.environment.apiUrl }authentication`;
    }

    public isUserLogged(): Observable<any> {
        const request = new ApiRequest();
        request.operation = ApiOperation.GET;
        request.endpointUrl = this.endpointUrl;

        return this.apiProcessor.proccessRequest(request);
    }
    
    public getLoggedUserInfo(): Observable<any> {
        const request = new ApiRequest();
        request.operation = ApiOperation.GET;
        request.endpointUrl = `${ this.endpointUrl }/user-info`;

        return this.apiProcessor.proccessRequest(request)
            .pipe(
                map((response: any) => {
                    if (response) {
                        return response;
                    }
                    return of(undefined);
                }),
                catchError(() => of(undefined))
            );
    }
}
