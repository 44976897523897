import { ChangeDetectorRef, Component,  Input,  OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RegexService } from 'app/services/regex-service/regex.service';
import { Subscription } from 'rxjs';
import { AbstractFormArrayComponentDirective } from '../abstract-form/abstract-form-array.component';
import { DecimalPipe } from '@angular/common';
import { ITreasuryMovementType } from './treasury-movement-type.model';
import { ITreasuryMovementState } from './treasury-movement-state.model';
import { ITreasuryMovement } from './treasury-movement.model';
import { ITreasuryMovementBankAccount } from 'api/treasury/movements-bank-account.model';

@Component({
    selector: 'gg-form-treasury-movement',
    templateUrl: './gg-form-treasury-movement.component.html',
    styleUrls: ['./gg-form-treasury-movement.component.scss']
})
export class FormTreasuryMovementComponent extends AbstractFormArrayComponentDirective implements OnDestroy {
    @Input() public associatedEntityId: string;
    @Input() public entityViewName: string;
    @Input() public associatedEntityViewName: string;
    @Input() public treasuryMovementTypes: ITreasuryMovementType[];
    @Input() public treasuryMovementStates: ITreasuryMovementState[];

    private queryListSubscriber: Subscription;
    private EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
    public bankAccounts: ITreasuryMovementBankAccount[];
    public isBankAccountsLoaded: boolean;

    constructor(
        private readonly regex: RegexService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly decimalPipe: DecimalPipe
    ) {
        super();
    }

    public ngOnDestroy(): void {
        if (this.queryListSubscriber) {
            this.queryListSubscriber.unsubscribe();
        }
    }

    public add(): void {
        this.childrenControls.push(new UntypedFormGroup({
            id: new UntypedFormControl(this.EMPTY_GUID, [Validators.maxLength(100)]),
            treasuryMovementDate: new UntypedFormControl('', [Validators.required]),
            amount: new UntypedFormControl('', [Validators.required, Validators.maxLength(100), Validators.pattern(this.regex.EURO_REGEX())]),
            typeId: new UntypedFormControl('', [Validators.required]),
            stateId: new UntypedFormControl('', [Validators.required]),
            treasuryMovementId: new UntypedFormControl(this.associatedEntityId, [Validators.required])
        }));
        this.changeDetector.detectChanges();
    }

    public remove(index: number): void {
        this.childrenControls.removeAt(index);
        this.changeDetector.detectChanges();
    }

    public setTreasuryMovements(treasuryMovements: ITreasuryMovement[]): UntypedFormArray {
        const items = new UntypedFormArray([]);
        for (let index = 0; index < treasuryMovements.length; index++) {
            const item = treasuryMovements[index];
            const itemAmount = this.decimalPipe.transform(item.amount, '1.2-2');
            items.push(new UntypedFormGroup({
                id: new UntypedFormControl(item.id, [Validators.maxLength(100)]),
                treasuryMovementDate: new UntypedFormControl(item.treasuryMovementDate, [Validators.required]),
                amount: new UntypedFormControl(itemAmount, [Validators.required, Validators.maxLength(100), Validators.pattern(this.regex.EURO_REGEX())]),
                typeId: new UntypedFormControl(item.type.id, [Validators.required]),
                stateId: new UntypedFormControl(item.state.id, [Validators.required]),
                treasuryMovementId: new UntypedFormControl(this.associatedEntityId, [Validators.required])
            }));

            this.parentGroup.setControl(this.controlName, items);
            this.checkTreasuryMovementType(item.type.id, index, item.bankAccountId);
        }

        this.changeDetector.detectChanges();
        return items;
    }

    public checkTreasuryMovementType(treasuryMovementTypeId: string, formControlIndex: number, bankAccountId?: string): void{
        if (this.treasuryMovementTypeRequiresIban(treasuryMovementTypeId) && !this.childrenControls.controls[formControlIndex].get('bankAccountId')){
            const bankAccountLength = this.bankAccounts ? this.bankAccounts.length : 0;
            const defaultAccountId = bankAccountLength === 0 ? '' : this.bankAccounts.find(ba => ba.isDefaultAccount).id;
            (this.childrenControls.controls[formControlIndex] as UntypedFormGroup)
                .addControl('bankAccountId', new UntypedFormControl(bankAccountId ? bankAccountId : defaultAccountId, [Validators.maxLength(100)]));
            this.childrenControls.controls[formControlIndex].get('bankAccountId').markAsTouched();
        }
        else if (!this.treasuryMovementTypeRequiresIban(treasuryMovementTypeId) && this.childrenControls.controls[formControlIndex].get('bankAccountId')){
            (this.childrenControls.controls[formControlIndex] as UntypedFormGroup)
                .removeControl('bankAccountId');
        }
    }

    public treasuryMovementTypeRequiresIban(treasuryMovementTypeId: string): boolean{
        if (!this.treasuryMovementTypes || !treasuryMovementTypeId) {
            return false;
        }
        const selectedTypeCode = this.treasuryMovementTypes.find(t => t.id === treasuryMovementTypeId).code;
        const treasuryMovementTypeCodesRequiringIban = [6, 8];
        return treasuryMovementTypeCodesRequiringIban.includes(selectedTypeCode);
    }

    public resetAccounts(bankAccounts: ITreasuryMovementBankAccount[]): void {
        this.childrenControls.controls.forEach(x => {
            if (this.treasuryMovementTypeRequiresIban(x.get('typeId').value)) {
                x.get('bankAccountId').setValue('');
            }
        });
        this.bankAccounts = bankAccounts;
        
        if (bankAccounts.length === 0) {
            this.childrenControls.controls.forEach(x => {
                if (this.treasuryMovementTypeRequiresIban(x.get('typeId').value)) {
                    x.get('bankAccountId').markAsTouched();
                    x.get('bankAccountId').updateValueAndValidity();
                }
            });
        }
        else {
            const defaultAccountId = bankAccounts.find(ba => ba.isDefaultAccount).id;
            this.childrenControls.controls.forEach(x => {
                if (this.treasuryMovementTypeRequiresIban(x.get('typeId').value)) {
                    x.get('bankAccountId').setValue(defaultAccountId);
                }
            });
        }
    }

    public setAvailableBankAccounts(bankAccounts: ITreasuryMovementBankAccount[]): void {
        this.bankAccounts = bankAccounts;
        this.isBankAccountsLoaded = true;
    }

    public isIbanRequiredInAnyMovement(): boolean {
        return this.childrenControls.controls.some(x => this.treasuryMovementTypeRequiresIban(x.get('typeId').value));
    }
}
