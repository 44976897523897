import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiProcessorService, ApiRequest, ApiOperation } from 'api/api-processor-service/api-processor.service';
import { IAccountingProceedingCreditors } from './accounting-proceeding-creditors.model';
import { Configuration } from 'api/configuration-service/configuration.model';

@Injectable({
    providedIn: 'root',
})
export class AccountingProceedingCreditorsService {
    private endpointUrl: string;
        
    constructor(private apiProcessorService: ApiProcessorService, private readonly environment: Configuration) {
        this.endpointUrl = this.environment.apiUrl + 'accounting-management/proceedings';
    }

    public getAccountingProceedingCreditors(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointUrl}/${id}/accounting-proceeding-creditors`;

        return this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<IAccountingProceedingCreditors>('getAccountingProceedingCreditors')));
    }

    private handleError<T>(
        operation = 'operation',
        result?: T
    ): (error: any) => Observable<T> {
        return (error: any): Observable<T> => {
            console.error(operation, error);
            return of(result);
        };
    }
}
