import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { IIncomeFile } from 'api/treasury/income/income-file.model';
import { IIncome } from 'api/treasury/income/income.model';
import { IncomeService } from 'api/treasury/income/income.service';
import { LoginService } from 'app/services/login/login.service';

@Component({
    selector: 'app-income-files-modal',
    templateUrl: './income-attached-files-modal.component.html'
})
export class IncomeAttachedFilesModalComponent {
    public income: IIncome;
    public navigateToIncome: boolean;
    private proceedingId: string;
    private supplierId: string;

    public constructor(
        private readonly incomeService: IncomeService,
        private readonly loginService: LoginService,
        private readonly router: Router,
        public dialogRef: MatDialogRef<IncomeAttachedFilesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.income = data.income;
        this.proceedingId = data.proceedingId;
        this.navigateToIncome = data.navigateToIncome;
        this.supplierId = data.supplierId;
    }

    public downloadFile(file: IIncomeFile): void {
        this.incomeService.downloadIncomeFile(file.fileName, file.id).subscribe({
            error: (error: any) => {
                if (error instanceof UrlTree) {
                    this.closeDialog();
                    this.loginService.returnUrl.next(this.router.url);
                    this.router.navigate([error.toString()]);                 
                }    
            }
        });
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public goToIncome(): void {
        this.dialogRef.close();

        if (this.supplierId) {
            const extraParams: NavigationExtras = {
                state: {
                    supplierId: this.supplierId
                }
            };
            this.router.navigate(['/manager/proceedings/' + this.proceedingId + '/incomes/' + 
                                    this.income.id + '/edit-income'], extraParams);
        }
        else {
            this.router.navigate(['/manager/proceedings/' + this.proceedingId + '/incomes/' + 
                                    this.income.id + '/edit-income']);
        }
    }
}
