import { Component, Input, OnInit } from '@angular/core';
import { StringUtils } from 'app/utils/string-utils';
import { AbstractFormArrayComponentDirective } from '../abstract-form/abstract-form-array.component';

@Component({
  selector: 'form-dropdown',
  templateUrl: './form-dropdown.component.html'
})
export class FormDropdownComponent extends AbstractFormArrayComponentDirective implements OnInit {

    @Input() public controlFormName: string;
    @Input() public labelText: string;

    _valuesList: any[];
    get valuesList(): any[] {
        return this._valuesList;
    }

    @Input() set valuesList(value: any[]) {
        this._valuesList = value;
        this.filteredValues = this._valuesList;
    }

    public filteredValues: any[];

    public constructor() {
        super();
    }

    public ngOnInit(): void {

        this.filteredValues = this.valuesList;
    }

    public remove(index: number): void {
        this.childrenControls.removeAt(index);
    }

    public setSearchboxStatus(elementId: string, event: any): void {
        const domControl = document.getElementById(elementId);
        if (domControl && event && typeof(event) === 'boolean') {
            this.focusInnerElementForm(domControl);
        }
    }

    public searchValue(query: string): void {

        if (query.length > 0) {
            const result = this.selectValue(query, this.valuesList);
            this.filteredValues = result;
        } else {
            this.filteredValues = this.valuesList;
        }
    }

    public resetValue(target: any): void 
    {
        target.value = '';
        this.filteredValues = this.valuesList;
    }

    private selectValue(query: string, dropDown: any[]): string[] {
        const result: string[] = [];
        const queryWithoutAcents = StringUtils.stringWithNoAccents(query);
        for (const value of dropDown) {
            
            if ( this.valueMatches(value.viewValue, queryWithoutAcents.toLowerCase()) ||
                 this.hasSecondValueAndMatches(value.viewValue, queryWithoutAcents.toLowerCase()) ) {
                result.push(value);
            }
        }
        return result;
    }

    private focusInnerElementForm(element: HTMLElement): void {
        element.click();
        element.focus();
    }

    private valueMatches(value: any, query: any): boolean{
        return StringUtils.stringWithNoAccents(value).startsWith(query) === true;
    }

    
    private hasSecondValueAndMatches(value, query): boolean{
        if (!value.includes('/')){
            return false;
        }

        const secondValueName = value.substring( value.search('/') + 1 );
        return this.valueMatches(secondValueName, query.toLowerCase());
    }




}
