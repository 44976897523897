import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ICreditorProceeding } from 'api/creditor-proceeding-service/creditor-proceeding.model';
import { AbstractCustomFilterProceedingCreditorCredits } from '../gg-custom-filter-proceeding-creditor-credits/abstract-custom-filter-proceeding-creditor-credits.component';
import { IColumnDefinition, SelectionListComponent } from '../selection-list/selection-list.component';

@Component({
  selector: 'gg-proceeding-creditors-selections-list',
  templateUrl: './gg-proceeding-creditors-selections-list.component.html'  
})
export class ProceedingCreditorsSelectionsListComponent extends AbstractCustomFilterProceedingCreditorCredits {
    @ViewChild('selectionList', {static: false}) public selectionList: SelectionListComponent<ICreditorProceeding, any>;
    
    @Input() public proceedingsCreditors: ICreditorProceeding[];
    @Input() public columnsDefinition: Array<IColumnDefinition>;
    @Input() public initialProceedingCreditorIds: Array<string>;

    @Output() public notifySelectionChange = new EventEmitter<ICreditorProceeding>();
              
    public onSelectedElements(event: any): void {
        this.notifySelectionChange.emit(event);
    }

    public deleteSelection(): void {
        this.selectionList.deleteSelection();
    }

    public updateDataInmediately(proceedingCreditors: ICreditorProceeding[]): void {
        this.selectionList.updateDataInmediately(proceedingCreditors);
    }
}
