import { ICreditorProceeding } from 'api/creditor-proceeding-service/creditor-proceeding.model';
import { ICustomFilterMatcheckbox, ICustomFilterMatcheckboxOptions } from '../selection-list/selection-list.component';

export abstract class AbstractCustomFilterProceedingCreditorCredits {
    public customFilterMatcheckboxOptions: ICustomFilterMatcheckboxOptions<ICreditorProceeding>;

    private isUnrecognizedCreditsChecked = false;
    private isDifferencesBetweenCreditorAndAcceptedCreditsChecked = false;
    private isHasNotCreditsChecked = false;
            
    public constructor() {
        const customFilterMatcheckboxs: Array<ICustomFilterMatcheckbox> = [
            { matlabel: 'Créditos no reconocidos', onChangeFunction: this.changeUnrecognizedCreditsCheckValue.bind(this), checked: false},
            { matlabel: 'Créditos cuyo importe comunicado no coincide con el reconocido', 
                onChangeFunction: this.changeDifferencesBetweenCreditorAndAcceptedCreditsCheckValue.bind(this), checked: false },
            { matlabel: 'No tiene créditos', onChangeFunction: this.changeHasNotCreditsCheckValue.bind(this), checked: false }
        ];

        this.customFilterMatcheckboxOptions = {
            customFilterMatcheckboxs: customFilterMatcheckboxs,
            customTableSearchFilterPredicate: this.customFilterProceedingCreditorCredits.bind(this)
        };
    }

    public changeUnrecognizedCreditsCheckValue(isChecked: boolean): void {
        this.isUnrecognizedCreditsChecked = isChecked;
    }

    public changeDifferencesBetweenCreditorAndAcceptedCreditsCheckValue(isChecked: boolean): void {
        this.isDifferencesBetweenCreditorAndAcceptedCreditsChecked = isChecked;
    }

    public changeHasNotCreditsCheckValue(isChecked: boolean): void {
        this.isHasNotCreditsChecked = isChecked;
    }

    public customFilterProceedingCreditorCredits(proceedingCreditor: ICreditorProceeding): boolean {
        let filterCredits = true;

        if (this.isUnrecognizedCreditsChecked) {
            filterCredits = filterCredits && proceedingCreditor.hasUnrecognizedCredits;
        }
        
        if (this.isHasNotCreditsChecked) {
            filterCredits = filterCredits && !proceedingCreditor.hasCredits;
        }

        if (this.isDifferencesBetweenCreditorAndAcceptedCreditsChecked) {
            filterCredits = filterCredits && proceedingCreditor.hasDifferencesBetweenCreditorAndAcceptedCredits;
        }

        return filterCredits;
    }
}
