import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RegexService {

    WORDS_REGEX(): RegExp {
        return /[^a-zA-Z0-9äëïöüÄËÏÖÜñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ!@#$&/()Çç.:,`'´ªº\\\-%¿?!¡€^\s_]+/g;
    }

    MAIL_SUBJECT_REGEX(): RegExp {
        return /[^a-zA-Z0-9äëïöüÄËÏÖÜñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ!@#$&/()\[\]Çç.:,`'´ªº\\\-%¿?!¡€^\s_]+/g;
    }

    ALPHANUMERIC_TAX_ID(): RegExp {
        return /^[a-zA-Z0-9]{8,15}$/;
    }

    ACCOUNTING_ACCOUNT(): RegExp {
        return /^[1-9][0-9]{3,14}$/;
    }

    NIE_REGEX(): RegExp{
        return /^[xyzXYZ]{1}[0-9]{7}[trwagmyfpdxbnjzsqvhlckeTRWAGMYFPDXBNJZSQVHLCKE]$/;
    }

    NIF_REGEX(): RegExp{
        return /^[a-zA-Z]{1}[0-9]{7}[trwagmyfpdxbnjzsqvhlckeTRWAGMYFPDXBNJZSQVHLCKE0-9]$/;
    }

    DNI_REGEX(): RegExp{
        return /^[0-9]{8}[trwagmyfpdxbnjzsqvhlckeTRWAGMYFPDXBNJZSQVHLCKE]$/;
    }

    DNI_NIF_REGEX(): RegExp {
        return new RegExp(this.DNI_REGEX().source + '|' + this.NIF_REGEX().source);
    }


    ES_IBAN_REGEX(): RegExp{
        return /^ES(\s?[0-9]){22}$/;
    }
    
    OTHER_IBAN_REGEX(): RegExp{
        return /^(?!ES)(\s?\w+)*$/;
    }

    IBAN_REGEX(): RegExp{
        return new RegExp(this.ES_IBAN_REGEX().source + '|' + this.OTHER_IBAN_REGEX().source);
    }

    URL_REGEX(): RegExp {
        // tslint:disable-next-line: max-line-length
        return /^(https?:\/\/)*[a-zA-Z0-9äëïöüÄËÏÖÜñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ-]+(\.[a-zA-Z0-9äëïöüÄËÏÖÜñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ-]+\.)+[a-zA-Z0-9äëïöüÄËÏÖÜñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ-]+(\/[a-zA-Z0-9äëïöüÄËÏÖÜñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ-]+)*\/?$/;
    }

    NUMBERS_REGEX(): RegExp {
        return /^([0-9]{1,3})([.][0-9]{3})*$/;
    }

    PERCENT_REGEX(): RegExp {
        return /^\d{1,2}(,\d{1,2})*$/;
    }

    EURO_REGEX(): RegExp {
        return /^-?([0-9]+)|((([1-9][0-9]*)|([0-9]))([.,])[0-9]{1,2})$/;
    }

    DATE_REGEX(): RegExp {
        return /^\d{4}(\/|\-)(\d{2}|\d{1})(\/|\-)(\d{2}|\d{1})T\d\d:\d\d:\d\d(\.\d\d\d[A-Z]){0,1}$/;
    }

   
    PHONE_REGEX(): RegExp {
        return /([+]?[0-9][0-9]\s?)?[0-9]{8,22}(-ext:[0-9]{1,9})?$/;
    }

    ZIPCODE_REGEX(): RegExp {
        return /^[0-9]{1,5}$/;
    }
    
    NUMBERS_AND_LETERS(): RegExp {
        return /^[a-zA-Z0-9]*$/;
    }

    NUMBERS_AND_LETERS_WITH_SPACES(): RegExp {
        return /^[a-zA-Z0-9 ]*$/;
    }

    NUM_AUTO(): RegExp{
        return /^[0-9].*([-\/])[0-9]*$/;
    }

}
