import { ApplicationRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Configuration } from 'api/configuration-service/configuration.model';
import { GenericDialogComponent } from 'app/components/generic-dialog/generic-dialog.component';
import { InfoComponent } from 'app/components/gg-info/gg-info.component';
import { concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable()
export class AngularUpdateService {
    private isDialogOpenend = false;
    private everyHourTimeInterval = 60000;

    constructor(
        private appRef: ApplicationRef,
        private updates: SwUpdate,
        public dialog: MatDialog,
        private readonly environment: Configuration
    ) {
        if (updates.isEnabled) {
            this.continuousCheckAngularStatus();
        }
    }

    public checkAngularStatus(): void {
        if (this.updates.isEnabled) {
            this.updates.versionUpdates.pipe(
                filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
            ).subscribe(() => this.showMessage());
        } else {
            console.log('ServiceWorker no disponible');
        }
    }

    public showMessageDialog(): void {
        this.dialog.open(GenericDialogComponent, {
            data: {
                template: InfoComponent,
                icon: 'info',
                subTitle: 'Nueva versión disponible',
                message: 'Es necesario recargar la página para que pueda seguir trabajando.',
                additionalClassDialog: 'small',
                displayButtonBar: true,
                dialogButton: 'Aceptar'
            },
            disableClose: true,
        })
        .afterClosed()
        .subscribe(() => {
            window.location.reload();
        });
    }

    private showMessage(): void {
        this.updates.activateUpdate().then(() => {
            if (!this.isDialogOpenend) {
                this.isDialogOpenend = true;
                console.log('Nueva version');
                this.showMessageDialog();
            }
        });
    }

    private continuousCheckAngularStatus(): void {
        const appIsStable$ = this.appRef.isStable.pipe(
            first((isStable) => isStable === true)
        );
        const intervalTime$ = interval(this.everyHourTimeInterval);
        const everyInternaTimeAndIsStable$ = concat(
            appIsStable$,
            intervalTime$
        );
        everyInternaTimeAndIsStable$.subscribe(() => {
            const hour = new Date().getHours();
            if (this.environment.angularCheckUpdateHour === hour) {
                this.updates.checkForUpdate().then(() => {
                    console.log('Checking for updates');
                });
            }
        });
    }
}
