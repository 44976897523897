import { Component, Inject } from '@angular/core';
import { ICredit } from 'api/credits-service/credit.model';
import { CreditsService } from 'api/credits-service/credits.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, UrlTree } from '@angular/router';
import { ICreditFile } from 'api/credits-service/creditFile.model';
import { LoginService } from 'app/services/login/login.service';

@Component({
    selector: 'app-credits-files-modal',
    templateUrl: './credits-attached-files-modal.component.html'
})
export class CreditsAttachedFilesModalComponent {
    public credit: ICredit;
    public navigateToCredit: boolean;
    private proceedingId: string;

    public constructor(
        private readonly creditService: CreditsService,
        private readonly router: Router,
        private readonly loginService: LoginService,
        public dialogRef: MatDialogRef<CreditsAttachedFilesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.credit = data.credit;
        this.proceedingId = data.proceedingId;
        this.navigateToCredit = data.navigateToCredit;
    }

    public downloadFile(file: ICreditFile): void {
        this.creditService.downloadCreditFile(this.credit.id, file.fileName, file.id).subscribe({
            error: (error: any) => {
                if (error instanceof UrlTree) {
                    this.closeDialog();
                    this.loginService.returnUrl.next(this.router.url);
                    this.router.navigate([error.toString()]);
                }
            }
        });
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public goToCredit(): void {
        this.dialogRef.close();
        this.router.navigate(['/manager/proceedings/' + this.proceedingId + '/credits/edit-credit', this.credit.id]);
    }
}
