import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Subject, Subscription } from 'rxjs';

export interface IMyAccountFormSettings {
    url: string;
    withoutRegisterMode: boolean;
    loginmodel: boolean;
    data?: any;
    onSuccess: () => void;
    onCancel: () => void;
}

export interface IMyAccountModalSettings {
    showRegisterOption: boolean;
    email: string;
}

declare class MyAccountLoginForm {
    public constructor(settings: IMyAccountFormSettings);
    public display(): void;
    public show(): void;
    public hide(): void;
    public closeWithoutCancel(): void;
    public close(): void;    
}

@Component({
    selector: 'gioc-login-modal',
    template: ''
})
export class MyAccountLoginModalComponent implements OnDestroy, OnInit {
    
    @Output() public closeDialogRequested: EventEmitter<void>;
    @Output() public scriptHasLoaded: EventEmitter<void>;    
    @Output() public loginCancelled: EventEmitter<void>;
    @Output() public loginSuccessful: EventEmitter<void>;
    private scriptLoaded = false;
    private formWindowCall: MyAccountLoginForm;
    private openModalRequest: Subject<boolean>;
    private openModalSubscription: Subscription;
    private script: HTMLScriptElement;   
    private showRegisterOption: boolean;
    private email: string;

    public constructor(private readonly environment: Configuration) {
        this.closeDialogRequested = new EventEmitter();
        this.scriptHasLoaded = new EventEmitter();
        this.loginCancelled = new EventEmitter();
        this.loginSuccessful = new EventEmitter();
        this.openModalRequest = new Subject();
    }

    public ngOnInit(): void {   
        this.loadScript();        
        this.openModalSubscription = this.openModalRequest.subscribe((val: boolean) => {
            if (val && this.scriptLoaded) {
                this.innerOpenModal();
            }
        });      
    }

    public ngOnDestroy(): void {
        if (this.openModalSubscription) {
            this.openModalSubscription.unsubscribe();
        }

        if (this.formWindowCall) {
            this.formWindowCall.closeWithoutCancel();
            this.destroyScript();
        }
    }
    
    public openModal(myAccountModalSettings: IMyAccountModalSettings): void {             
        this.showRegisterOption = myAccountModalSettings.showRegisterOption;
        this.email = myAccountModalSettings.email;
        this.openModalRequest.next(true);
    }

    private loadScript(): void {

        this.script = document.createElement('script');
        this.script.onload = () => { this.scriptLoaded = true; this.scriptHasLoaded.emit(); };
        this.script.src = `${this.environment.urlMyAccountHome}${this.environment.myAccountLoginScriptLocation}`;
        this.script.async = true;
        this.script.defer = true;
        document.body.appendChild(this.script);
    }

    private innerOpenModal(): void {
        this.loadWindowForm();
        this.formWindowCall.display();
        this.formWindowCall.show();
    }

    private loadWindowForm(): void {
        const data: any = { predefinedEmail: this.email, forceOpenLoginPopup: true };

        const url = this.showRegisterOption ?
            `${ this.environment.urlMyAccountHome }${ this.environment.myAccountExternalLoginUrl }` :
            `${ this.environment.urlMyAccountHome }${ this.environment.myAccountExternalLoginWithoutRegisterUrl }`;

        this.formWindowCall = new MyAccountLoginForm({
            url: url,
            withoutRegisterMode: !this.showRegisterOption,
            loginmodel: true,
            data: data,
            onSuccess: () => { this.onSuccessCallback(); },
            onCancel: () => { this.onCancelCallback(); }
        });        
    }

    private onSuccessCallback(): void {            
        this.loginSuccessful.emit();                   
        this.closeExternalWindow();
    }

    private onCancelCallback(): void {
        this.loginCancelled.emit();
        this.closeExternalWindow();
    }

    private closeExternalWindow(): void {
        this.openModalRequest.next(false);
        this.formWindowCall.closeWithoutCancel();
        this.closeDialogRequested.emit();
    }

    protected destroyScript(): void {
        const elements = document.getElementsByClassName('iframeLogin');
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    }
}
