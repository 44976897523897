import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {
    private endpointUrl: string;

    constructor(private apiProcessorService: ApiProcessorService, private readonly environment: Configuration) {
        this.endpointUrl = this.environment.apiUrl + 'accounting-management';
    }

    public getAccountingYears(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;        
        apiRequest.endpointUrl = `${ this.endpointUrl }/proceedings/${proceedingId}/accounting-years`;
        
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getAccountingYear(accountingYearId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;       
        apiRequest.endpointUrl = `${ this.endpointUrl }/accounting-years/${accountingYearId}`;
        
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public deleteAccountingYear(accountingYearId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl =  `${ this.endpointUrl }/accounting-years/${accountingYearId}`;    

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
