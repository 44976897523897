import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SamlClientService {
    private readonly SAML_CLIENT_ID_TOKEN = 'saml-client-id';

    public setSamlClientId(samlClientId: string): void {
        sessionStorage.setItem(this.SAML_CLIENT_ID_TOKEN, samlClientId);
    }

    public getSamlClientId(): string {
        return sessionStorage.getItem(this.SAML_CLIENT_ID_TOKEN);
    }

    public appendSamlClientIdAsQueryParameter(originalUrl: string): string {
        const samlClientId = this.getSamlClientId();
        if (!samlClientId) {
            return originalUrl;
        }
        const url = new URL(originalUrl);
        url.searchParams.append('idp', samlClientId);
        return url.toString();
    }
}
