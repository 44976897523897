import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { cloneDeep } from 'lodash';
import { GenericFilterDirective } from 'app/components/generic-filter/generic-filter';
import { IFilterOptions } from '../selection-list/selection-list.component';

@Component({
    selector: 'gg-filter-list',
    templateUrl: './gg-filter-list.component.html',
})
export class FilterListComponent<T> extends GenericFilterDirective<T> implements OnChanges {    
    @Input() public options: Array<{viewValue: string, value: any, checked: boolean}> = undefined;
    @Input() public optionsByInput = false;
    @Input() public type: string = undefined;
    @Input() public set genericSelectionListfilterOptions(filterOptions: IFilterOptions) {
        if (!filterOptions) {
            return;
        }
        
        for (const key of Object.getOwnPropertyNames(filterOptions)) {
            if (this.hasOwnProperty(key)) {                    
                this[key] = filterOptions[key];
            }
        }
    }

    public selectedItems = new Array<string>();
    public innerOptions: Array<{viewValue: string, value: any, checked: boolean}> = [];

    public ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        if (changes['dataTable'] && changes['dataTable'].currentValue) {
            this.setOptions();
        }
    }
    
    public getFilterPredicate(data: T, filter: string): boolean {
        if (!this.filterName || !this.isFieldVisible) {
            return true;
        }
        
        const filterTerms = JSON.parse(filter);
        let searchTerm = new Array<string>();

        if (filterTerms[this.filterName] && filterTerms[this.filterName].length !== 0) {
            searchTerm = filterTerms[this.filterName];
        }

        if (this.customSearch) {
            return this.customSearch(searchTerm, data, this.filterName);
        }

        return this.nameSearch(searchTerm, data[this.filterName]);                                    
    }

    public resetFilters(): void {
        this.selectedItems = new Array<string>();
        this.innerOptions.forEach((option) => {
            option.checked = false;
        });

        super.resetFilters();
    }
    
    public setOptions(): void {
        if (!this.filterName) { return; }

        if (!this.optionsByInput) {
            this.innerOptions = this.getFilterByDataTable(this.dataTable.data);
        }
        else {
            const tableOptions = this.getFilterByDataTable(this.dataTable.data);
            this.innerOptions = cloneDeep(this.options.filter(opt => tableOptions.some((tOpt: any) => tOpt.value === opt.value)));
        }
        
        this.updateRemovedFilterOptions();

        if (this.innerOptions) {
            this.innerOptions.sort((a, b) => {
                const aValue = this.getValue(a.viewValue) || '';
                const bValue = this.getValue(b.viewValue) || '';
                return aValue.toString().localeCompare(bValue.toString());
            });

            this.selectedItems.forEach(selectedItem => {
                const selectedOption = this.innerOptions.find(x => x.value === selectedItem);
                if (selectedOption) {
                    selectedOption.checked = true;
                }
            });
        }
    }
    
    public filterChange(column: string, value: any, event: MatCheckboxChange): void {
        const parsedValue = this.getValue(value);
        if (event.checked) {
            this.selectedItems.push(parsedValue);
        } 
        else {
            const index = this.selectedItems.indexOf(parsedValue);
            if (index !== -1) {
                this.selectedItems.splice(index, 1);
            }
        }

        this.dataTable.filter = this.getNewFilter(column);
        this.filterChanged.emit();
    }

    private getNewFilter(column: string): string {
        let newFilter = {};
        if (this.dataTable.filter) {
            newFilter = JSON.parse(this.dataTable.filter);
        }

        newFilter[column] = this.selectedItems;
        return JSON.stringify(newFilter);
    }

    private nameSearch(searchTerm: Array<string>, dataObj: any): boolean {
        const data = this.getValue(dataObj);
        if (!searchTerm || searchTerm.length === 0) {
            return true;
        }
        return searchTerm.some(x => x === data);
    }

    private getFilterByDataTable(columnData: Array<any>): any {
        const filtersDropdown = [];
        columnData.forEach((obj: string) => {
            const objValue = this.getValue(obj, true);
            if (!filtersDropdown.some(val => val.value === objValue)) {
                const existingOption = this.innerOptions ? this.innerOptions.find(x => x.value === objValue) : false;
                filtersDropdown.push({
                    viewValue: objValue, 
                    value: objValue, 
                    checked: existingOption ? existingOption.checked : false
                });
            }
        });
        return filtersDropdown;
    }
   
    private updateRemovedFilterOptions(): void {
        if (this.dataTable.filter) {
            const filterObj = JSON.parse(this.dataTable.filter);
            const columnData = this.dataTable.data.map(x => this.getValue(x[this.filterName]));

            if (filterObj[this.filterName]) {
                filterObj[this.filterName].forEach((filterColumn: any) => {
                    if (!columnData.includes(filterColumn)) {
                        const filterIndex = filterObj[this.filterName].indexOf(filterColumn);
                        if (filterIndex !== -1) {
                            filterObj[this.filterName].splice(filterIndex, 1);
                        }
                        const selectedItemsIndex = this.selectedItems.indexOf(filterColumn);
                        if (selectedItemsIndex !== -1) {
                            this.selectedItems.splice(selectedItemsIndex, 1);
                        }
                    }
                });
            }

            this.dataTable.filter = JSON.stringify(filterObj);
        }
    }
}
