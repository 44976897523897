import { Injectable, ViewChild, SecurityContext, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Configuration } from 'api/configuration-service/configuration.model';
import { GiocondaGenericDialogComponent } from 'app/components/gg-generic-dialog/gg-generic-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class PublicLinksButtonsService {

    public liquidationsVideoUrl: SafeResourceUrl;
    public urlSite: SafeResourceUrl;
    public mainHome = 'main';
    public isShow: boolean;
    public topPosToStartShowing = 100;
    public scrollY = 0;

    @ViewChild('dialogUrl', { static: true }) public dialogUrl: GiocondaGenericDialogComponent;

    @HostListener('window:scroll', ['$event']) onScroll(event){
        this.scrollY = window.scrollY;
        console.log('scroll: [scroll]', this.scrollY);
      }
  
    @HostListener('window:scroll') heckScroll() {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        console.log(' [scroll]', scrollPosition);
        if (scrollPosition >= this.topPosToStartShowing) {
            this.isShow = true;
        } else {
            this.isShow = false;
        }
        alert(this.isShow);
    }

    constructor(
        private scrolling: ViewportScroller,
        private router: Router, 
        private sanitizer: DomSanitizer,
        private readonly environment: Configuration,
        ) {
            this.liquidationsVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.environment.liquidationsVideoUrl);
    }

    public scrollMain(): void {
        this.scroll(this.mainHome);
    }

    public goToContact(): void {
        this.router.navigate(['/public/testIt']);
        this.scrollMain();
    }

    public goToAuctions(): void {
        this.router.navigate(['/public/auctions']);
        this.scrollMain();
    }
    
    public sanitizeUrl(url: SafeResourceUrl): SafeResourceUrl{
        return this.sanitizer.bypassSecurityTrustResourceUrl(`${url}`);
    }
    
    public goToLink(link: SafeResourceUrl): void {
        this.urlSite = this.sanitizeUrl(link); 
        const data = this.sanitizer.sanitize(SecurityContext.URL, this.urlSite);
        window.open(data);
    }

    public openDialog(link: SafeResourceUrl): void {
        this.urlSite = this.sanitizeUrl(link); 
        this.dialogUrl.openDialog({
            panelClass: 'cdk-overlay-big-modal',
            backdropClass: 'cdk-overlay-backdrop-dark'
        });
    }
    
    public scroll(element: any ): void {
        const qelement = document.querySelector('#' + element);
        qelement.scrollIntoView();
    }

    public scrollTop(): void {
        this.scrolling.scrollToPosition([0, 0]);
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
}
