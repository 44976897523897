import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'gg-panel-header',
    templateUrl: './gg-panel-header.component.html',
    styleUrls: ['./gg-panel-header.component.scss'],
    encapsulation  : ViewEncapsulation.None,
})

export class PanelHeaderComponent implements OnInit {

    @Input() isButtonBar = true;
    @Input() isTypeGrey: boolean;
    @Input() isDisplayedListBar = true;
    @Input() isDisplayedButton: boolean;
    @Input() isDisplayedToggle: boolean;
    @Input() isDisplayedListSelect: boolean;
    @Input() isControlBottomHidden: boolean;
    @Input() isListTotalsHidden: boolean;
    @Input() isListProofSendingHidden: boolean;
    
    

    ngOnInit(): void {
        if (this.isButtonBar === true) {
            this.isDisplayedButton = true;
        } else {
            this.isDisplayedListBar = false;
        }
    }
}
