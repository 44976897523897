import { Component, AfterViewInit, Input, NgZone  } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';

@Component({
    selector: 'app-scroll-to-top',
    templateUrl: './scroll-to-top.component.html',
    styleUrls: ['./scroll-to-top.component.scss']
})

export class ScrollToTopComponent implements AfterViewInit {

    @Input() public mainElement: string;
    @Input() public titleButton: string;

    public haveTitleButton: boolean;

    public isShow: boolean;
    public topPosToStartShowing = 100;

    constructor(
        private scrollDispatcher: ScrollDispatcher,
        private scrolling: ViewportScroller,
        private zone: NgZone
    ) {
    }

    public ngAfterViewInit(): void {
        this.haveTitleButton = !(this.titleButton === null || this.titleButton === undefined || this.titleButton === '');
        this.scrollDispatcher.scrolled().subscribe((cdk: CdkScrollable)  => {
            this.zone.run(() => {
                const scrollPosition = cdk.getElementRef().nativeElement.scrollTop;
                if (scrollPosition >= this.topPosToStartShowing) {
                    this.isShow = true;
                } else {
                    this.isShow = false;
                }
            });
        });
    }

    public scrollMain(): void {
        this.scroll(this.mainElement);
    }

    public scroll(element: any ): void {
        const qelement = document.getElementById(element);
        qelement.scrollIntoView();
    }
    

    public scrollToTop(): void {
        this.scrolling.scrollToPosition([0, 0]);
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        (function smoothscroll(): void {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    }
    
  }
