import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IJsonPatchDocument } from 'api/api-processor-service/json-patch-document.model';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable } from 'rxjs';
import { ApiOperation, ApiProcessorService, ApiRequest } from '../api-processor-service/api-processor.service';
import { RecognizedTypes } from './credit-recognized-types.model';
import { ICredit } from './credit.model';
import { IRectificationInvoicesGenerations } from './rectification-invoices-generations-request';

@Injectable({    
    providedIn: 'root'
})
export class CreditsService {
    private endpointUrl: string;
    
    constructor(
        private readonly apiProcessorService: ApiProcessorService,
        private readonly http: HttpClient,
        private readonly environment: Configuration) {
        
        this.endpointUrl = this.environment.apiUrl + 'credit-management';
    }

    public getCreditsForCreditorInProceeding(proceedingId: string, proceedingCreditorId: string, allCreditorTypes: boolean): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.endpointUrl}/proceedings/${proceedingId}/credits?` +
            `creditorInProceedingId=${proceedingCreditorId}` + 
            `&AllCreditorTypes=${ allCreditorTypes }` +
            `&requestAll=true`;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public getCreditById(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/credits/${ id }`;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public saveCredit(credit: ICredit): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${ this.endpointUrl }/credits`;
        apiRequest.body = credit; 

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public updateCredit(id: string, credit: ICredit): Observable<any> {

        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${ this.endpointUrl }/credits/${ id }`;
        apiRequest.body = credit;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public patchCredit(jsonPatchDocument: Array<IJsonPatchDocument>, creditId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.endpointUrl = `${this.endpointUrl}/credits/${creditId}`;
        apiRequest.body = JSON.stringify(jsonPatchDocument);

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public recognizeCredits(creditsIds: Array<string>, recognizedType: RecognizedTypes): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${ this.endpointUrl }/credits/recognize-credits`;
        apiRequest.body = {
            creditsIds: creditsIds,
            recognizedType: recognizedType
        };

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public deleteCredit(id: string): Observable<any>{
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${ this.endpointUrl }/credits/${ id }`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public deleteCreditsCollection(creditsIds: Array<string>): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${ this.endpointUrl }/credits/`;
        apiRequest.body = JSON.stringify(creditsIds);
        return this.apiProcessorService.proccessRequest(apiRequest);
    }
        
    public getCreditFiles(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/credits/${ id }/files`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public downloadCreditFile(id: string, fileName: string, fileId: string): Observable<any> {
        let url = `${ this.endpointUrl }/credit-files/`;
        if (!url.endsWith('/')){ url += '/'; }
        url += fileId;
        return this.apiProcessorService.downloadFile(url, fileName);
    }

    public importCreditsFile(proceedingId: string, dataFile: File): Observable<any> {
        const endpointUrl = this.environment.apiUrl + 'credit-management/credits/import';
        const reqBody = new FormData();
        reqBody.append('file', dataFile, dataFile.name);
        reqBody.append('proceedingId', proceedingId);

        return this.http.post(endpointUrl, reqBody, { withCredentials: true });
    }

    public generateRectificationInvoices(generations: IRectificationInvoicesGenerations): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;        
        apiRequest.endpointUrl = `${this.endpointUrl}/credits/generate-rectification-invoice`;
        apiRequest.body = generations;
        
        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
