import { Component, Input, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { IUserType } from 'api/user-service/user.type.model';
import { Configuration } from 'api/configuration-service/configuration.model';
import { FooterLinks } from 'api/configuration-service/footer-links.model';
import { PublicLinksButtonsService } from 'app/services/public-links-buttons/public-links-buttons.service';
import { ILoggedUserInfo } from 'api/authentication-service/logged-user-info.model';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls : ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit, OnDestroy {
    
    @Input() public name: string;
    @Input() public surname: string;
    @Input() public email: string;
    @Input() public hasUserRights: boolean;
    @Input() public isAdmin: boolean;     
    @Input() public isPublicPage: boolean;
    @Input() public isUserLogged: boolean;
    @Input() public userTypeCode: IUserType;
    @Input() public showInsolvencyPracticeButton: boolean;
    @Input() public showTrainingButton: boolean;
    @Output() public loginRequested: EventEmitter<void>;
    @Output() public accessButtonClicked: EventEmitter<void>;

    public horizontalNavbar: boolean;
    public rightNavbar: boolean;
    public hiddenNavbar: boolean;
    public languages: any;
    public navigation: any;
    public selectedLanguage: any;
    public footerLinks: FooterLinks;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private readonly environment: Configuration,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        protected readonly publicLinksbuttons: PublicLinksButtonsService
    ) {
        
        this.footerLinks = this.environment.footerLinks;
        this.languages = [ {
                id   : 'en',
                title: 'English',
                flag : 'us'
            }, {
                id   : 'es',
                title: 'Spanish',
                flag : 'es'
            }
        ];

        this._unsubscribeAll = new Subject();
        this.loginRequested = new EventEmitter();
        this.accessButtonClicked = new EventEmitter();        
    }

    public ngOnInit(): void {
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });
               
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
    }

    public ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    public setLanguage(lang): void {
        this.selectedLanguage = lang;
        this._translateService.use(lang.id);
    }

    public goToLink(link: any): void {
        this.publicLinksbuttons.goToLink(link);
    }

    public setLoggedUserInfo(userLogedInfo: ILoggedUserInfo): void{
        this.isUserLogged = true;
        this.name = userLogedInfo.name;
        this.surname = userLogedInfo.surname;
        this.email = userLogedInfo.email;
    }  
}
