import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProceedingLiquidationRequestService {

    private baseEndpoint: string;

    public constructor(private readonly apiProcessorService: ApiProcessorService, environment: Configuration) {
        this.baseEndpoint = environment.apiUrl + 'proceeding-management/proceedings';
    }

    public requestLiquidation(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.baseEndpoint}/${proceedingId}/request-liquidation`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
