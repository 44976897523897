import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAssetStockAssociationWithAssetInfo } from 'api/assets-service/stock-association/asset-stock-association-with-asset-info.model';
import { IColumnDefinition } from 'app/components/selection-list/selection-list.component';

@Component({
    selector: 'gg-asset-stock-associations-with-asset-info-list-modal',
    templateUrl: './asset-stock-associations-with-asset-info-list-modal.component.html'
})
export class AssetStockAssociationsWithAssetInfoListModalComponent {
    public dialogTitle = 'Asociar Activo';
    public columnsDefinition: IColumnDefinition[];
    public proceedingName = '';
    public currentAssociatedAssetsIds: Array<number>;
    public assetStockAssocitionsWithAssetInfo: Array<IAssetStockAssociationWithAssetInfo>;
    public initialAssetIds: Array<string>;
    public initialAssetStockAssociationsWithAssetInfo: Array<IAssetStockAssociationWithAssetInfo>;

    private currentlySelectedAssets: Array<IAssetStockAssociationWithAssetInfo>;
    private updateUncheckedAssetStockAssociationsWithAssetInfo: (uncheckedAssetIds: Array<string>) => void;

    public constructor(
        public dialogRef: MatDialogRef<AssetStockAssociationsWithAssetInfoListModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data) {
            this.proceedingName = data.proceedingName;
            this.dialogTitle = this.data.dialogTitle;
            this.currentAssociatedAssetsIds = data.currentAssociatedAssetsIds;
            this.assetStockAssocitionsWithAssetInfo = this.data.assetStockAssocitionsWithAssetInfo;
            this.initialAssetIds = this.data.initialAssetIds;
            this.initialAssetStockAssociationsWithAssetInfo = this.data.initialAssetStockAssociationsWithAssetInfo;
            this.updateUncheckedAssetStockAssociationsWithAssetInfo = this.data.updateUncheckedAssetStockAssociationsWithAssetInfo;

            this.columnsDefinition = [
                {
                    name: 'name',
                    title: 'Nombre descriptivo',
                    type: 'string'
                },
                {
                    name: 'assetType',
                    title: 'Naturaleza',
                    type: 'string'
                },
                {
                    name: 'accountingCategory',
                    title: 'Categoría',
                    type: 'string'
                },                
                {
                    name: 'totalStock',
                    title: 'Stock total',
                    type: 'number'
                },
                {
                    name: 'availableStockAmount',
                    title: 'Stock Disponible',
                    type: 'number'
                }
            ];

            const selectedAssets = this.assetStockAssocitionsWithAssetInfo
                .filter((x: IAssetStockAssociationWithAssetInfo) => this.currentAssociatedAssetsIds.includes(x.id));

            this.setSelectedAssets(selectedAssets);
    }

    public isRowDisabled(element: IAssetStockAssociationWithAssetInfo): boolean {
        if (this.initialAssetIds.length === 0) {
            const initialReducedAssetWithLiquidationInfo: IAssetStockAssociationWithAssetInfo = this.initialAssetStockAssociationsWithAssetInfo
                .find((a: IAssetStockAssociationWithAssetInfo) => a.assetId === element.assetId);

            return initialReducedAssetWithLiquidationInfo.availableStockAmount === 0;
        }

        return element.availableStockAmount === 0 && this.initialAssetIds.findIndex((assetId: string) => assetId === element.assetId) < 0;            
    }

    public cancelAssociation(): void {
        this.dialogRef.close();
    }

    public associateSelectedAssets(): void {
        this.dialogRef.close(this.currentlySelectedAssets || []);
    }

    public uncheckedAssetStockAssociationsWithAssetInfo(elements: Array<{element: IAssetStockAssociationWithAssetInfo, newState: boolean}>): void {
        this.updateUncheckedAssetStockAssociationsWithAssetInfo(elements
            .filter((x: {element: IAssetStockAssociationWithAssetInfo, newState: boolean}) => !x.newState)
            .map((x: {element: IAssetStockAssociationWithAssetInfo, newState: boolean}) => x.element.assetId));
    }

    public setSelectedAssets(assets: Array<IAssetStockAssociationWithAssetInfo>): void {
        this.currentlySelectedAssets = assets;
    }
}
