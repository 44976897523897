export interface ILoggedUserInfo {    
    email: string;
    name: string;
    surname: string;
    isAdmin: boolean;
    hasUserRights: boolean;
    userId: string;
    giocondaModules: number[];
}

export enum GiocondaModules {
    CalendarTaskModule = 1,
    GiocondaModule = 2
}
