import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeResourceUrl'
})

export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }

  public transform(value: any): any {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(value) as string;
  }
  
}