import { Injectable } from '@angular/core';

@Injectable()
export class CacheContainerService {
    private readonly COMMON_INVALIDATION_TIME = 60;
    private storedData: Map<string, CacheElement>;

    constructor() {
        this.storedData = new Map<string, any>();
    }

    public invalidate(): void {
        this.storedData = new Map<string, any>();
    }

    public invalidateKey(key: string): void {
        if (this.containsKey(key)) {
            this.storedData.delete(key);
        }
    }

    public isExpiredEntry(key: string): boolean {
        if (!this.containsKey(key)) {
            throw { errorMsg: 'Not exist entry' };
        }
        const value = this.getInnerData(key);
        return typeof(value) === 'undefined' || value === null ? true : value.isExpired();
    }

    public containsKey(key: string): boolean {
        return this.storedData.has(key);
    }

    public getData(key: string): any {
        if (!this.containsKey(key)) {
            return null;
        }
        const value = this.getInnerData(key);
        if (!value || value.isExpired()) {
            return null;
        }
        return value.data;
    }

    public setData(key: string, value: any): void {
        if (this.containsKey(key)) {
            this.storedData.delete(key);
        }
        this.storedData.set(key, new CacheElement(value, value.expiration_time || this.COMMON_INVALIDATION_TIME));
    }

    private getInnerData(key: string): CacheElement {

        const value = this.storedData.get(key);
        if (typeof(value) === 'undefined' || value === null || value.isExpired()) {
            this.storedData.delete(key);
            return null;
        }

        return value;
    }
}

export class CacheElement {
    public data: any;
    public expirationDate: Date;

    constructor(data: any, expiresIn: number, rawDate = false) {
        this.data = data;
        this.expirationDate = new Date(rawDate ? expiresIn : this.calculateOffset(expiresIn));
    }

    public isExpired(): boolean {
        return this.expirationDate < new Date();
    }

    private calculateOffset(expiresIn: number): number {
        return Date.now() + (expiresIn * 60 * 1000);
    }
}
