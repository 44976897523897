import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { CreditsAgainstEstateService } from 'api/treasury/credits-against-estate/credits-against-estate.service';
import { ICreditAgainstEstateFile } from 'api/treasury/credits-against-estate/credit-against-estate-file.model';
import { IReducedCreditAgainstEstate } from 'api/treasury/credits-against-estate/reduced-credit-against-estate.model';
import { LoginService } from 'app/services/login/login.service';

@Component({
    selector: 'app-credits-against-estate-files-modal',
    templateUrl: './credits-against-estate-attached-files-modal.component.html'
})
export class CreditsAgainstEstateAttachedFilesModalComponent {
    public creditAgainstEstate: IReducedCreditAgainstEstate;
    public files: ICreditAgainstEstateFile[];
    public navigateToCreditAgainstEstate: boolean;
    private proceedingId: string;
    private supplierId: string;

    public constructor(
        private readonly creditAgainstEstateService: CreditsAgainstEstateService,
        private readonly loginService: LoginService,
        private readonly router: Router,
        public dialogRef: MatDialogRef<CreditsAgainstEstateAttachedFilesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.creditAgainstEstate = data.creditAgainstEstate;
        this.proceedingId = data.proceedingId;
        this.navigateToCreditAgainstEstate = data.navigateToCreditAgainstEstate;
        this.supplierId = data.supplierId;
        this.files = data.files;
    }

    public downloadFile(file: ICreditAgainstEstateFile): void {
        this.creditAgainstEstateService.downloadCreditAgainstEstateFile(file.fileName, file.id).subscribe({
            error: (error: any) => {
                if (error instanceof UrlTree) {
                    this.closeDialog();
                    this.loginService.returnUrl.next(this.router.url);
                    this.router.navigate([error.toString()]);                    
                }
            }
        });
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public goToCreditAgainstEstate(): void {
        this.dialogRef.close();

        if (this.supplierId) {
            const extraParams: NavigationExtras = {
                state: {
                    supplierId: this.supplierId
                }
            };
            this.router.navigate(['/manager/proceedings/' + this.proceedingId + '/credits-against-estate/' + 
                                    this.creditAgainstEstate.id + '/edit-credit-against-estate'], extraParams);
        }
        else {
            this.router.navigate(['/manager/proceedings/' + this.proceedingId + '/credits-against-estate/' + 
                                    this.creditAgainstEstate.id + '/edit-credit-against-estate']);
        }
    }
}
