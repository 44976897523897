import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserTypes } from 'api/user-service/user.type.model';
import { AuthenticatedUserCacheService } from 'app/services/authenticated-user-cache/authenticated-user-cache.service';
import { Observable } from 'rxjs';

import { AllowBaseAccessGuard } from './allow-base.guard';

@Injectable({
    providedIn: 'root'
})
export class AllowManagerAccessGuard extends AllowBaseAccessGuard {
    public constructor(
        public authenticatedUserProvider: AuthenticatedUserCacheService,
        private readonly router: Router) {
            super(authenticatedUserProvider);
        }

        public canActivate(): Observable<boolean | UrlTree> {
            return super.canActivate();
        }
    
        protected processUserTypeCode(userType: number): boolean | UrlTree {
            switch (userType) {
                case UserTypes.BankruptcyAdministrator:
                    return true;
                default:
                    return this.router.parseUrl('/public/unauthorized?type=2');
            }
        }
}
