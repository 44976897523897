import { Component, Inject } from '@angular/core';
import { IProceeding } from 'api/proceedings-service/proceeding.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'gg-card-data',
    templateUrl: './gg-card-data.component.html',
    styleUrls: ['./gg-card-data.component.scss']
})

export class CardDataComponent {

    public proceeding: IProceeding;
    
    constructor(@Inject(MAT_DIALOG_DATA) data: { proceeding: IProceeding }) {
        this.proceeding = data.proceeding;
    }
}
