import { Injectable } from '@angular/core';
import { IOffice } from 'api/office-service/office.model';
import { OfficeService } from 'api/office-service/office.service';
import { CacheContainerService } from '../cache/cache-container.service';
import { AuthenticationService } from 'api/authentication-service/authentication.service';
import { GiocondaModules, ILoggedUserInfo } from 'api/authentication-service/logged-user-info.model';
import { IUserType } from 'api/user-service/user.type.model';
import { UserService } from 'api/user-service/user.service';

@Injectable()
export class AuthenticatedUserCacheService {
    
    private readonly CURRENT_OFFICES_KEY = 'GIOC_CURRENT_OFFICES';
    private readonly USER_INFO_KEY = 'GIOC_USER_INFO';
    private readonly USER_TYPE_KEY = 'GIOC_USER_TYPE';

    public constructor(
        private readonly cacheContainer: CacheContainerService,
        private readonly officeService: OfficeService,
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UserService
    ) {
    }

    public async getCurrentOffices(): Promise<Array<IOffice>> {
        if (this.cacheContainer.containsKey(this.CURRENT_OFFICES_KEY) && !this.cacheContainer.isExpiredEntry(this.CURRENT_OFFICES_KEY)) {
            return this.cacheContainer.getData(this.CURRENT_OFFICES_KEY) as Array<IOffice>;
        }

        const offices: IOffice[] = await this.officeService.getOfficesByUserId().toPromise();
        this.cacheContainer.setData(this.CURRENT_OFFICES_KEY, offices);
        return offices;
    }

    public setCurrentOffices(currentOffices: Array<IOffice>): void {
        this.cacheContainer.setData(this.USER_INFO_KEY, currentOffices);
    }

    public async getLoggedUserInfo(): Promise<ILoggedUserInfo> {
        if (this.cacheContainer.containsKey(this.USER_INFO_KEY) && !this.cacheContainer.isExpiredEntry(this.USER_INFO_KEY)) {
            return this.cacheContainer.getData(this.USER_INFO_KEY) as ILoggedUserInfo;
        }

        const loggedUserInfo: ILoggedUserInfo = await this.authenticationService.getLoggedUserInfo().toPromise();
        if (!loggedUserInfo) {
            return undefined;
        }

        this.setLoggedUserInfo(loggedUserInfo);
        return loggedUserInfo;
    }

    public setLoggedUserInfo(loggedUserInfo: ILoggedUserInfo): void {
        this.cacheContainer.setData(this.USER_INFO_KEY, loggedUserInfo);
    }

    public async getCurrentUserType(): Promise<IUserType> {
        if (this.cacheContainer.containsKey(this.USER_TYPE_KEY) && !this.cacheContainer.isExpiredEntry(this.USER_TYPE_KEY)) {
            return this.cacheContainer.getData(this.USER_TYPE_KEY) as IUserType;
        }

        const userInfo = await this.getLoggedUserInfo();
        if (!userInfo) {
            return undefined;
        }

        const users = await this.userService.getUsersByEmail(userInfo.email).toPromise();
        this.setCurrentUserType(users[0].userType);
        return users[0].userType;
    }

    public setCurrentUserType(currentUserType: IUserType): void {
        this.cacheContainer.setData(this.USER_TYPE_KEY, currentUserType);
    }

    public async hasLoggedUserPurchasedModule(module: GiocondaModules): Promise<boolean> {
        const loggedUserInfo: ILoggedUserInfo = await this.getLoggedUserInfo();
        return loggedUserInfo.giocondaModules.includes(module);
    }

    public clearUserOfficesCache(): void {
        this.cacheContainer.invalidateKey(this.CURRENT_OFFICES_KEY);
    }

    public clearUserInfoCache(): void {
        this.cacheContainer.invalidateKey(this.USER_INFO_KEY);
    }

    public clearUserTypeCache(): void {
        this.cacheContainer.invalidateKey(this.USER_TYPE_KEY);
    }

    public clearAuthenticatedUserCache(): void {
        this.clearUserInfoCache();
        this.clearUserOfficesCache();
        this.clearUserTypeCache();
    }
}
