import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IOffice } from 'api/office-service/office.model';
import { AuthenticatedUserCacheService } from 'app/services/authenticated-user-cache/authenticated-user-cache.service';
import { from, Observable } from 'rxjs';

@Injectable()
export class CurrentOfficesResolver implements Resolve<Array<string>> {

    public constructor(
        private readonly authenticatedUserProvider: AuthenticatedUserCacheService) { }

    resolve(): Observable<Array<string>> {
        
        return from(
            this.authenticatedUserProvider.getCurrentOffices().then(
                (offices: IOffice[]) => {
                    return offices.map(x => x.id);
                },
                () => {
                    return undefined;
                }
            )
        );
    }
}
