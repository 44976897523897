import { Injectable } from '@angular/core';
import { ITemplateToken, ITokenGroup } from 'api/template-token-service/template-token.model';
import { TemplateTokenService } from 'api/template-token-service/template-token.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateTokenGroupProviderService {

    constructor(private readonly templateTokenService: TemplateTokenService) { }

    public async getCommunicationsTokenGroups(): Promise<ITokenGroup[]> {
        const templateTokens: ITemplateToken[] = await this.templateTokenService.getTemplateTokensAvailableInCommunications().toPromise();        
        return this.buildTokenGroups(templateTokens);
    }

    public async getDocumentsTokenGroups(): Promise<ITokenGroup[]> {
        const templateTokens: ITemplateToken[] = await this.templateTokenService.getTemplateTokensAvailableInDocuments().toPromise();
        return this.buildTokenGroups(templateTokens);
    }

    public buildTokenGroups(templateTokens: ITemplateToken[]): ITokenGroup[] {
        const tokenGroupCodes = Array.from(new Set(templateTokens.map((tk: ITemplateToken) => tk.tokenGroup.code)));
        const tokenGroups: ITokenGroup[] = [];

        tokenGroupCodes.forEach((tgc: number) => {
            if (!tokenGroups.find((atg: ITokenGroup) => atg.code === tgc)) {
                const tokens = templateTokens.filter((tk: ITemplateToken) => tk.tokenGroup.code === tgc);
                tokenGroups.push({
                    id: tokens[0].tokenGroup.id,
                    code: tokens[0].tokenGroup.code,
                    name: tokens[0].tokenGroup.name,
                    templateTokens: tokens
                });
            }
        });

        return tokenGroups;
    }
}
