import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';
import { Observable } from 'rxjs';
import { ApplicationTokenSections } from './template-token.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateTokenService {
    private endpointUrl: string;    
    
    constructor(private readonly apiProcessor: ApiProcessorService, private readonly environment: Configuration) {        
        this.endpointUrl = this.environment.apiUrl + 'masterdata-management/template-tokens';
    }

    public getTemplateTokensAvailableInDocuments(): Observable<any> {
        const url = this.endpointUrl + '?applicationTokenSectionCodes=' + ApplicationTokenSections.DOCUMENTS;

        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = url;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getTemplateTokensAvailableInCommunications(): Observable<any> {
        const url = this.endpointUrl + '?applicationTokenSectionCodes=' + ApplicationTokenSections.COMMUNICATIONS;

        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = url;

        return this.apiProcessor.proccessRequest(apiRequest);
    }
    
    public getTemplateTokens(codes: Array<string>): Observable<any> {
        let url = this.endpointUrl;

        if (codes && codes.length > 0) {
            url += '?codes=';
            url += codes.join(',');        
        }

        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = url;

        return this.apiProcessor.proccessRequest(apiRequest);
    }
}
