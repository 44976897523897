import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'gg-accordion-panel',
    templateUrl: './gg-accordion-panel.component.html'
})
export class AccordionPanelComponent implements OnInit {
    @Input() idCard: string;
    @Input() expanded = true;
    @Input() addClass: string;
    @Input() hadHeader = true;

    public classPanel: string;
    
    public ngOnInit(): void {
        if ((this.addClass === null) || (this.addClass === undefined) || (this.addClass === '')) {
            this.classPanel = 'card-form';
        } else {
            this.classPanel = this.addClass;
        }
    }

}
