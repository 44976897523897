export const locale = {
    lang: 'en',
    data: {
        NAV: {
            'PROCEEDINGS': 'Concursos',
            'PROCEEDINGS-LIST': {
                TITLE: 'Concursos'
            },
            'PROCEEDINGS-FORM': {
                TITLE: 'Alta de concurso'
            }
        }
    }
};
