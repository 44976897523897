import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GenericFilterDirective } from '../generic-filter/generic-filter';

@Component({
    selector: 'gg-filter-list-date',
    templateUrl: './gg-filter-list-date.component.html'
})
export class FilterListDateComponent<T> extends GenericFilterDirective<T> implements OnChanges {    
    @Input() public isDisabled = false;
    
    @Input() public initialDate: Date;

    @Input() public endDate: Date;

    public constructor(private datePipe: DatePipe) {
        super();
    }
    
    public ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        if (changes['dataTable'] && changes['dataTable'].currentValue && changes['dataTable'].firstChange && (this.initialDate || this.endDate)) {
            this.dataTable.filter = this.getNewFilter();
        }
    }

    public getFilterPredicate(data: T, filter: string): boolean {
        if (!this.filterName || !this.isFieldVisible) {
            return true;
        }

        const filterTerms = JSON.parse(filter);
        let searchTerm = new Array<string>();

        if (filterTerms[this.filterName] && (filterTerms[this.filterName].initialDate || filterTerms[this.filterName].endDate)) {
            searchTerm = filterTerms[this.filterName];
        }

        return this.dateIsMatch(searchTerm, data[this.filterName]);
    }

    public resetFilters(): void {
        this.initialDate = undefined;
        this.endDate = undefined;
        super.resetFilters();
    }

    public initialDateChange(event: any): void {
        if (event.value) {
            this.initialDate = event.value.toDate();
        }

        this.dataTable.filter = this.getNewFilter();
        this.filterChanged.emit();
    }

    public endDateChange(event: any): void {
        if (event.value) {
            this.endDate = event.value.toDate();
        }

        this.dataTable.filter = this.getNewFilter();
        this.filterChanged.emit();
    }

    private dateIsMatch(searchTerm: Array<string>, dataObj: any): boolean {
        if (!searchTerm || searchTerm.length === 0) {
            return true;
        }
        const objDateString: string = this.datePipe.transform(new Date(this.getValue(dataObj)), 'yyyy/MM/dd');

        const retValue = this.initialDate ? this.datePipe.transform(this.initialDate, 'yyyy/MM/dd') <= objDateString : true;
        return retValue && (this.endDate ? this.datePipe.transform(this.endDate, 'yyyy/MM/dd') >= objDateString : true);
    }

    private getNewFilter(): string {
        let newFilter = {};

        if (this.dataTable.filter) {
            newFilter = JSON.parse(this.dataTable.filter);
        }

        newFilter[this.filterName] = {};

        if (this.initialDate) {
            const valueIni = this.datePipe.transform(this.initialDate, 'dd/MM/yyyy');
            newFilter[this.filterName]['initialDate'] = valueIni;
        }

        if (this.endDate) {
            const valueEnd = this.datePipe.transform(this.endDate, 'dd/MM/yyyy');
            newFilter[this.filterName]['endDate'] = valueEnd;
        }

        return JSON.stringify(newFilter);
    }
}
