import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'api/authentication-service/authentication.service';
import { ILoggedUserInfo } from 'api/authentication-service/logged-user-info.model';
import { ICreditor } from 'api/creditors-service/creditor.model';
import { CreditorsService } from 'api/creditors-service/creditors.service';
import { IProceeding } from 'api/proceedings-service/proceeding.model';
import { ProceedingsService } from 'api/proceedings-service/proceedings.service';
import { IUser } from 'api/user-service/user.model';
import { UserService } from 'api/user-service/user.service';
import { IUserType, UserTypes } from 'api/user-service/user.type.model';
import { IMyAccountModalSettings, MyAccountLoginModalComponent } from 'app/components/myaccount-login-modal/myaccount-login-modal.component';
import { AuthenticatedUserCacheService } from 'app/services/authenticated-user-cache/authenticated-user-cache.service';
import { LoginService } from 'app/services/login/login.service';
import { of, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'gioc-access-button',
    templateUrl: './access-button.component.html'
})
export class AccessButtonComponent implements OnInit, OnDestroy {

    @ViewChild('loginModalComponent', { static: true }) loginModalComponent: MyAccountLoginModalComponent;
    @Output() public isUserLoggedChanged = new EventEmitter<ILoggedUserInfo>();
    public isUserLogged: boolean;
    public name: string;
    public surname: string;
    public email: string;
    public isAdmin: boolean;
    public userTypeCode: number;
    public hasUserRights: boolean;
    public creditorName: string;    
    
    private returnUrlSubscription: Subscription;
    private referrerUrl: string;
    private hasUserToken: boolean;

    public constructor(
        private readonly loginService: LoginService,
        private readonly authenticatedUserProvider: AuthenticatedUserCacheService,
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UserService,
        private readonly proceedingService: ProceedingsService,
        private readonly creditorService: CreditorsService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.initialize();

        this.returnUrlSubscription = this.loginService.getReturnUrl.subscribe(url => {
            this.referrerUrl = url;
        });
    }

    private initialize(): void {
        this.authenticationService.getLoggedUserInfo()
            .pipe(
                map((loggedUserInfo: ILoggedUserInfo) => {
                    this.isUserLogged = true;
                    this.setLoggedUserInfo(loggedUserInfo);
                    return of();
                }),
                mergeMap(() => this.userService.synchronizeUser()),                                    
                mergeMap(() => this.userService.getUsersByEmail(this.email))
            ).subscribe((users: IUser[]) => {
                this.userTypeCode = users[0].userType.code;
                this.authenticatedUserProvider.setCurrentUserType(users[0].userType);                
            }, () => {
                this.isUserLogged = false;
                this.hasUserRights = false;
                this.isAdmin = false;
            });
    }

    public ngOnDestroy(): void {
        if (this.returnUrlSubscription) {
            this.returnUrlSubscription.unsubscribe();
        }
    }

    public IsBankruptcyAdministrator(): boolean {
        if (this.userTypeCode) {
            return this.userTypeCode === UserTypes.BankruptcyAdministrator;
        }

        return false;
    }

    public IsCreditor(): boolean {
        if (this.userTypeCode) {
            return this.userTypeCode === UserTypes.Creditor;
        }

        return false;
    }

    public loginScriptHasLoaded(): void {
        const token = this.activatedRoute.snapshot.queryParams['token'];
        if (token) {
            this.userService.getUserByToken(token).subscribe((user: IUser) => {
                this.hasUserToken = true;
                this.openMyAccountLoginModal(user.email);
            }, () => {
                this.hasUserToken = false;
            });
        } else if (this.referrerUrl) {
            this.openMyAccountLoginModal();
        }
    }

    public openLoginModalRequested(): void {
        this.loginService.isUserLoggedIn().subscribe((result: boolean) => {
            if (result) {
                this.redirectToUserTypeUrl();
            } else {
                this.openMyAccountLoginModal();
            }
        });
    }

    public async redirectToUrl(): Promise<void> {
        const loggedUserInfo = await this.authenticationService.getLoggedUserInfo().toPromise() as ILoggedUserInfo;
        this.authenticatedUserProvider.setLoggedUserInfo(loggedUserInfo);
        this.setLoggedUserInfo(loggedUserInfo);

        this.isUserLogged = true;
        this.isUserLoggedChanged.emit(loggedUserInfo);

        let userWithGiocondaClaims: boolean;

        await this.userService.synchronizeUser().toPromise()
        .then(
            () => {
                userWithGiocondaClaims = true;
            },
            () => {
                userWithGiocondaClaims = false;
            }
        );

        if (!userWithGiocondaClaims)
        {
            this.router.navigate(['/public/home']);
            return;
        }

        const users = await this.userService.getUsersByEmail(loggedUserInfo.email).toPromise() as Array<IUser>;
        const userTypes: IUserType[] = users.map(user => user.userType);

        this.userTypeCode = userTypes[0].code;
        this.authenticatedUserProvider.setCurrentUserType(users[0].userType);

        const token: string = this.activatedRoute.snapshot.queryParams['token'];
        const creditorId: string = this.activatedRoute.snapshot.queryParams['creditorId'];

        const validEmail = typeof (this.email) !== 'undefined' && this.email !== null && this.email !== '';
        const validReferrer = typeof (this.referrerUrl) !== 'undefined' && this.referrerUrl !== null && this.referrerUrl !== '';
        const validToken = typeof (token) !== 'undefined' && token !== null && token !== '' && this.hasUserToken;
        const validReferrerAndEmail = validReferrer && validEmail;
        const validReferrerAndNoEmail = validReferrer && !validEmail;
        const invalidReferrerAndValidToken = !validReferrer && validToken;

        if (validReferrerAndEmail) {
            await this.checkPreviousUserLogged(userTypes);
        } else if (validReferrerAndNoEmail) {
            this.router.navigate([this.referrerUrl]);
            this.loginService.returnUrl.next('');
        } else if (invalidReferrerAndValidToken) {
            await this.userService.deleteUserToken(token).toPromise();
            this.redirectToUserTypeUrl(creditorId);
        } else {
            this.redirectToUserTypeUrl(creditorId);
        }

        return Promise.resolve();
    }

    public clearUrl(): void {
        if (this.activatedRoute.snapshot.queryParams['token']) {
            window.history.replaceState({}, document.title, '/public/home');
        }
    }

    private async checkPreviousUserLogged(userTypes: Array<IUserType>): Promise<void> {

        const loggedUserInfo = await this.authenticationService.getLoggedUserInfo().toPromise();
        if (this.email === loggedUserInfo.email) {
            const params: {} = this.getReferrerUrlQueryParams();
            if (params) {
                const urlWithoutQueryParams: string = decodeURI(this.referrerUrl).split('?')[0];
                this.router.navigate([urlWithoutQueryParams], { queryParams: params });                        
            }
            else {
                this.router.navigate([this.referrerUrl]);
            }                              
        } else {
            this.userTypeCode = userTypes[0].code;
            this.redirectToUserTypeUrl();
        }

        this.loginService.returnUrl.next('');
        return Promise.resolve();
    }

    private getReferrerUrlQueryParams(): {} {
        const urlSegmented: string[] = decodeURI(this.referrerUrl).split('?');

        if (urlSegmented.length <= 1) {
            return undefined;
        }

        const queryParams: string[] = urlSegmented[1].split('&');
        const params = {};
        queryParams.forEach((queryParam: string) => {
            const values: string[] = queryParam.split('=');
            if (values.length === 1) {
                params[`${values[0]}`] = true;
            } 
            else if (values.length === 2) {
                params[`${values[0]}`] = values[1];
            }
        });

        return params;
    }

    private openMyAccountLoginModal(email?: string): void {
        const myAccountModalSettings: IMyAccountModalSettings = {
            email: email || '',
            showRegisterOption: typeof (email) !== 'undefined' && email !== null && email !== ''
        };
        this.loginModalComponent.openModal(myAccountModalSettings);
    }

    private redirectToUserTypeUrl(creditorId?: string): void {
        // Ahora mismo asumimos que solo tenemos un tipo de usuario. Cuando venga la historia de seleccionar el tipo de usuario trataremos el array         
        switch (this.userTypeCode) {
            case UserTypes.Creditor:
                if (!creditorId) {
                    this.creditorService.getCreditorsByUserId().subscribe((creditors: Array<ICreditor>) => {
                        creditorId = creditors[0].id;
                        this.creditorName = creditors[0].name;

                        this.recoverProceedingsByCreditorAndUserId(creditorId);
                    }, () => {
                        this.setCurrentUserAsCreditorWithNoProceedings();
                    });

                } else {
                    this.recoverProceedingsByCreditorAndUserId(creditorId);
                }
                break;
            case UserTypes.BankruptcyAdministrator:
                this.router.navigate(['/manager/proceedings']);
                break;
            default:
                this.router.navigate([`/public/unauthorized`], {queryParams: {type : '1'}});
                break;
        }
    }

    private recoverProceedingsByCreditorAndUserId(creditorId: string): void {
        this.proceedingService.getProceedingsByCreditorIdAndUserId(creditorId).subscribe((proceedings: Array<IProceeding>) => {
            if (proceedings.length > 1) {
                this.router.navigate([`creditor/${creditorId}/proceedings`]);
            }
            else if (proceedings.length === 1) {
                this.router.navigate([`creditor/${creditorId}/proceedings/${proceedings[0].id}`]);
            }
        });
    }

    private setCurrentUserAsCreditorWithNoProceedings(): void {        
        this.isUserLogged = true;
        this.router.navigate(['/public/unauthorized'], {queryParams: {type : '3'}});
    }

    private setLoggedUserInfo(loggedUserInfo: ILoggedUserInfo): void {
        this.email = loggedUserInfo.email;
        this.name = loggedUserInfo.name;
        this.surname = loggedUserInfo.surname;
        this.isAdmin = loggedUserInfo.isAdmin;
        this.hasUserRights = loggedUserInfo.hasUserRights;
    }
}
