import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, merge } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpEventType } from '@angular/common/http';
import { ApiOperation, ApiProcessorService, ApiRequest} from 'api/api-processor-service/api-processor.service';
import { IApiProcessorFiles, IApiProcessorFilesResult } from 'api/api-processor-service/api-processor-files.model';
import { UntypedFormArray } from '@angular/forms';
import { MobileService } from 'app/services/mobile-service/mobile.service';
import { CompressImageService } from '../../services/compress-images/compress-image.service';
import { take } from 'rxjs/operators';
import { Router, UrlTree } from '@angular/router';
import { LoginService } from 'app/services/login/login.service';

export interface IProcessorFiles extends IApiProcessorFiles {
    toDelete: boolean;
    toUpload: boolean;
    errorDeleting: boolean;
    errorUpdatingFile: boolean;
    errorUpdatingFileMessage: string;
    warningFile: boolean;
    maxFileExceeded: boolean;
    progress: number;
    validExtension: boolean;
    validSize: boolean;
    maxSizeFilesExceeded: boolean;
    imgBase64: SafeResourceUrl | ArrayBuffer;
}

export interface IProcessorImageFile extends IProcessorFiles {
    height: number;
    width: number;
    validDimension: boolean;
}

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls : ['./file-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FileManagerComponent {

    public static readonly URL_FILE_ID_PARAM = ':fileId';
   
    @ViewChild('fileDropRef', { static: false }) fileDropRef: { nativeElement: { value: string; }; };

    @Input() allowedFilesType: any[] = ['txt', 'doc', 'docx', 'xls', 'xlsx', 'pdf']; 
    @Input() forbiddenFilesType: any[] = [];
    @Input() maxFilesAttached = 20;
    @Input() maxFileSize = 10485760;
    @Input() maxAttachedFilesSize: number;
    @Input() url: string = null;
    @Input() set currentAttachedFiles(currentAttachedFiles: IApiProcessorFiles[]) { 
        this.initializeByServer(currentAttachedFiles); 
    }
    @Input() color = 'primary';
    @Input() mode = 'determinate';
    @Input() allowDonwnload = true; 
    @Input() hasToDeleteOnSubmit = true;
    @Input() maxUploadSimultaneousFiles = 5;
    @Input() downloadLink: string = null;
    @Input() maxHeight: number;
    @Input() maxWidth: number;
    @Input() previewLogo = false;
    @Input() disabled = false;
    @Input() set getDownloadLink(func: (id: string) => string){
        if (func){
            this.getDownloadLinkInternal = func;
        }
    }
    @Input() hasToSelectPrincipal = false;
    @Input() filesControls: UntypedFormArray = null;

    @Input() proceedingHasDocuments = false;

    @Output() principalImageChanged = new EventEmitter<IProcessorFiles>();
    @Output() uploadCompleted = new EventEmitter<IApiProcessorFilesResult[]>();
    @Output() deleteFileEventEmmit = new EventEmitter<string>();
   
    public isUploadingFiles = false;
    public files: any[] = [];
    public maxFilesAttachedError = false;
    public maxAttachedFilesSizeError = false;
    public filesToUpload: IProcessorFiles[] = [];
    public loadingData = true;

    public isMobile: boolean;
    

    private readonly imagesType: any[] = ['jpg', 'jpeg', 'gif', 'png'];
    
    public constructor(
        private apiProcessorService: ApiProcessorService, 
        private loginService: LoginService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef, 
        private sanitizer: DomSanitizer,
        private mobile: MobileService,
        private compressImage: CompressImageService) {
           this.isMobile = this.mobile.isMobile;
        }

    public openSelectionFileDialog(files: any): void {
        alert(files);
    }
    
    public fileBrowseHandler(files: any): void {
        this.initializeByBrowser(files);
    }

    public fileDropped(files: any): void{
        this.initializeByBrowser(files);
    }

    public selectFileAsPrincipal(selectedIndex: number): void {
        this.filesToUpload.forEach((file: IProcessorFiles, index: number) => {
            if (selectedIndex === index) {
               file.isPrincipal = true;
               this.principalImageChanged.emit(file);
            }
            else if (file.isPrincipal) {
               file.isPrincipal = false;
            }
        });
    }

    private initializeByBrowser(files: Array<any>): void {
        this.maxFilesAttachedError = false;
        const sortedFiles = [].slice.call(files).sort((a: any, b: any ) => a.name.localeCompare(b.name) );
        const images = sortedFiles.filter((x: any) => this.isImage(x.name));
        const filesWithoutImages = sortedFiles.filter((x: any) => !this.isImage(x.name));
        if (images.length > 0){
            this.initiliazeImageFilesFromBrowser(images);
        }
       
        if (filesWithoutImages.length > 0){
            this.initializeFilesFromBrowser(filesWithoutImages);
        }
    }  

    private initiliazeImageFilesFromBrowser(files: Array<any>): void{
        const observables: Array<Observable<any>> = new Array<Observable<any>>();  
        files.forEach((x: Observable<any>) => observables.push(this.loadImage(x)));

        merge(... observables).subscribe({
            complete: () => {
                this.filesToUpload.sort((a, b) => a.name.localeCompare(b.name) );
                this.fileDropRef.nativeElement.value = '';
                this.checkmaxFilesAttached();
                this.markFirstAsPrincipalIfNotExistOne();
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    private markFirstAsPrincipalIfNotExistOne(): void {
        if (!this.hasToSelectPrincipal || this.filesToUpload.length === 0) {
            return;
        }

        const existingPrincipal = this.filesToUpload.filter(f => f.isPrincipal && !f.toDelete);

        if (existingPrincipal === null || existingPrincipal === undefined || existingPrincipal.length === 0) {
            const hasErrors = (f: IProcessorFiles) => f.maxFileExceeded || f.maxSizeFilesExceeded || !f.validExtension || !f.validSize;
            this.selectFileAsPrincipal(this.filesToUpload.findIndex(f => !f.toDelete && !hasErrors(f)));
        }
    }
    
    private initializeFilesFromBrowser(files: Array<any>): void {
        let fileToUpload: IProcessorFiles;
        this.isUploadingFiles = true;
        for (const file of files) {
            fileToUpload = this.initializeProcessorFileFromBrowser(file);
            this.pushFileToUpload(fileToUpload);
        }

        this.filesToUpload.sort((a, b) => a.name.localeCompare(b.name) );
        this.fileDropRef.nativeElement.value = '';
        this.checkmaxFilesAttached(); 
        this.isUploadingFiles = false; 
        this.changeDetectorRef.detectChanges();
    }

    
    private loadImage(imageFile: any): Observable<any>{
        return new Observable((observer) => {
            this.compressImage.compress(imageFile, this.maxFileSize, this.maxWidth).pipe(take(1)).subscribe(compressedImage => { 
                const imageInfo = new Image();
                const url = window.URL.createObjectURL(compressedImage);
                imageInfo.src = url;
                imageInfo.onload = () => {
                    const fileToUpload: IProcessorImageFile = this.initializeProcessorImageFromBrowser(compressedImage, imageInfo);
                    this.getImgBase64(fileToUpload);
                    this.pushFileToUpload(fileToUpload);
                    imageInfo.remove();
                    window.URL.revokeObjectURL(url);
                    observer.complete();
                };
            });
        });
    }

    private initializeProcessorImageFromBrowser(file: any, imageInfo: any): IProcessorImageFile {
        
        const fileToUpload: IProcessorImageFile = {
            progress : 0,
            validExtension : this.isValidExtension(file.name),
            validSize: file.size < this.maxFileSize,
            errorDeleting: false,
            errorUpdatingFile: false,
            warningFile: false,
            file: file,
            name: this.filesToUpload.some(x => x.name === file.name && !x.toDelete) ? this.setNewName(file.name) : file.name,
            isUploaded: false,
            errorUpdatingFileMessage: '',
            toDelete: false,
            toUpload: true,
            maxFileExceeded: false,
            imgBase64: '',
            id: null,
            width: imageInfo.width,
            height: imageInfo.height,
            validDimension: this.maxWidth && this.maxHeight ? imageInfo.width <= this.maxWidth && imageInfo.height <= this.maxHeight : true,
            maxSizeFilesExceeded: false
        };

        return fileToUpload;
    }

    private initializeByServer(filesToAttach: IApiProcessorFiles[]): void{
        this.loadingData = false;
        if (filesToAttach !== undefined && filesToAttach !== null) {
            let processorFile: IProcessorFiles;
            filesToAttach.forEach(x => {
                processorFile = this.initializeProcessorFileFromApi(x);
                if (this.isImage(processorFile.name)){
                    const processorFileImage = processorFile as IProcessorImageFile;
                    processorFileImage.validDimension = true;
                    this.recoverThumbnail(processorFile);
                    this.filesToUpload.push(processorFileImage);
                }
                else {
                    this.filesToUpload.push(processorFile);
                }
            });

            this.filesToUpload.sort((a, b) => a.name.localeCompare(b.name) );
            this.checkmaxFilesAttached();
        }
    }
   
    private initializeProcessorFileFromBrowser(file: any): IProcessorFiles {
        const fileToUpload: IProcessorFiles = {
            progress : 0,
            validExtension : this.isValidExtension(file.name),
            validSize: file.size < this.maxFileSize,
            errorDeleting: false,
            errorUpdatingFile: false,
            warningFile: false,
            file: file,
            name: this.filesToUpload.some(x => x.name === file.name && !x.toDelete) ? this.setNewName(file.name) : file.name,
            isUploaded: false,
            errorUpdatingFileMessage: '',
            toDelete: false,
            toUpload: true,
            maxFileExceeded: false,
            imgBase64: '',
            id: null,
            maxSizeFilesExceeded: false
        };
        return fileToUpload;
    }
          
    private initializeProcessorFileFromApi(file: IApiProcessorFiles): IProcessorFiles{
        const processorFile: IProcessorFiles = {
            errorDeleting: false,
            errorUpdatingFile: false,
            errorUpdatingFileMessage: '',
            warningFile: false,
            file: file.file,
            isUploaded: file.isUploaded,
            name: file.name,
            progress: 100,
            toDelete: false,
            toUpload: !file.isUploaded,
            validExtension: this.isValidExtension(file.name),
            validSize: true,
            maxFileExceeded: false,
            imgBase64: '',
            id: file.id,
            maxSizeFilesExceeded: false,
            isPrincipal: file.isPrincipal
        };
        return processorFile;
    }
        
    public isImage(fileName: string): boolean {
        const extension: string = this.getExtension(fileName);
        if (extension === '' || !this.imagesType.includes(extension)) { return false; }
        return true;
    }

    public getImgBase64(file: IProcessorFiles): void {               
        const reader = new FileReader();        
        reader.readAsDataURL(file.file); 
        reader.onloadend = (event: Event) => {            
            file.imgBase64 =  reader.result;                
            this.changeDetectorRef.detectChanges();             
        };      
    }

    private pushFileToUpload(fileToUpload: IProcessorFiles): void {
        fileToUpload = this.checkErrorsInFileToUpload(fileToUpload);
        this.filesToUpload.push(fileToUpload);             
    }
     
    private checkErrorsInFileToUpload(fileToUpload: IProcessorFiles): IProcessorFiles {
        
        if (this.isImage(fileToUpload.name)){
            const image = fileToUpload as IProcessorImageFile;
            if (!image.validSize || !image.validExtension || !image.validDimension) {
                fileToUpload.toUpload = false;
            }
        }else if (!fileToUpload.validSize || !fileToUpload.validExtension){             
            fileToUpload.toUpload = false;            
        }
        
        return this.checkMaxFileExceededInFile(fileToUpload);
    }

    private checkMaxFileExceededInFile(fileToUpload: IProcessorFiles): IProcessorFiles {        
        const validFilesImages: IProcessorFiles[] = this.getUploadValidImages();    
        const validFilesWithoutImages: IProcessorFiles[] = this.getValidFiles();
        const validFiles = validFilesImages.concat(validFilesWithoutImages);     


        let  currentAttachedSize = 0;
        validFiles.forEach((x: IProcessorFiles) =>
        { 
            if (x.file !== null && typeof(x.file) !== 'undefined' && x.file.size !== null){
                currentAttachedSize += x.file.size;
            }
        });

        if (fileToUpload.file !== null && fileToUpload.file.size !== null){
            currentAttachedSize += fileToUpload.file.size;
        }
        let maxSizeExceeded = false;
        if ( typeof (this.maxAttachedFilesSize ) !== 'undefined' ){
            maxSizeExceeded = currentAttachedSize < this.maxAttachedFilesSize;
        }

        if (validFiles.length < this.maxFilesAttached && ! maxSizeExceeded) { 
            return fileToUpload;
        }            
        fileToUpload.isUploaded = false;
        fileToUpload.maxFileExceeded = validFiles.length >= this.maxFilesAttached;
        fileToUpload.toUpload = false;
        fileToUpload.toDelete = false;
        fileToUpload.warningFile = true;
        fileToUpload.maxSizeFilesExceeded = typeof (this.maxAttachedFilesSize ) !== 'undefined' && currentAttachedSize > this.maxAttachedFilesSize;
        
        this.maxAttachedFilesSizeError = typeof (this.maxAttachedFilesSize ) !== 'undefined' && currentAttachedSize > this.maxAttachedFilesSize;
        
        return fileToUpload;
        
    }

    private getUploadValidImages(): IProcessorImageFile[] {        
        const images = this.filesToUpload.filter(x => {  
                const image = x as IProcessorImageFile;          
                return image !== null && typeof(image) !== 'undefined' && image.validSize &&
                       image.validExtension && !image.maxFileExceeded && !image.toDelete && image.validDimension;
        }).map(y => y as IProcessorImageFile);
                 
        return images;
    }

    private getValidFiles(): IProcessorFiles[] {
        return this.filesToUpload.filter(x => !this.isImage(x.name) && x.validSize && x.validExtension && !x.maxFileExceeded && !x.toDelete);
    }

    public IsValidImage(image: any): boolean{       
        if (image.width && image.height){
            return image.validDimension;
        } 
        return true;
    }

    public submitFiles(): void {   
        const filesToDelete: IProcessorFiles[] = this.filesToUpload.filter( x => x.toDelete);
        if (filesToDelete.length === 0) {
            this.uploadFiles();
            return;
        }

        this.deleteFiles(filesToDelete).subscribe(
            {               
                complete: () => {
                    this.uploadFiles();             
                },
                error: () => {
                    this.checkUploadFileStatus();  
                }
            }
        );
    }

    public getFiles(): IApiProcessorFiles[]{
        if (this.filesToUpload !== undefined){       
            const validImages = this.getUploadValidImages();                
            const validFiles = this.getValidFiles();
            return validFiles.concat(validImages);
        }
        return null;
    }

    public downloadFile(name: string, id: string, $event: any): void{
        const file = this.filesToUpload.find(x => x.name === name);
        if (file.file && file.file.size > 0) {
            const ext = name.substring(name.lastIndexOf('.') + 1, name.length).toLowerCase();
            let openNewTab = false;
            if (ext === 'pdf' || ext === 'txt')
            {
                openNewTab = true;
            }

            this.apiProcessorService.saveDownloadedFile(file.file, name, file.file.type, openNewTab);
        }else{
            const url = this.getDownloadLinkInternal(id);
            if (file !== undefined && file.isUploaded) {           
                this.apiProcessorService.downloadFile(url , name ).subscribe({
                    error: (error: any) => 
                    {   
                        if (error instanceof UrlTree) {
                            this.loginService.returnUrl.next(this.router.url);
                            this.router.navigate([error.toString()]);                 
                        }
                    }
                });
        }
        }
    }

    public deleteFile(name: string, $event: any): void {
        $event.preventDefault();
        if (this.isUploadingFiles) { return; }

        if (this.deleteFileEventEmmit.observers.length > 0)
        {
            this.deleteFileEventEmmit.emit(name);
            return;
        }
        
        const files: IProcessorFiles[] = this.filesToUpload.filter(x => x.name === name); 
        
        if (this.hasToDeleteOnSubmit) {
            for (const file of files) {
                if (file.progress === 0) {
                    this.filesToUpload.splice(this.getIndexByObj(file), 1);
                } else {
                    file.toDelete = true;
                    file.toUpload = false;
                }
            }
            this.checkmaxFilesAttached();
        }
        else{
            if (this.url === null){ return; }
    
            for (const file of files) {  
                if (file.progress === 100 && this.url !== null){
                   const filesToDelete: IProcessorFiles[] = [];
                   filesToDelete.push(file);
                   this.deleteFiles(filesToDelete).subscribe();
                }
                else if (file.progress === 0)
                {
                    this.filesToUpload.splice(this.getIndexByObj(file), 1);
                    this.checkmaxFilesAttached();
                } 
            }
        }
        this.markFirstAsPrincipalIfNotExistOne();
    }
      
    public deleteErrorFile(name: string, $event: any): void{
        $event.preventDefault();
        if (this.isUploadingFiles) { return; }        
    
        const file = this.filesToUpload.find(x => x.name === name); 
        if (file.errorDeleting){
            this.deleteFile(name, $event);
            return;
        }
        const index = this.getIndexByObj(file);
        if (index === -1) { return; }

        this.filesToUpload.splice(index, 1);  
        this.checkmaxFilesAttached();         
    }

    public reportProgress(fileName: string, percent: number): void{
        const file = this.filesToUpload.find(x => x.name === fileName); 
        if (file !== null && typeof (file) !== 'undefined'){
            file.progress = percent;
            this.changeDetectorRef.detectChanges();
        }
    }

    public formatBytes(bytes: any, decimals: any): string {
        if (bytes === null) { return ''; }
        if (bytes === 0) { return '0 Bytes'; }

        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    public getExtension(fileName: string): string {
        return typeof fileName !== 'undefined' ? fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase() : '';
    }

    public validExtensionsText(): string {
        if (this.allowedFilesType.length === 1){
            return this.allowedFilesType[0];
        }
        const list = this.allowedFilesType.join(', ');    
        const lastComma = list.lastIndexOf(',');
        return list.substring(0, lastComma) + ' o ' + list.substring(lastComma + 1);
    }

    public isTypeImage(): boolean {
        return this.allowedFilesType.includes('jpg' || 'jpeg' || 'gif' ||  'png');
    }

    public validExtensionInput(): string{
        return '.' + this.allowedFilesType.join(',.'); 
    }
    
    private getDownloadLinkInternal(id: any): string{
        let url = this.downloadLink ? this.downloadLink : this.url;
        if (!url.endsWith('/')){ url += '/'; }
        url = url.replace(FileManagerComponent.URL_FILE_ID_PARAM, id);
        return url;
    }

    private recoverThumbnail(processorFile: IProcessorFiles): void{
        if (processorFile.imgBase64 !== '') {
            return;
        }
        if (processorFile.file === null || processorFile.file === undefined){
            if (this.url !== null){
                const url = this.getDownloadLinkInternal(processorFile.id);
                
                this.apiProcessorService.getFile(url).subscribe({
                    next: (arrayBuffer: ArrayBuffer) =>
                    {                       
                        const blob = new Blob([arrayBuffer], {type: 'application/octet-binary'});
                        const reader = new FileReader();
                        reader.readAsDataURL(blob); 
                        reader.onloadend = (event: Event) => {
                            processorFile.imgBase64 =  this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
                            this.changeDetectorRef.detectChanges(); 
                        };  
                    }
                });
            }
            return;
        }
        this.getImgBase64(processorFile);
    }   

    private checkmaxFilesAttached(): void{

        this.maxFilesAttachedError = this.filesToUpload.filter(x => !x.toDelete).length > this.maxFilesAttached; 
        const filesToUpload = this.filesToUpload.filter(x => !x.toDelete && x.toUpload); 
      
        filesToUpload.forEach((file: IProcessorFiles) => {
            if (this.filesToUpload.filter(x => x.toUpload || (x.isUploaded && !x.toDelete) ).length < this.maxFilesAttached){ 
                if (!file.isUploaded){       
                    file.maxFileExceeded = false;                    
                    file.toUpload = true;                     
                }
            }     
        });       

        let  currentAttachedSize = 0;    
        if (typeof (this.maxAttachedFilesSize ) !== 'undefined' &&  this.maxAttachedFilesSize > 0){
            filesToUpload.forEach((file: IProcessorFiles) => {            
                if (file.file.size !== null){
                    if (typeof (this.maxAttachedFilesSize ) !== 'undefined' && currentAttachedSize + file.file.size > this.maxAttachedFilesSize){                    
                        file.maxSizeFilesExceeded = true;
                        file.toUpload = false;                               
                    }else{
                        file.maxSizeFilesExceeded = false;
                        file.toUpload = true;
                    }    
                    currentAttachedSize += file.file.size;    
                }        
            }); 
        }     
        this.maxAttachedFilesSizeError = typeof (this.maxAttachedFilesSize ) !== 'undefined' &&  currentAttachedSize > this.maxAttachedFilesSize;        

        this.changeDetectorRef.detectChanges(); 
    }

    private setNewName(name: string): string{
        const ext = this.getExtension(name); 
        const nameWithoutExtension = name.replace(`.${ext}`, '');
        let cont = 0;
        let found = this.filesToUpload.some(x => x.name === name);
        
        while (found) {
            cont++;
            found = this.filesToUpload.some(x => x.name === `${nameWithoutExtension}(${cont}).${ext}`);            
        }   

        return `${nameWithoutExtension}(${cont}).${ext}`;
    }

    private isValidExtension(fileName: string): boolean {
        const extension: string = this.getExtension(fileName);
        if (extension === '') { return false; }
       
        if (this.forbiddenFilesType.length === 0){
            if (this.isImage(fileName)){
                return this.allowedFilesType.includes(extension) && this.imagesType.includes(extension);
            }else {
                return this.allowedFilesType.includes(extension);
            }        
        }else{
            return !this.forbiddenFilesType.includes(extension);
        }
    }   

    private uploadFiles(): void{

        const observables: Array<Observable<any>> = new Array<Observable<any>>();
        const filesToUpload: IProcessorFiles[] = this.filesToUpload.filter(x => x.toUpload);

        if (filesToUpload.length === 0){
            const filesResult: IApiProcessorFilesResult[] = [];
            this.uploadCompleted.emit(filesResult);
            return;
        }

        for (const fileToSubmit of filesToUpload){
            observables.push(this.uploadFile(fileToSubmit));
        }

        merge(...observables, this.maxUploadSimultaneousFiles).subscribe(
        {
            complete: () => {
                console.log('Complete on upload file after delete file');
                this.checkUploadFileStatus(); 
            }, 
            error: () => {
                console.log('Error on upload file after delete file');
                this.checkUploadFileStatus();
            }
        });
    }

    private uploadFile(fileToUpload: IProcessorFiles): Observable<any> {
        this.isUploadingFiles = true;
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload.file, fileToUpload.name);
        if (fileToUpload.isPrincipal !== undefined) {
            formData.append('isPrincipal', fileToUpload.isPrincipal.toString());
        }
        
        return new Observable((observer) =>
        {
            this.apiProcessorService.postObject(this.url, formData).subscribe(
                {
                next: (event: any) => {
                    
                    if (typeof event === 'undefined') {
                        return;
                    }
                    if (event.type === HttpEventType.UploadProgress) {
                        fileToUpload.progress = ((event.loaded * 100) / event.total);
                        this.changeDetectorRef.detectChanges();
                    }
                    else if (event.type === HttpEventType.Response && event.body.id){
                        fileToUpload.id = event.body.id;
                    }

                  },
                  complete: () => {
                    fileToUpload.progress = 100;                    
                    fileToUpload.isUploaded = true;   
                    fileToUpload.toUpload = false;
                    observer.complete();
                  },
                  error: (error: any) => {
                    if (this.fileHasWarning(fileToUpload)){
                        fileToUpload.warningFile = true;
                    }
                    else{
                        fileToUpload.errorUpdatingFile = true;
                    }
                    fileToUpload.errorUpdatingFileMessage = this.getErrorMessage(error.xMLHttpRequest);                
                    fileToUpload.progress = 100;
                    fileToUpload.isUploaded = false;
                    fileToUpload.toUpload = true;
                    observer.complete(); 
                  } 
                });
        });
    }	

    private fileHasWarning(file: IProcessorFiles): boolean{
        return !file.validSize || file.maxFileExceeded || !file.validExtension;
    }

    private checkUploadFileStatus(): void {               
        this.isUploadingFiles = false;
        const filesResult: IApiProcessorFilesResult[] = [];
        
        for (const file of this.filesToUpload){
            if (file.maxFileExceeded){
                file.errorUpdatingFileMessage = 'Límite de ficheros excedido';
            }
            const fileResult: IApiProcessorFilesResult = 
             {
                 name: file.name,
                 error: file.errorUpdatingFile,
                 warning: file.warningFile,
                 errorMessage: file.errorUpdatingFile || file.warningFile ? file.errorUpdatingFileMessage : ''
             };
            filesResult.push(fileResult);
        }

        this.uploadCompleted.emit(filesResult);             
    }      

    private deleteFiles(files: IProcessorFiles[]): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
           
        apiRequest.headers  = new HttpHeaders({ 'Content-Type': 'application/json' });

        let ids = '';       
        for (const file of files) {  
            ids += file.id + ',';
        }
        const lastComma = ids.lastIndexOf(',');
        ids = ids.substring(0, lastComma);
        apiRequest.endpointUrl = `${this.url}?ids= ${ids}` ;    
        return new Observable((observer) => {           
            this.apiProcessorService.proccessRequest(apiRequest).subscribe({                        
                complete: () => {
                    for (const file of files) {  
                        const index = this.getIndexByObj(file);
                        if (index !== -1) { 
                            this.filesToUpload.splice(index, 1 );  
                        }                   
                    }                 
                    observer.complete();                   
                },
                error: () => {
                    for (const file of files) {  
                        console.log('Error Deleted file' + file.name + '-- ' + Date()) ;          
                        file.errorDeleting = true;
                    }
                    observer.error(files);
                }
            });
        });   

    }

    private getIndexByObj(file: IProcessorFiles): number {
        const fileStf = JSON.stringify(file);
        for (let i = 0; i < this.filesToUpload.length; i++){
            const fileToUpload = JSON.stringify(this.filesToUpload[i]);
            if ( fileStf ===  fileToUpload)  
            {
                return i;
            }
        }
        return -1;
    }

    private getErrorMessage(xMLHttpRequest: any): string {    
        try{    
            if (xMLHttpRequest !== undefined && xMLHttpRequest.response !== undefined) {  
                if (xMLHttpRequest.response === ''){
                    return 'Se produjo un error al enviar el fichero';
                }
                const response: any = JSON.parse(xMLHttpRequest.response);
                if (response !== undefined) {
                    if (response['file'] !== undefined && response['file'][0] !== undefined) {
                        return response['file'][0];
                    }
                    else if (response.status !== undefined){
                        return `Se produjo un error al enviar el fichero, error:  ${response.status}`;
                    }
                }
            }
            return 'Se produjo un error al enviar el fichero'; 
        }catch
        {
            return 'Se produjo un error al enviar el fichero'; 
        }
    }

}
