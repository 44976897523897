import { Injectable } from '@angular/core';
import { IProceeding } from 'api/proceedings-service/proceeding.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProceedingProviderService {

    private proceedingSource = new Subject<IProceeding>();
    private proceedingLiquidationRequestStatusCodeSource = new Subject<number>();

    public get proceedingSourceObservable(): Observable<IProceeding> {
        return this.proceedingSource.asObservable();
    }

    public get proceedingLiquidationRequestStatusCodeSourceObservable(): Observable<number> {
        return this.proceedingLiquidationRequestStatusCodeSource.asObservable();
    }

    public shareProceeding(proceeding: IProceeding): void {
        this.proceedingSource.next(proceeding);
    }

    public shareProceedingLiquidationRequestStatusCode(code: number): void {
        this.proceedingLiquidationRequestStatusCodeSource.next(code);
    }    
}
