import { Injectable } from '@angular/core';
import { Configuration } from 'api/configuration-service/configuration.model';

@Injectable({
  providedIn: 'root'
})
export class CookieEloquaService {
  private script: HTMLScriptElement; 
  private scriptCampaign: HTMLScriptElement; 

  constructor(private readonly environment: Configuration) { }
  
  public init(): void {
    if (this.environment.includeEloquaCookie) {
        const _elqQ = [];
        this.script = document.createElement('script');
        this.script.onload = () => { 
            _elqQ.push(['elqSetSiteId', '1364398973']);
            _elqQ.push(['elqTrackPageView']);
            _elqQ.push(['elqSetSiteId', '4793349']);
            _elqQ.push(['elqUseFirstPartyCookie', 'stat.laleynext.es']);
            _elqQ.push(['elqTrackPageView']);
        };
        this.script.src = this.environment.cookieEloquaUrl;
        this.script.async = true;
        this.script.defer = true;
        document.body.appendChild(this.script);

        this.scriptCampaign = document.createElement('script');
        this.scriptCampaign.src = this.environment.contactFormScript;
        this.scriptCampaign.async = true;
        this.scriptCampaign.defer = true;
        document.body.appendChild(this.scriptCampaign);
    }  
  }
}
