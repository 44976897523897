import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Observable } from 'rxjs';
import { ICreditor } from './creditor.model';

@Injectable({
    providedIn: 'root'
})
export class CreditorsService {
    private endpointUrl: string;
    
    constructor(private readonly apiProcessor: ApiProcessorService, private readonly environment: Configuration) {
        
        this.endpointUrl = this.environment.apiUrl + 'creditor-management/creditors';
    }

    getCreditorByTaxAndOfficeId(taxId: string, officeId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }?cif=${ taxId }&officeId=${ officeId }`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    getCreditorById(creditorId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/${ creditorId }`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    getCreditorsByUserId(): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    deleteCreditor(creditorId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${ this.endpointUrl }/${ creditorId }`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }

    updateCreditor(creditor: ICreditor): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl =  `${ this.endpointUrl }/${ creditor.id }`;
        apiRequest.body = creditor;
        return this.apiProcessor.proccessRequest(apiRequest);
    }
}
