import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { Observable } from 'rxjs';
import { IOffice } from './office.model';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'api/configuration-service/configuration.model';


@Injectable({
    providedIn: 'root'
})
export class OfficeService {
    private endpointUrl: string;

    constructor(
        private readonly apiProcessorService: ApiProcessorService,
        private readonly http: HttpClient,
        private readonly environment: Configuration
        )
        {
            this.endpointUrl = this.environment.apiUrl + 'office-management/offices';
        }

    public getOfficesByUserId(): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.endpointUrl;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getUsersByOfficeId(officeId: string, userTypeCode?: number): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;

        if (userTypeCode) {
            apiRequest.endpointUrl = `${this.endpointUrl}/${officeId}/users?userTypeCode=${userTypeCode}`;
        }
        else {
            apiRequest.endpointUrl = `${this.endpointUrl}/${officeId}/users`;
        }
        
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getOfficeById(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/${ id }`;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }
       
    public getApplicationDocumentTemplates(officeId: string, phaseTypeCodes?: number[], isEnabledFilter?: boolean): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;        

        let url = `${this.endpointUrl}/${officeId}/application-document-templates`;
        if (phaseTypeCodes) {
            url += '?templatePhaseTypeCodes=';
            url += phaseTypeCodes.join(',');
        }
        
        if (isEnabledFilter) {
            url += `?isEnabled=${isEnabledFilter.toString()}`;
        }

        apiRequest.endpointUrl = url;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getCustomDocumentTemplates(officeId: string, isEnabledFilter?: boolean): Observable<any>{
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;                
        apiRequest.endpointUrl = `${this.endpointUrl}/${officeId}/custom-document-templates`;

        if (isEnabledFilter) {
            apiRequest.endpointUrl += `?isEnabled=${isEnabledFilter.toString()}`;
        }
        
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getApplicationEmailTemplates(officeId: string, isEnabledFilter?: boolean): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;        

        let url = `${this.endpointUrl}/${officeId}/application-email-templates`;
        
        if (isEnabledFilter) {
            url += `?isEnabled=${isEnabledFilter.toString()}`;
        }

        apiRequest.endpointUrl = url;
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getCustomEmailTemplates(officeId: string, isEnabledFilter?: boolean): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;        

        let url = `${this.endpointUrl}/${officeId}/custom-email-templates`;
        
        if (isEnabledFilter) {
            url += `?isEnabled=${isEnabledFilter.toString()}`;
        }

        apiRequest.endpointUrl = url;
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public saveOffice(office: IOffice): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = this.endpointUrl;
        apiRequest.body = office; 

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public updateOffice(id: string, office: IOffice): Observable<any> {

        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${ this.endpointUrl }/${ id }`;
        apiRequest.body = office;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    }

    public getOfficeLogo(officeId: string): Observable<Blob> {
        const endpointUrl = this.getOfficeLogoUrl(officeId);
        return this.http.get<Blob>(endpointUrl, {         
            responseType: 'blob' as 'json', withCredentials: true } 
           );
    }

    private getOfficeLogoUrl(officeId: string): string{
        return `${ this.endpointUrl }/${ officeId }/logo`;
    }
}
