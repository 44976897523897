import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from 'api/api-processor-service/api-processor.service';
import { IAsset } from 'api/assets-service/asset.model';
import { Configuration } from 'api/configuration-service/configuration.model';

import { ICreditGuarantee } from 'api/credit-guarantee-service/credit-guarantee.model';
import { parseAmountNumber } from 'app/utils/number-utils';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IAssetsRequest } from './assets-request';
import { IAssetsLiquidationRequest } from './assetsLiquidationRequest/assets-liquidation-request.model';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
    private endpointUrl: string;
    
    constructor(
        private readonly apiProcessorService: ApiProcessorService,
        private readonly http: HttpClient,
        private readonly environment: Configuration) {
        
        this.endpointUrl = this.environment.apiUrl + 'asset-management';
    }

    public getAssets(request: IAssetsRequest): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        const baseUrlEndpoint = this.environment.apiUrl + `proceeding-management/proceedings/${ request.proceedingId }/assets/?`;  
        
        const clonnedRequest = Object.assign({}, request);

        const params = [];
        const range = clonnedRequest['range'];

        delete clonnedRequest.range;
        delete clonnedRequest.proceedingId;

        if (range) {
            params.push(`range=${ range }`);
        }
                        
        for (const item in clonnedRequest) {
            if (item) {
                params.push(`${item}=${JSON.stringify(clonnedRequest[item])}`);
            }
        }
        
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ baseUrlEndpoint }&${ params.join('&') }`;

        return this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<IAsset>('getAsset')));
    }

    public getProceedingProductiveUnits(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.environment.apiUrl + `proceeding-management/proceedings/${ proceedingId }/productive-units`;

        return this.apiProcessorService.proccessRequest(apiRequest);  
    } 

    public getAsset(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/assets/${ id }`;     

        return this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<IAsset>('getAsset')));
    }

    public getAssetNotesByAssetId(assetId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/assets/${assetId}/asset-notes`;
        
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public saveAsset(asset: IAsset): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${ this.endpointUrl }/assets`;
        apiRequest.body = asset; 

        return this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<IAsset>('saveAsset')));
    }

    public updateAsset(id: string, asset: IAsset): Observable<any>  {
        asset.id = id;
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${ this.endpointUrl }/assets/${ id }`;
        apiRequest.body = asset; 

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public deleteAsset(assetId: string): Observable<any>{
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${ this.endpointUrl }/assets/${ assetId }`;    

        return this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<IAsset>('deleteAsset')));
    }

    public deleteAssetsCollection(assetsIds: string[]): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${ this.endpointUrl }/assets/`;
        apiRequest.body = JSON.stringify(assetsIds);

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getAssetFiles(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/assets/${ id }/files`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public downloadAssetFile(id: string, fileName: string, fileId: string): Observable<any> {
        let url = `${ this.endpointUrl }/asset-files/`;
        if (!url.endsWith('/')){ url += '/'; }
        url += fileId;
        return this.apiProcessorService.downloadFile(url, fileName);
    }

    public getAssetStockAssociationsWithAssetInfo(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }/proceedings/${ proceedingId }/asset-stock-associations-with-asset-info`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public importAssetsFile(proceedingId: string, dataFile: File): Observable<any> {
        const endpointUrl = this.environment.apiUrl + 'asset-management/assets/import';
        const reqBody = new FormData();
        reqBody.append('file', dataFile, dataFile.name);
        reqBody.append('proceedingId', proceedingId);

        return this.http.post(endpointUrl, reqBody, { withCredentials: true });
    }

    public calculateAssetGuarantees(assetId: string, guarantees: Array<ICreditGuarantee>, fairValue: number): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${ this.endpointUrl }/assets/${ assetId }/calculate-credit-guarantees`;
        apiRequest.body = {
            guarantees: guarantees,
            fairValue: parseAmountNumber(fairValue)
        };

        return this.apiProcessorService.proccessRequest(apiRequest)
            .pipe(catchError(this.handleError<IAsset>('calculateAssetGuarantees')));
    }
  
    public generateAssetsLiquidationRequest(assetsLiquidationRequest: IAssetsLiquidationRequest): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${ this.endpointUrl }/assets/generate-assets-liquidation-request`;
        apiRequest.body = assetsLiquidationRequest;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
        return (error: any): Observable<T> => {
            console.error(operation, error);
            return of(result);
        };
    }
}
