import { Injectable } from '@angular/core';
import { ApiProcessorService, ApiRequest, ApiOperation } from 'api/api-processor-service/api-processor.service';
import { Configuration } from 'api/configuration-service/configuration.model';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreditProceedingService {
    private endpointUrl: string;
    
    constructor(private readonly apiProcessor: ApiProcessorService, private readonly environment: Configuration) {
        
        this.endpointUrl = `${ this.environment.apiUrl }credit-management/proceedings`;
    }

    public getProceeding(proceedingId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.endpointUrl = `${ this.endpointUrl }/${ proceedingId }`;

        apiRequest.operation = ApiOperation.GET;        
        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getProceedingByCreditorId(proceedingId: string, creditorId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.endpointUrl = `${ this.endpointUrl }/${ proceedingId }?creditorId=${creditorId}`;    

        apiRequest.operation = ApiOperation.GET;        
        return this.apiProcessor.proccessRequest(apiRequest);
    }

    public getProceedingsByCreditorIdAndUserId(creditorId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.endpointUrl }?creditorId=${ creditorId }`;

        return this.apiProcessor.proccessRequest(apiRequest);
    }
}
