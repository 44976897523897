import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { LiquidationManagementButtonTypes } from 'app/components/gg-gioconda-liquidation-management-button/gg-gioconda-liquidation-management-button.component';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    
    @Input() layout = 'vertical';
    @Input() navigation: any;

    public navigationItems: any[];

    public liquidationManagementButtonTypes = LiquidationManagementButtonTypes;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                this._changeDetectorRef.markForCheck();
            });
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });
    }
}
