import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, UrlTree } from '@angular/router';
import { Configuration } from 'api/configuration-service/configuration.model';
import { PublicLinksButtonsService } from 'app/services/public-links-buttons/public-links-buttons.service';
import { IProceedingLiquidationRequestStatus } from 'api/proceeding-liquidation-request-status-service/proceeding-liquidation-request-status.model';
import { ProceedingLiquidationRequestStatusService } from 'api/proceeding-liquidation-request-status-service/proceeding-liquidation-request-status.service';
import { IProceeding } from 'api/proceedings-service/proceeding.model';
import { ProceedingProviderService } from 'app/services/proceeding-provider-service/proceeding-provider.service';
import { Subscription } from 'rxjs';
import { ProceedingLiquidationRequestService } from 'api/proceeding-liquidation-request-service/proceeding-liquidation-request.service';
import { StaticContentService } from 'api/static-content-service/static-content.service';
import { LoginService } from 'app/services/login/login.service';

@Component({
  selector: 'gg-gioconda-liquidation-management-info',
  templateUrl: './gg-gioconda-liquidation-management-info.component.html',
  styleUrls: ['./gg-gioconda-liquidation-management-info.component.scss']  
})
export class GiocondaLiquidationManagementInfoComponent implements OnInit, OnDestroy {

    public proceeding: IProceeding;
    public proceedingId: string;
    public pdfAuctions: string;
    public pdfLiquidations: string;
    public contactMail: string;
    public isProceedingForm: false;
    public proceedingLiquidationRequestStatusCode: number;
    public updateProceedingLiquidation = false;

    private proceedingLiquidationRequestStatuses: { [code: number]: IProceedingLiquidationRequestStatus; } = {};
    private subscription: Subscription;
    public readonly liquidationManualLocation = 'manual/Gioconda_Manual_Liquidaciones.pdf'
    private readonly WITHOUT_REQUEST_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE = 1;
    private readonly NEW_REQUEST_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE = 2;
    private readonly REJECTED_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE = 4;

    public constructor(
        public readonly publicLinksbuttons: PublicLinksButtonsService,
        private readonly environment: Configuration,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data,
        private readonly staticContentService: StaticContentService,
        private readonly loginService: LoginService,
        private readonly proceedingProviderService: ProceedingProviderService,
        private readonly proceedingLiquidationRequestStatusService: ProceedingLiquidationRequestStatusService,
        private readonly proceedingLiquidationRequestService: ProceedingLiquidationRequestService,
        private readonly router: Router,
    ) {
        this.contactMail = this.environment.contactMail;
        this.pdfAuctions = this.environment.auctionsPdfUrl;
        this.proceeding = data.proceeding;
        this.proceedingId = data.proceedingId;
        this.proceedingLiquidationRequestStatusCode = data.proceedingLiquidationRequestStatusCode;
        this.isProceedingForm = data.isProceedingForm;

    }

    public ngOnInit(): void {
        this.isPossibleLiquidation(this.proceedingLiquidationRequestStatusCode);
        this.loadProceedingLiquidationRequestStatuses();
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }    
    }

    public goToLink(link: any): void {
        this.publicLinksbuttons.goToLink(link);
    }

    public openHelp(path: string): void {
        this.staticContentService.downloadStaticFile(path).subscribe({
            error: (error: any) => {
                if (error instanceof UrlTree) {
                    this.loginService.returnUrl.next(this.router.url);
                    this.router.navigate([error.toString()]);                 
                }    
            }
        });
    }

    public updateProceedingLiquidationRequestStatus(): void  {
        if (this.updateProceedingLiquidation && this.proceeding) {
            this.proceedingLiquidationRequestService.requestLiquidation(this.proceeding.id).subscribe(() => {
                this.proceeding.proceedingLiquidationRequestStatus = 
                    this.proceedingLiquidationRequestStatuses[this.NEW_REQUEST_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE];

                this.proceeding.proceedingLiquidationRequestStatusId = 
                    this.proceedingLiquidationRequestStatuses[this.NEW_REQUEST_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE].id;

                this.proceedingProviderService.shareProceeding(this.proceeding);
                this.router.navigate(['/manager/proceedings/' + this.proceedingId + '/assets-proceeding']); 
            });
        }
        this.dialogRef.close();
    }

    public isPossibleLiquidation(proceedingLiquidationRequestStatusCode: number): void  {        
        if ((proceedingLiquidationRequestStatusCode === this.WITHOUT_REQUEST_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE ||
            proceedingLiquidationRequestStatusCode === this.REJECTED_PROCEEDING_LIQUIDATION_REQUEST_STATUS_CODE) && this.proceeding ) {
            this.updateProceedingLiquidation = true;
        }
    }

    private loadProceedingLiquidationRequestStatuses(): void {
        this.proceedingLiquidationRequestStatusService.getProceedingLiquidationRequestStatuses()
            .subscribe((proceedingLiquidationRequestStatuses: IProceedingLiquidationRequestStatus[]) => {
                proceedingLiquidationRequestStatuses.forEach((proceedingLiquidationRequestStatus) => {
                    this.proceedingLiquidationRequestStatuses[proceedingLiquidationRequestStatus.code] = proceedingLiquidationRequestStatus;
            });
        });
    }
}
